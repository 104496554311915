import React, { useState } from "react";
import ContentCard, { ContentContainer, Hero, Summary, Title } from "../business-profile/content-card";
import { Button, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ContactMethods, Image } from "../../../generated/gql/graphql";
import EmailSubscription from "../EmailSubscription";


function ContactButtons(props: ContactMethods) {
  const ctas: React.ReactElement[] = [];
  const getButton = (txt, href) => <Button
    key={`contact-button-${txt}`}
    size='large'
    variant='contained'
    sx={{ textAlign: 'center' }}
    href={href}
  >
    {txt}
  </Button>;
  if (props.subscription)
    ctas.push(<EmailSubscription key='emailSub' label={props.subscription.label} successLabel={props.subscription.successLabel} />)
  if (props.email)
    ctas.push(getButton("Email Us", `mailto:${props.email}`))
  if (props.phone)
    ctas.push(getButton("Call Us", `tel:${props.phone}`))
  return <Stack direction='row' spacing={2}>
    {ctas}
  </Stack>
}


export default function Contact(props: {
  title: string,
  description?: string | null,
  location?: string | null,
  contactMethods?: ContactMethods | null,
  images: Image[],
}): React.ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  if (!props.contactMethods) return <></>;

  return <Stack spacing={4} sx={{
    pt: 8,
    pb: '30vh',
    display: 'flex',
    alignItems: 'center',
  }}>
    <Title size={isSmallScreen ? "medium" : 'large'} title={props.title} />
    <Hero images={props.images} isBackground />
    <Stack spacing={8} alignItems='center'>
      <Summary summary={props.description} size="medium" />
      <ContactButtons {...props.contactMethods} />
    </Stack>
  </Stack>
}
