import { TWebPageInteraction } from "../../generated/gql/graphql";
import { SIDE_PANEL_FILE_NAME, CHROME_EXT_STORAGE_KEY } from "../constants/chrome-extension";
import { AppState, FlowGraph } from "../types/AppState";
import {
  AuthenticateServiceWorkerRequest,
  AuthenticateServiceWorkerResponse,
  CaptureWebContentScriptRequest,
  CaptureWebContentScriptResponse,
  ChromeExtensionState,
  ContentScriptRequest,
  ContentScriptResponse,
  ErrorResponse,
  ExtClientState,
  GetStartupConfigServiceWorkerRequest,
  GetStartupConfigServiceWorkerResponse,
  NavigationContentScriptRequest,
  NavigationContentScriptResponse,
  NewSessionServiceWorkerRequest,
  NewSessionServiceWorkerResponse,
  ResumeSessionServiceWorkerRequest,
  ResumeSessionServiceWorkerResponse,
  ServiceWorkerRequest,
  ServiceWorkerResponse,
  WebInteractionContentScriptResponse
} from "../types/ChromeExtensionTypes";

export function isErrorResponse(res: ServiceWorkerResponse | ContentScriptResponse): res is ErrorResponse {
  return 'status' in res && res.status === 'error';
}

// in sidepanel, background, or popup; return false for contentscript
export function isInChromeExtensionFile(): boolean {
  return window.location.protocol === 'chrome-extension:';
}

export function isInSidePanel(): boolean {
  return isInChromeExtensionFile() && window.location.pathname.endsWith(SIDE_PANEL_FILE_NAME);
}

export function getTabIdForSidePanel(): number | undefined {
  if (!isInSidePanel()) {
    return undefined;
  }

  const params = new URLSearchParams(window.location.search);
  let tabId: number | undefined = parseInt(params.get('tabId'));
  if (isNaN(tabId)) {
    tabId = undefined;
  }
  return tabId;
}

// NOTE this is broadcasting message to sidePanel/popup as well
export async function sendRequestToServiceWorker(message: GetStartupConfigServiceWorkerRequest): Promise<GetStartupConfigServiceWorkerResponse>;
export async function sendRequestToServiceWorker(message: AuthenticateServiceWorkerRequest): Promise<AuthenticateServiceWorkerResponse>;
export async function sendRequestToServiceWorker(message: NewSessionServiceWorkerRequest): Promise<NewSessionServiceWorkerResponse>;
export async function sendRequestToServiceWorker(message: ResumeSessionServiceWorkerRequest): Promise<ResumeSessionServiceWorkerResponse>;
export async function sendRequestToServiceWorker(message: ServiceWorkerRequest): Promise<ServiceWorkerResponse> {
  if (chrome && chrome.runtime) {
    return await chrome.runtime.sendMessage(message);
  }
  else {
    console.error('Chrome extension not available');
    return Promise.resolve({ status: 'error', message: 'Chrome extension not available' });
  }
}

export async function sendRequestToContentScript(tabId: number, message: NavigationContentScriptRequest): Promise<NavigationContentScriptResponse>;
export async function sendRequestToContentScript(tabId: number, message: TWebPageInteraction): Promise<WebInteractionContentScriptResponse>;
export async function sendRequestToContentScript(tabId: number, message: CaptureWebContentScriptRequest): Promise<CaptureWebContentScriptResponse>;
export async function sendRequestToContentScript(tabId: number, message: ContentScriptRequest): Promise<ContentScriptResponse> {
  try {
    return await chrome.tabs.sendMessage(tabId, message);
  } catch (error) {
    if (message.__typename === 'Handshake') {
      throw error;
    }
    if (error.message.includes('Could not establish connection')) {
      // Wait for content script to initialize and send ready message with 60s timeout
      const startTime = Date.now();
      while (Date.now() - startTime < 30000) {  // 60 second timeout
        try {
          // Try handshake with content script
          await chrome.tabs.sendMessage(tabId, { __typename: 'Handshake' });
          // Retry sending original message
          return await chrome.tabs.sendMessage(tabId, message);
        } catch (e) {
          // Wait 100ms before next retry
          await new Promise(resolve => setTimeout(resolve, 500));
          continue;
        }
      }
      throw new Error('Action timeout: cannot establish connection with web page.');
    }
    throw error;
  }
}


async function saveChromeExtensionState(state: ChromeExtensionState): Promise<void> {
  await chrome.storage.local.set({ [CHROME_EXT_STORAGE_KEY]: state });
}
// export async function savePopupState(popupState: ChromeExtensionState['popup']): Promise<void> {
//   const state = await loadChromeExtensionState()
//   await saveChromeExtensionState({ ...state, popup: popupState });
// }

export async function loadChromeExtensionState(): Promise<ChromeExtensionState> {
  const stored = await chrome.storage.local.get(CHROME_EXT_STORAGE_KEY);
  return stored[CHROME_EXT_STORAGE_KEY] || {
    // popup: {},
    sessions: {},
    appStateWithGraphs: {},
  };
}

export async function updateSession(tabId: number, sessionState: ExtClientState | undefined, appState?: AppState & { graph: FlowGraph; }): Promise<void> {
  const stored = await loadChromeExtensionState();
  const newStore = structuredClone(stored);
  if (sessionState) {
    newStore.sessions[tabId] = sessionState;
    if (appState) {
      if (!newStore.appStateWithGraphs) {
        newStore.appStateWithGraphs = {};
      }
      newStore.appStateWithGraphs[tabId] = appState;
    }
  }
  else {
    delete newStore.sessions[tabId];
    if (newStore.appStateWithGraphs) {
      delete newStore.appStateWithGraphs[tabId];
    }
  }
  await saveChromeExtensionState(newStore);
}

//TODO this might no longer be correct
export function canUploadWebContent(): boolean {
  return Boolean(chrome?.runtime);
}
