
// IMPORTANT: DO NOT IMPORT this file in any other file.
// Doing so would trigger many onload side effects that is only designed for the app's entry point.
import { AlertTitle, Box, Fab, ThemeProvider, createTheme } from '@mui/material';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useQuery } from '@apollo/client';

import {
  AppContext,
} from './contexts/AppContext';
import { SiteIdContext } from "./contexts/SiteIdContext";
import { ItentoAppContext } from "./contexts/ItentoAppContext";
import { PageInEdit } from "./types/PageInEdit";
import { AiTask } from "./types/AiTask";
import CustomizationProvider from './components/customization/customization-provider';
import ScrollToTop from './components/scroll-to-top';
import SiteView from './components/business-profile/site-view';
import displayOptions from './components/customization/display-options';
import { IS_SITE_OWNER } from './graphql/query';
import PixieApp from './features/Pixie/App';
import { AppContainer } from './components/pixie/AppContainer';
import { GraphQLClientProvider } from './features/Pixie/Client/Web/GraphQLClientProvider';
import { useUserAndWorkspaceStore } from './hooks/UserAndWorkspaceStore';

// the global user object is populated in the base html page, rendered from buttlerfly/templates/base.html
declare global {
  var userId: number | undefined;
  var businessProfileId: number | undefined;
  var slug: string | undefined;
}

function EditingMode({ pageInEdit, setPageInEdit }): React.ReactElement {
  const { initialSiteId } = useContext(SiteIdContext);
  const isOwnerQuery = useQuery(IS_SITE_OWNER, { variables: { siteId: initialSiteId } });
  const { setError } = useContext(AppContext);
  const user = useUserAndWorkspaceStore(state => state.user);

  useEffect(() => {
    isOwnerQuery.refetch().catch(setError);
  }, [user]);

  return isOwnerQuery.data?.isSiteOwner
    ? <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: displayOptions.menu.p,
        width: '100%',
        zIndex: 1150, // in front of top menu bar (1100) but behind chat drawer (1200)
        pointerEvents: 'none', // Add this line to make the FAB transparent for mouse events
      }}
    >
      {pageInEdit
        ? <Fab variant='extended' onClick={() => setPageInEdit(undefined)} sx={{ pointerEvents: 'auto', textTransform: 'none' }}>
          Editing "{pageInEdit.label || pageInEdit.id}". Click text to edit, click here to exit.
        </Fab>
        : <Fab variant='extended' sx={{ textTransform: 'none' }}>Double click content to select it for editing</Fab>
      }
    </Box>
    : <></>
}


function ItentoAppContainer({ children }): React.ReactElement {
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [taskProgressionOpen, setTaskProgressionOpen] = useState<boolean>(false);
  const [tasks, updateTasksOrClear] = useReducer(
    updateAiTasks,
    [] as AiTask[],
  );
  const [pageInEdit, setPageInEdit] = useState<PageInEdit | undefined>(undefined);

  function updateAiTasks(ts: AiTask[], task: AiTask | null): AiTask[] {
    if (!task) return []
    const t = ts.find(t => t.name == task.name);
    if (!t) ts.push(task);
    else t.response = task.response || ((t.response || '') + (task.token || ''));
    return [...ts];
  }

  return <ItentoAppContext.Provider value={{
    chatOpen,
    setChatOpen,
    taskProgressionOpen,
    setTaskProgressionOpen,
    tasks,
    updateTasksOrClear,
    pageInEdit,
    setPageInEdit,
  }}>
    <ScrollToTop />
    <CustomizationProvider>
      <EditingMode pageInEdit={pageInEdit} setPageInEdit={setPageInEdit} />
      {children}
    </CustomizationProvider>
  </ItentoAppContext.Provider>
}

export function HouseElfAppContainer(): React.ReactElement {
  return <GraphQLClientProvider>
    <ThemeProvider theme={createTheme()}>
      <AppContainer>
        <PixieApp />
      </AppContainer >
    </ThemeProvider>
  </GraphQLClientProvider>
}

const container = document.getElementById('app');
if (container) {
  const root = createRoot(container);
  root.render(<HouseElfAppContainer />);
}
