import React from 'react';
import { ContentContainer } from '../../business-profile/content-card';
import { Box, Stack } from '@mui/material';

export default function HeadlineLayout({ hero, title, summary, buttons }) {
  return <ContentContainer sx={{ minHeight: '50vh' }}>
    <Stack spacing={12} alignItems='center' sx={{ width: '100%', textAlign: 'center', p: { xs: 4, md: 6, lg: 8 } }}>
      {title}
      {summary}
      {buttons}
      {hero}
    </Stack>

  </ ContentContainer>
}
