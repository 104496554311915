import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { AppContext } from '../contexts/AppContext';
import { LinearProgress } from '@mui/material';
import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';

export default function DataProvider<T>(props: {
  fetch: () => Promise<T>,
  onLoaded?: (data: T) => void,
  getChildren?: (data: T) => React.ReactNode,
}): React.ReactElement {
  const loading = <LinearProgress />;

  const [data, setData] = useState<T | undefined>(undefined);
  const setError = useContext(AppContext).setError;

  async function fetchData() {
    try {
      const data = await props.fetch();
      setData(data);
      if (props.onLoaded) props.onLoaded(data);
    }
    catch (err) {
      setError(err);
    };
  }

  useEffect(() => { fetchData() }, []);

  return props.getChildren
    ? <>{data ? props.getChildren(data) : loading}</>
    : <></>;
}


export function GraphQLDataProvider<TData = any, TVariables extends OperationVariables = OperationVariables>(props: {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
  options?: QueryHookOptions<TData, TVariables>
  onLoaded?: (data: TData) => void,
  getChildren?: (data: TData) => React.ReactNode,
  refetchTrigger?: boolean,
}): React.ReactElement {

  const { loading, error, data, refetch } = useQuery(props.query, props.options);
  const setError = useContext(AppContext).setError;

  useEffect(() => {
    if (data && props.onLoaded) props.onLoaded(data);
  }, [data]);

  useEffect(() => { refetch() }, [props.refetchTrigger]);
  useEffect(() => { if (error) setError(error) }, [error]);

  const progressBar = <LinearProgress />;
  const noData = <></>;

  if (loading) return progressBar;
  if (error) return noData;

  return props.getChildren
    ? data ? <>{props.getChildren(data)}</> : noData
    : noData;
}
