import React, { useEffect, useMemo } from 'react';
import {
  Node,
  NodeProps,
  XYPosition,
  useUpdateNodeInternals,
} from 'reactflow';
import {
  Box,
  Stack,
  Typography,
  lighten,
  useTheme,
} from '@mui/material';
import {
  TFlowPluginV2,
} from '../../../../generated/gql/graphql';
import {
  FlowNodeData,
  FlowNodeType,
} from '../../../types/GraphNode';
import {
  ConnectHandle, ConnectHandleGroup,
} from './ConnectHandle';
import { FlowNodeName } from './FlowNodeName';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { useNodeParamValidation } from '../../../hooks/useGraphValidation';

export function toFlowNode(nodeData: TFlowPluginV2, position?: XYPosition): Node<FlowNodeData, FlowNodeType> {
  return {
    id: nodeData.id,
    position: position || { x: 0, y: 0 },
    type: "flowNode",
    data: nodeData,
  }
}


export default function FlowNode({ id, data, selected }: NodeProps<FlowNodeData>): React.ReactElement {
  const typeInfo = useEditorStore(state => state.actions.graph.getTypeInfo()[id]);
  const theme = useTheme();
  // const { tunings, toggleTuning } = useContext(TuningContext);
  const startNodeId = useEditorStore(state => state.app?.startNodeId);
  const selectedNodeId = useEditorStore(state => state.actions.graph.getSelectedNodeId());
  const selectedEdgeId = useEditorStore(state => state.actions.graph.getSelectedEdgeId());
  const highlightedHandles = useEditorStore(useShallow(state => {
    if (selectedEdgeId) {
      const edge = state.graph.edges.find(e => e.id === selectedEdgeId && e.source === id);
      if (edge?.sourceHandle) {
        return [edge.sourceHandle];
      }
      return [];
    }
    else if (selectedNodeId) {
      return state.graph.edges
        .filter(e => e.source === id && e.target === selectedNodeId && e.sourceHandle)
        .map(e => e.sourceHandle!)
    }
    else return [];
  }));
  const validationErrors = useNodeParamValidation(id);

  const boxStyle = useMemo(() => {
    return selected
      ? {
        backgroundColor: validationErrors.length > 0 ? theme.palette.error.light : lighten(theme.palette.primary.light, 0.8),
        border: `4px solid ${theme.palette.secondary.dark}`,
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)',
      }
      : {
        border: '2px solid black',
        backgroundColor: validationErrors.length > 0 ? theme.palette.error.light : theme.palette.background.paper,
      };
  }, [selected, validationErrors.length, theme.palette]);

  return <Box position='relative' p={2} borderRadius={4} sx={boxStyle} width='400px'>
    {id === startNodeId && <Stack direction='row' display='flex' alignItems='center' sx={{
      position: 'absolute',
      top: '50%',
      left: -100,
      transform: 'translateY(-50%)',
    }}>
      <Typography variant='h6' color='secondary'><b>START</b></Typography>
      <ArrowRightIcon color='secondary' fontSize='large' />
    </Stack>
    }
    <FlowNodeName variant='title' nodeId={id} />
    {/* <Typography><InlineCode>{JSON.stringify(data.params, null, 2)}</InlineCode></Typography>
      <Typography><InlineCode>{JSON.stringify(data.dynamicParams, null, 2)}</InlineCode></Typography> */}

    <ConnectHandle nodeId={id} handleId='prev' />
    <ConnectHandleGroup nodeId={id} handleIds={typeInfo?.pluginInfo?.allowedBranches || []} highlighted={highlightedHandles} />
  </Box>
}
