import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { FlowNodeData, GraphNode } from '../types/GraphNode'
import { Connection, Edge, EdgeAddChange, EdgeChange, NodeAddChange, NodeChange, NodeRemoveChange, OnConnect, XYPosition, applyEdgeChanges, applyNodeChanges } from 'reactflow'
import { ApolloClient, ApolloError, FetchResult } from '@apollo/client';
import {
  GET_DYNAMIC_PLUGIN_INFO,
  GET_FLOW_V2,
  GET_STATIC_INFO,
  VALIDATE_APP,
  GET_RECIPE,
  BATCH_GET_DYNAMIC_PLUGIN_INFO,
} from '../graphql/query'
import { createEdge, createFlowFromZustand, getEdgeStyle, getGraphForZustand } from '../utils/graph-conversion';
import { SettingsMenuOption } from '../types/SettingsMenuOption'
import {
  CREATE_FLOW_V2,
  DELETE_FLOW_V2,
  UPDATE_FLOW_V2,
  CREATE_RECIPE,
  DELETE_RECIPE,
  UPDATE_RECIPE,
} from '../graphql/mutation'
import { Selection } from '../types/Selection'
import { DeleteFlowV2Mutation, DeleteRecipeMutation, PluginCategory, TAppBreakpoint, TFlowPluginType, TFlowPluginV2, TPluginConstructInfo, TPluginInfo, TRecipeInfo } from '../../generated/gql/graphql';
import { AxiosError } from 'axios'
import { GraphQLError } from 'graphql'
import { arrayToObject } from '../utils/common';
import { isEqual } from 'lodash';
import { removeTypename } from '../utils/removeTypename'
import { logDebug } from '../utils/logging';
import { AccessPathSegment, DynamicValueParam, isComplex, isComposite } from '../types/DynamicValueTypes'
import { computed } from '../utils/zustand-computed';
import { shallow } from 'zustand/shallow';
import { AppState, EditorMode, FlowGraph } from '../types/AppState'
import { JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema'


export interface TypeInfo {
  type: TFlowPluginType,
  constructInfo: TPluginConstructInfo | undefined,
  pluginInfo: TPluginInfo | undefined,
  dynamicTypeInfoOutdated: boolean,
}

// for tracking action history, to carry out undo/redo
type ActionRecord = {
  action: 'add',
  nodeIds: string[],
  edgeIds: string[],
  prevSelection: Selection[],
} | {
  action: 'remove',
  nodes: GraphNode[],
  edges: Edge[],
} | {
  action: 'change',
  prevNodeData: FlowNodeData,
}

type ActionHistory = { timestamp: number, actions: ActionRecord[] }[];

interface ConnectHandle {
  nodeId: string,
  handleId: string
}

interface Actions {
  setSelectedSettingMenuOption: (option: SettingsMenuOption | null) => void,

  // app actions
  setAiConfig: (aiConfig: any) => void,
  setAiConfigForNode: (nodeId: string, aiConfig: any) => void,
  setAppIsPublic: (isPublic: boolean) => void,
  setAppName: (name: string) => void,
  setStartNodeId: (startNodeId: string | null) => void,
  setSigninRequired: (requireSignin: boolean) => void,
  setBreakpoints: (breakpoints: string[]) => void,
  setCurrentBreakpoint: (breakpoint: TAppBreakpoint | null) => void,
  setRerunFromNodeAfterBreakpoint: (node: { nodeId: string, nodeVersion: number } | null) => void,

  // recipe actions
  setRecipeName: (name: string) => void,
  setRecipeDescription: (description: string | null) => void,

  graph: {
    setHighlightedConnectHandles: (handles: ConnectHandle[]) => void,
    updateNodeData: (nodeId: string, updates: Partial<FlowNodeData>) => void,
    addNode: (data: Omit<FlowNodeData, "id" | "__typename">, position: XYPosition, onCreate?: (node: GraphNode) => void) => void,
    onNodesChange: (changes: NodeChange[]) => void,
    onEdgesChange: (changes: EdgeChange[]) => void,
    onConnect: OnConnect,
    removeNode: (nodeId: string) => void,
    removeSelected: () => void,
    pasteClipboard: (offset?: XYPosition) => void,
    selectAll: () => void,
    validateApp: () => void,
    // undo/redo
    undo: () => void,
    redo: () => void,
    // computed properties
    getSelections: () => Selection[],
    getSelectedNodeId: () => string | null,
    getSelectedEdgeId: () => string | null,
    getSelectionCount: () => number,
    getTypeInfo: () => { [nodeId: string]: TypeInfo },
    getRelatedNodeIds: (distance: number, sourceNodeId?: string) => string[],
    requiresChromeExtension: () => boolean,
  }

  addErrorNotification: (error: AxiosError | ApolloError | GraphQLError | string) => void,
  addSuccessNotification: (message: string) => void,

  startDebug: () => void,
  endDebug: () => void,
  setTemplateView: (view: string | null) => void,
  setEdgeHighlightColor: (color: string) => void,

  setClipboard: (selections: Selection[]) => void,
  setShortcutsPopoverOpen: (open: boolean) => void,

  getParamEditorTypeSelection: (uniqueId: string, path: AccessPathSegment[]) => number | 'dynamic' | null,
  setParamEditorTypeSelection: (uniqueId: string, path: AccessPathSegment[], selection: number | 'dynamic' | null) => void,
}

interface Graphql {
  loadApp: (client: ApolloClient<object>, appId: string | null) => Promise<void>,
  saveApp: (client: ApolloClient<object>, siteId: string) => Promise<FetchResult<string>>,
  deleteApp: (client: ApolloClient<object>) => Promise<FetchResult<DeleteFlowV2Mutation>>,
  duplicateApp: (client: ApolloClient<object>, siteId: string) => Promise<FetchResult<string>>,
  saveAppAsRecipe: (client: ApolloClient<object>) => Promise<FetchResult<string>>,
  validatePluginDependencies: (client: ApolloClient<object>) => Promise<void>,

  loadStaticTypes: (client: ApolloClient<object>) => Promise<void>,
  // load all dynamic types for the current app state
  loadDynamicTypes: (client: ApolloClient<object>, cleanNodeData?: boolean) => Promise<void>,
  loadDynamicType: (client: ApolloClient<object>, nodeIdOrData: string | TFlowPluginV2, cleanNodeData?: boolean) => Promise<void>,
  updateDefaultEditorView: (client: ApolloClient<object>, view: string) => Promise<void>,

  loadRecipe: (client: ApolloClient<object>, recipeId: string | null) => Promise<void>,
  saveRecipe: (client: ApolloClient<object>) => Promise<FetchResult<string>>,
  duplicateRecipe: (client: ApolloClient<object>) => Promise<FetchResult<string>>,
  deleteRecipe: (client: ApolloClient<object>) => Promise<FetchResult<DeleteRecipeMutation>>,
  pasteRecipeToApp: (client: ApolloClient<object>, recipeId: string) => Promise<void>,
}

interface Notification {
  time: Date,
  content: AxiosError | ApolloError | GraphQLError | {
    type: 'error' | 'success',
    message: string,
  },
}

interface SettingsMenu {
  selected: SettingsMenuOption | null,
}

interface EditorView {
  debugAppOpen: boolean,
  edgeHightlightColor: string,
  templateView: string | null,
  shortcutsPopoverOpen: boolean,
}

interface TypeInfoCollection {
  // actual key is PluginType
  static: { [type: string]: TPluginInfo },
  construct: { [type: string]: TPluginConstructInfo },
  dynamic: {
    [appId: string]: {
      [nodeId: string]: TPluginInfo,
    }
  }
}

interface FlowRecipe {
  id: string | null,
  name: string,
  description: string | null,
}

export interface EditorState {
  settingsMenu: SettingsMenu,
  mode: EditorMode,
  app: AppState | null,
  recipe: FlowRecipe | null,
  graph: FlowGraph,
  notifications: Notification[],
  clipboard: {
    nodes: GraphNode[],
    edges: Edge[],
  },
  types: TypeInfoCollection,

  editorView: EditorView,

  actions: Actions,
  graphql: Graphql,
}

const defaultApp: AppState = {
  id: '',
  name: 'Pixie app',
  aiConfig: null,
  aiConfigByNode: null,
  isPublic: false,
  startNodeId: null,
  signinRequired: false,
  breakpoints: [],
  currentBreakpoint: null,
  rerunFromNodeAfterBreakpoint: null,
}

const defaultGraph: FlowGraph = {
  nodes: [],
  edges: [],
  pastActions: [],
  futureActions: [],
  highlightedConnectHandles: [],
  validationResults: [],
  typeSelections: {},
}

function getDynamicTypeCollectionKey(state: EditorState): string {
  if (state.mode === 'app') {
    return 'app' + (state.app.id || '');
  }
  else {
    return 'recipe' + (state.recipe?.id || '');
  }
}

export const useEditorStore = create<EditorState>()(
  devtools(subscribeWithSelector(immer((set, get) => ({
    settingsMenu: {
      selected: null,
    },
    editorView: {
      debugAppOpen: false,
      edgeHightlightColor: '#9c27b0',
      templateView: null,
      shortcutsPopoverOpen: false,
    },

    // default editor to app mode with empty app
    mode: 'app',
    app: defaultApp,
    recipe: null,
    graph: defaultGraph,

    notifications: [] as Notification[],
    clipboard: { nodes: [], edges: [] },

    types: {
      static: {},
      construct: {},
      dynamic: {},
    } as TypeInfoCollection,

    actions: {
      setSelectedSettingMenuOption: (option: SettingsMenuOption | null) => set(state => {
        state.settingsMenu.selected = option;
      }),
      setAiConfig: (aiConfig: any) => set(state => {
        if (!state.app) return;
        state.app.aiConfig = aiConfig;
      }),
      setAiConfigForNode: (nodeId: string, aiConfig: any) => set(state => {
        if (!aiConfig && state.app.aiConfigByNode) {
          delete state.app.aiConfigByNode[nodeId];
        }
        else {
          state.app.aiConfigByNode = { ...state.app.aiConfigByNode, [nodeId]: aiConfig };
        }
      }),
      setAppIsPublic: (isPublic: boolean) => set(state => {
        if (!state.app) return;
        state.app.isPublic = isPublic;
      }),
      setAppName: (name: string) => set(state => {
        if (!state.app) return;
        state.app.name = name;
      }),
      setStartNodeId: (startNodeId: string | null) => set(state => {
        if (!state.app) return;
        if (startNodeId === null || state.graph.nodes.every(n => n.id !== startNodeId)) {
          state.app.startNodeId = state.graph.nodes[0]?.id || null;
        }
        else state.app.startNodeId = startNodeId;
      }),
      setSigninRequired: (requireSignin: boolean) => set(state => {
        if (!state.app) return;
        state.app.signinRequired = requireSignin;
      }),
      setEdgeHighlightColor: (color: string) => set(state => {
        state.editorView.edgeHightlightColor = color;
      }),
      setRecipeName: (name: string) => set(state => {
        if (!state.recipe) return;
        state.recipe!.name = name;
      }),
      setRecipeDescription: (description: string | null) => set(state => {
        if (!state.recipe) return;
        state.recipe!.description = description;
      }),
      setBreakpoints: (breakpoints: string[]) => set(state => {
        if (!state.app) return;
        state.app.breakpoints = breakpoints;
      }),
      setCurrentBreakpoint: (breakpoint: TAppBreakpoint | null) => set(state => {
        if (!state.app) return;
        state.app.currentBreakpoint = breakpoint;
      }),
      setRerunFromNodeAfterBreakpoint: (node) => set(state => {
        if (!state.app) return;
        state.app.rerunFromNodeAfterBreakpoint = node ? { ...node } : null;
      }),

      graph: {
        setHighlightedConnectHandles: (handles: ConnectHandle[]) => set(state => {
          state.graph.highlightedConnectHandles = handles;
        }),
        updateNodeData: (nodeId: string, updates: Partial<FlowNodeData>) => set(state => {
          for (const node of state.graph.nodes) {
            if (node.id === nodeId) {
              const record: ActionRecord = { action: 'change', prevNodeData: node.data };
              node.data = { ...node.data, ...updates };
              $recordAction(state, record);
            }
          }
        }),

        addNode: (data: Omit<FlowNodeData, "id" | "__typename">, position: XYPosition, onCreate?: (node: GraphNode) => void) => set(state => {
          const n = $addNode(state, data, position);
          const prevSelection = $getSelections(state);
          const record: ActionRecord = { action: 'add', nodeIds: [n.id], edgeIds: [], prevSelection };
          $recordAction(state, record);
          $updateSelections(state, [{ type: 'node', id: n.id }]);
          onCreate?.(n);
        }),

        onNodesChange: (changes: NodeChange[]) => set(state => {
          const prevSelections = $getSelections(state);
          const addRecord: ActionRecord = {
            action: 'add',
            nodeIds: changes.filter(c => c.type === 'add').map(c => (c as NodeAddChange).item.id),
            edgeIds: [],
            prevSelection: prevSelections
          };
          const nodesById = arrayToObject(state.graph.nodes, 'id');
          const removeRecord: ActionRecord = {
            action: 'remove',
            nodes: changes
              .filter(c => c.type === 'remove')
              .map(c => nodesById[(c as NodeRemoveChange).id])
              .filter(n => n) as GraphNode[],
            edges: [],
          };
          state.graph.nodes = applyNodeChanges<FlowNodeData>(changes, state.graph.nodes) as GraphNode[];
          if (addRecord.nodeIds.length > 0) {
            $recordAction(state, addRecord);
          }
          if (removeRecord.nodes.length > 0) {
            $recordAction(state, removeRecord);
          }
          // this does nothing because the function doesn't do anything on selected nodes, but add here for consistency
          $updateSelections(state);
        }),

        onEdgesChange: (changes: EdgeChange[]) => set(state => {
          const prevSelections = $getSelections(state);
          const addRecord: ActionRecord = {
            action: 'add',
            nodeIds: [],
            edgeIds: changes.filter(c => c.type === 'add').map(c => (c as EdgeAddChange).item.id),
            prevSelection: prevSelections
          };
          const edgesById = arrayToObject(state.graph.edges, 'id');
          const removeRecord: ActionRecord = {
            action: 'remove',
            nodes: [],
            edges: changes
              .filter(c => c.type === 'remove')
              .map(c => edgesById[(c as NodeRemoveChange).id])
              .filter(e => e) as Edge[],
          };
          $recordAction(state, addRecord);
          $recordAction(state, removeRecord);
          state.graph.edges = applyEdgeChanges(changes, state.graph.edges);
          $updateSelections(state);
        }),

        onConnect: (conn: Connection) => set(state => {
          if (conn.source && conn.target && conn.sourceHandle) {
            const newEdge = createEdge(conn.source, conn.target, conn.sourceHandle, {});
            const prevSelections = $getSelections(state);
            const record: ActionRecord = {
              action: 'add',
              nodeIds: [],
              edgeIds: [newEdge.id],
              prevSelection: prevSelections,
            };
            $recordAction(state, record);
            state.graph.edges.push(newEdge);
          }
        }),

        removeNode: (nodeId: string) => set(state => {
          // TODO remove node-specific ai config when removing node
          const { removedNode, removedEdges } = $removeNodeFromState(nodeId, state);
          const record: ActionRecord = {
            action: 'remove',
            nodes: removedNode ? [removedNode] : [],
            edges: removedEdges,
          };
          $recordAction(state, record);
        }),

        removeSelected: () => set(state => {
          const selectedNodes = state.graph.nodes.filter(n => n.selected);
          const removed = selectedNodes.map(n => $removeNodeFromState(n.id, state));
          const removedNodes = removed.filter(r => r.removedNode).map(r => r.removedNode);
          const removedEdges = removed.flatMap(r => r.removedEdges);
          const selectedEdges = state.graph.edges.filter(e => e.selected);
          removedEdges.push(...selectedEdges);
          state.graph.edges = state.graph.edges.filter(e => !e.selected);
          // this does nothing because nothing is selected, but adding here for consistency
          $updateSelections(state);
          const record: ActionRecord = {
            action: 'remove',
            nodes: removedNodes,
            edges: removedEdges,
          };
          $recordAction(state, record);
        }),

        selectAll: () => set(state => {
          const all = [
            ...state.graph.nodes.map(n => ({ type: 'node', id: n.id })),
            ...state.graph.edges.map(e => ({ type: 'edge', id: e.id })),
          ] as Selection[];
          $updateSelections(state, all);
        }),

        pasteClipboard: (offset?: XYPosition) => {
          set(state => $pasteGraph(state, state.clipboard.nodes, state.clipboard.edges, offset));
        },

        undo: () => set(state => $undoActions(state, state.graph.pastActions, state.graph.futureActions)),

        redo: () => set(state => $undoActions(state, state.graph.futureActions, state.graph.pastActions)),

        validateApp: () => set(state => {
          // TODO to be implemented
        }),

        //computed properties
        getSelections: computed(
          () => [
            get().graph.nodes.filter(n => n.selected).map(n => n.id),
            get().graph.edges.filter(e => e.selected).map(e => e.id)
          ],
          ([], selectedNodeIds, selectedEdgeIds) => [
            ...selectedNodeIds.map(id => ({ type: 'node', id })),
            ...selectedEdgeIds.map(id => ({ type: 'edge', id })),
          ] as Selection[],
          shallow,
        ),

        getSelectedNodeId: computed(
          () => [get().graph.nodes.filter(n => n.selected).map(n => n.id)],
          ([], selectedNodeIds) => selectedNodeIds.length === 1 ? selectedNodeIds[0] : null,
          shallow,
        ),

        getSelectedEdgeId: computed(
          () => [get().graph.edges.filter(e => e.selected).map(e => e.id)],
          ([], selectedEdgeIds) => selectedEdgeIds.length === 1 ? selectedEdgeIds[0] : null,
          shallow,
        ),

        getSelectionCount: computed(
          () => [get().graph.nodes.filter(n => n.selected).length, get().graph.edges.filter(e => e.selected).length],
          ([], selectedNodeCount, selectedEdgeCount) => selectedNodeCount + selectedEdgeCount,
        ),

        getTypeInfo: computed(
          () => [
            get().graph.nodes.map(node => node.id),
            get().graph.nodes.map(node => node.data.pluginType),
            get().types.static,
            get().types.construct,
            get().types.dynamic[getDynamicTypeCollectionKey(get())] || {},
          ],
          ([], nodeIds, nodeTypes, staticTypes, constructTypes, dynamicTypes) => {
            const ret: { [nodeId: string]: TypeInfo } = {}
            for (let i = 0; i < nodeIds.length; i++) {
              const nodeId = nodeIds[i];
              const nodeType = nodeTypes[i];
              ret[nodeId] = {
                type: nodeType,
                constructInfo: nodeType.dynamic?.constructType && constructTypes[nodeType.dynamic.constructType],
                pluginInfo: nodeType.static
                  ? staticTypes[nodeType.static]
                  : dynamicTypes[nodeId],
                dynamicTypeInfoOutdated: Boolean(nodeType.dynamic && !isEqual(removeTypename(nodeType.dynamic), removeTypename(dynamicTypes[nodeId]?.pluginType?.dynamic))),
              };
            }
            return ret;
          },
          shallow,
        ),

        getRelatedNodeIds: computed(
          () => [
            get().graph.edges.reduce((acc, edge) => {
              acc[edge.source] = edge.target;
              return acc;
            }, {} as { [sourceNodeId: string]: string }),
            get().actions.graph.getSelectedNodeId(),
          ],
          ([distance, sourceNodeId], edgeMapping, selectedNodeId) => {
            if (selectedNodeId === null) return [];
            return getRelatedNodeIds(sourceNodeId || selectedNodeId, distance, edgeMapping);
          },
          shallow,
        ),

        requiresChromeExtension: computed(
          () => [
            Object.entries(get().actions.graph.getTypeInfo()).reduce((requiresExt, [nodeId, typeInfo]) => {
              for (const c of typeInfo.pluginInfo?.categories || []) {
                if (c === PluginCategory.ChromeExtensionOnly) {
                  return true;
                }
              }
              return requiresExt;
            }, false),

          ],
          ([], requiresExt) => requiresExt,
        ),
      },

      addErrorNotification: (error: AxiosError | ApolloError | GraphQLError | string) => {
        console.warn(error);
      },

      addSuccessNotification: (message: string) => {
        console.log(message);
      },

      startDebug: () => set(state => {
        state.editorView.debugAppOpen = true;
      }),
      endDebug: () => set(state => {
        state.editorView.debugAppOpen = false;
      }),
      setTemplateView: (view: string | null) => set(state => {
        state.editorView.templateView = view;
      }),
      setClipboard: (selections: Selection[]) => set(state => {
        const nodesById = arrayToObject(state.graph.nodes, 'id');
        const edgesById = arrayToObject(state.graph.edges, 'id');
        const selectedNodes = selections.filter(s => s.type === 'node').map(s => nodesById[s.id]).filter(n => n);
        const selectedEdges = selections.filter(s => s.type === 'edge').map(s => edgesById[s.id]).filter(e => e);
        state.clipboard = { nodes: selectedNodes, edges: selectedEdges };
      }),
      setShortcutsPopoverOpen: (open: boolean) => set(state => {
        state.editorView.shortcutsPopoverOpen = open;
      }),

      getParamEditorTypeSelection: (uniqueId: string, path: AccessPathSegment[]) => {
        const state = get();
        const selection = state.graph.typeSelections[uniqueId]?.find(d => isEqual(d.path, path))?.selection;
        return selection === undefined ? null : selection;
      },

      setParamEditorTypeSelection: (uniqueId, path, selection) => set(state => {
        if (!state.graph.typeSelections[uniqueId]) {
          state.graph.typeSelections[uniqueId] = [];
        }
        const data = state.graph.typeSelections[uniqueId];
        const index = data.findIndex(d => isEqual(d.path, path));
        if (selection === null) {
          if (index !== -1) {
            data.splice(index, 1);
          }
        }
        else {
          if (index === -1) {
            data.push({ path, selection });
          }
          else {
            data[index].selection = selection;
          }
        }
        if (data.length === 0) {
          delete state.graph.typeSelections[uniqueId];
        }
      }),
    },

    graphql: {
      loadApp: async (client: ApolloClient<object>, appId: string | null) => await loadApp(client, appId, get, set, true),

      saveApp: async (client: ApolloClient<object>, siteId: string) => {
        const state = get();
        const commonVars = {
          flowName: state.app.name,
          flow: createFlowFromZustand(
            state.graph.nodes.map(n => n.data),
            state.graph.edges,
          ),
          isPublic: state.app.isPublic,
          layout: state.graph.nodes
            .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
          startNodeId: state.app.startNodeId,
          aiConfig: state.app.aiConfig,
          aiConfigByNode: state.app.aiConfigByNode,
          signinRequired: state.app.signinRequired,
        }
        if (!state.app.id) {
          const result = await client.mutate({
            mutation: CREATE_FLOW_V2,
            variables: {
              siteId,
              ...commonVars,
            }
          })
          return { ...result, data: result.data?.acreateFlowV2 } as FetchResult<string>;
        }
        else {
          const result = await client.mutate({
            mutation: UPDATE_FLOW_V2,
            variables: {
              flowId: state.app.id,
              ...commonVars,
              editorData: state.graph.typeSelections,
            }
          })
          return { ...result, data: result.data?.aupdateFlowV2.id } as FetchResult<string>;
        }
      },

      deleteApp: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot delete unsaved app");
        }
        const result = await client.mutate({
          mutation: DELETE_FLOW_V2,
          variables: { flowId: state.app.id }
        })
        return result;
      },

      duplicateApp: async (client: ApolloClient<object>, siteId: string) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot duplicate unsaved app");
        }
        const result = await client.mutate({
          mutation: CREATE_FLOW_V2,
          variables: {
            siteId: siteId,
            flowName: state.app.name + ' (copy)',
            flow: createFlowFromZustand(
              state.graph.nodes.map(n => n.data),
              state.graph.edges,
            ),
            isPublic: state.app.isPublic,
            layout: state.graph.nodes
              .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
            startNodeId: state.app.startNodeId,
            aiConfig: state.app.aiConfig,
            signinRequired: state.app.signinRequired,
          }
        })
        const newAppId = result.data?.acreateFlowV2;
        // preserve all the configuration without cleaning node data based on dynamic plugin's param schema
        await loadApp(client, newAppId, get, set, false);
        return { ...result, data: newAppId } as FetchResult<string>;
      },

      saveAppAsRecipe: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot save app as recipe without app data");
        }
        const result = await client.mutate({
          mutation: CREATE_RECIPE,
          variables: {
            name: 'Recipe: ' + state.app.name,
            description: null,
            flowConfig: createFlowFromZustand(
              state.graph.nodes.map(n => n.data),
              state.graph.edges,
            ),
            layout: state.graph.nodes
              .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
          }
        })
        return { ...result, data: result.data?.acreateRecipe?.id } as FetchResult<string>;
      },

      loadRecipe: async (client: ApolloClient<object>, recipeId: string | null) => {

        set(state => {
          state.settingsMenu.selected = null;
          state.editorView.debugAppOpen = false;
          state.mode = 'recipe';
          state.app = null;
          state.recipe = {
            id: null,
            name: 'My Recipe',
            description: null,
          };
          state.graph = defaultGraph;
        })

        if (!recipeId) return;

        const { recipe, nodes, edges } = await loadRecipe(client, recipeId);
        set(state => {
          state.recipe = {
            id: recipe.id,
            name: recipe.name,
            description: recipe.description,
          }
          state.graph.nodes = nodes;
          state.graph.edges = edges;
        })
        const nodesData = nodes.map(n => n.data);
        await loadDynamicTypes(client, nodesData, get, set, true).catch(get().actions.addErrorNotification);
      },

      saveRecipe: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.recipe) {
          state.actions.addErrorNotification("Cannot save recipe without recipe data");
        }
        const commonVars = {
          name: state.recipe.name,
          description: state.recipe.description,
          flowConfig: createFlowFromZustand(
            state.graph.nodes.map(n => n.data),
            state.graph.edges,
          ),
          layout: state.graph.nodes
            .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
        };
        if (state.recipe.id) {
          const result = await client.mutate({
            mutation: UPDATE_RECIPE,
            variables: { recipeId: state.recipe.id, ...commonVars }
          });
          return { ...result, data: result.data?.aupdateRecipe.id } as FetchResult<string>;
        }
        else {
          const result = await client.mutate({
            mutation: CREATE_RECIPE,
            variables: commonVars
          });
          return { ...result, data: result.data?.acreateRecipe } as FetchResult<string>;
        }
      },

      duplicateRecipe: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.recipe || !state.recipe.id) {
          state.actions.addErrorNotification("Cannot duplicate unsaved recipe");
        }
        const result = await client.mutate({
          mutation: CREATE_RECIPE,
          variables: {
            name: state.recipe.name + ' (copy)',
            description: state.recipe.description,
            flowConfig: createFlowFromZustand(
              state.graph.nodes.map(n => n.data),
              state.graph.edges,
            ),
            layout: state.graph.nodes
              .map(n => ({ id: n.id, x: n.position.x, y: n.position.y })),
          }
        });
        return { ...result, data: result.data?.acreateRecipe?.id } as FetchResult<string>;
      },

      deleteRecipe: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.recipe || !state.recipe.id) {
          state.actions.addErrorNotification("Cannot delete unsaved recipe");
        }
        const result = await client.mutate({
          mutation: DELETE_RECIPE,
          variables: { recipeId: state.recipe.id }
        });
        return result;
      },

      pasteRecipeToApp: async (client: ApolloClient<object>, recipeId: string) => {
        const { nodes, edges } = await loadRecipe(client, recipeId);
        set(state => $pasteGraph(state, nodes, edges));
        const nodesData = get().graph.nodes.map(n => n.data);
        await loadDynamicTypes(client, nodesData, get, set, false).catch(get().actions.addErrorNotification);
      },

      validatePluginDependencies: async (client: ApolloClient<object>) => {
        const state = get();
        const flow = createFlowFromZustand(
          state.graph.nodes.map(n => n.data),
          state.graph.edges,
        );
        await client.query({
          query: VALIDATE_APP,
          variables: { flow }
        }).then(res => {
          if (res.error) {
            get().actions.addErrorNotification(res.error);
          }
          else if (res.data.avalidateFlow) {
            set(state => {
              state.graph.validationResults = res.data.avalidateFlow;
            });
          }
        }).catch(get().actions.addErrorNotification);
      },


      updateDefaultEditorView: async (client: ApolloClient<object>, view: string) => {
        const state = get();
        if (state.app.id) {
          await client.mutate({
            mutation: UPDATE_FLOW_V2,
            variables: {
              flowId: state.app.id,
              defaultEditorView: view,
            }
          });
        }
      },

      loadStaticTypes: async (client: ApolloClient<object>) => {
        await client.query({ query: GET_STATIC_INFO })  // this is cached
          .then(res => {
            if (res.error) {
              get().actions.addErrorNotification(res.error);
            }
            else {
              set(state => {
                state.types.static = arrayToObject(res.data.alistPluginInfo, ['pluginType', 'static']);
                state.types.construct = arrayToObject(res.data.alistPluginConstructs, 'constructType');
              })
            }
          })
          .catch(get().actions.addErrorNotification);

      },

      loadDynamicTypes: async (client: ApolloClient<object>, cleanNodeData?: boolean) => {
        const state = get();
        await loadDynamicTypes(client, state.graph.nodes.map(n => n.data), get, set, cleanNodeData || false);
      },

      // when only nodeId is provided, construct param will be looked up from the graph,
      // and the node param/dv in graph will be cleaned after the dynamic type is loaded if cleanNodeData is true
      // otherwise nodeIdOrData is a TFlowPluginV2 object, and construct param will be taken from it
      loadDynamicType: async (client: ApolloClient<object>, nodeIdOrData: string | TFlowPluginV2, cleanNodeData?: boolean) => {
        await loadDynamicType(client, nodeIdOrData, get, set, cleanNodeData);
      },
    }
  }))))
)

//useEditorStore.subscribe(state => state.types, logDebug)

///// helper functions //////////////////////////////////////
// NOTE $ prefix functions are used by store functions to mutate the draft
// these functions cannot call functions inside the store

function getRelatedNodeIds(
  fromNodeId: string,
  distance: number,
  edgeMapping: { [source: string]: string },
): string[] {
  if (distance == 0) return [fromNodeId];
  const previousNodeIds = Object.entries(edgeMapping)
    .filter(([_, target]) => target === fromNodeId).map(([source]) => source);
  return previousNodeIds.flatMap(nodeId => getRelatedNodeIds(nodeId, distance - 1, edgeMapping));
}

async function loadApp(
  client: ApolloClient<object>,
  appId: string | null,
  get: () => EditorState,
  set: (fn: (state: EditorState) => any) => void,
  cleanNodeData: boolean,
) {
  set(state => {
    state.settingsMenu.selected = null;
    state.editorView.debugAppOpen = false;
    state.mode = 'app';
    state.app = defaultApp;
    state.recipe = null;
    state.graph = defaultGraph;
  })
  if (appId !== null) {
    const { data } = await client.query({
      query: GET_FLOW_V2,
      variables: { flowId: appId },
      fetchPolicy: 'no-cache',
    });
    const flow = data.agetFlowV2;
    const { nodesData, nodesLocations, edges } = getGraphForZustand(flow.config, flow.layout);
    set(state => {
      state.app.id = flow.id;
      state.app.aiConfig = flow.aiConfig;
      state.app.aiConfigByNode = flow.aiConfigByNode;
      state.app.id = flow.id;
      state.app.name = flow.name;
      state.app.isPublic = flow.isPublic;
      state.graph.nodes = Object.entries(nodesData).map(([id, data]) => ({
        id: id,
        data: data,
        position: nodesLocations[id],
        type: 'flowNode',
        selected: false,
      }));
      // have to use config since nodesData lost ordering
      state.app.startNodeId = flow.startNodeId || flow.config.plugins[0]?.id || null;
      // TODO the color needs to come from theme
      state.graph.edges = Object.values(edges);
      state.app.signinRequired = flow.signinRequired;
      state.editorView.templateView = flow.defaultEditorView;
      state.graph.typeSelections = flow.editorData;
    });
    await loadDynamicTypes(client, flow.config.plugins, get, set, cleanNodeData);
  }
}

async function loadDynamicTypes(
  client: ApolloClient<object>,
  plugins: TFlowPluginV2[],
  get: () => EditorState,
  set: (fn: (state: EditorState) => any) => void,
  cleanNodeData: boolean,
) {
  logDebug(`loading dynamic types for ${plugins.length} plugins`)
  const state = get();
  const typeCollectionKey = getDynamicTypeCollectionKey(state);

  // Filter plugins that need dynamic type info
  const dynamicPlugins = plugins.filter(plugin => plugin.pluginType?.dynamic);

  if (dynamicPlugins.length === 0) {
    return; // No dynamic plugins to load
  }

  // Prepare the configs for the batch query
  const configs = dynamicPlugins.map(plugin => removeTypename(plugin.pluginType.dynamic));

  await client.query({
    query: BATCH_GET_DYNAMIC_PLUGIN_INFO,
    variables: { configs },
    fetchPolicy: 'no-cache',
  }).then(res => {
    if (res.error) {
      get().actions.addErrorNotification(res.error);
    }
    else {
      set(state => {
        if (!(typeCollectionKey in state.types.dynamic)) {
          state.types.dynamic[typeCollectionKey] = {};
        }

        // Process each dynamic plugin info result
        res.data.abatchGetDynamicPluginInfo.forEach((dynamicTypeInfo, index) => {
          const plugin = dynamicPlugins[index];
          state.types.dynamic[typeCollectionKey][plugin.id] = dynamicTypeInfo;

          // Clean up node params after dynamic type is loaded
          if (cleanNodeData) {
            const nodeData = state.graph.nodes.find(n => n.id === plugin.id)?.data;
            if (nodeData) {
              const paramSchema = dynamicTypeInfo.parameterSchema;

              nodeData.params = cleanParams(nodeData.params, paramSchema);
              nodeData.dynamicParams = cleanDynamicParams(nodeData.dynamicParams, paramSchema);
            }
          }
        });
      });
    }
  }).catch(get().actions.addErrorNotification);
}

async function loadDynamicType(
  client: ApolloClient<object>,
  nodeIdOrData: string | TFlowPluginV2,
  get: () => EditorState,
  set: (fn: (state: EditorState) => any) => void,
  cleanNodeData?: boolean,
) {
  logDebug(`loading dynamic type for ${nodeIdOrData}`)
  const state = get();
  const typeCollectionKey = getDynamicTypeCollectionKey(state);
  const nodeId = typeof nodeIdOrData === 'string' ? nodeIdOrData : nodeIdOrData.id;
  let cparam = typeof nodeIdOrData === 'string'
    ? (
      state.graph.nodes.find(n => n.id === nodeId)?.data?.pluginType?.dynamic
      || state.types.dynamic[typeCollectionKey]?.[nodeId]?.pluginType?.dynamic
    )
    : nodeIdOrData.pluginType.dynamic;
  if (!cparam) {
    state.actions.addErrorNotification("Cannot load dynamic type without construct parameters.");
    return;
  }
  await client.query({
    query: GET_DYNAMIC_PLUGIN_INFO,
    variables: { config: removeTypename(cparam) },
    fetchPolicy: 'no-cache',
  }).then(res => {
    if (res.error) {
      get().actions.addErrorNotification(res.error);
    }
    else {
      set(state => {
        if (!(typeCollectionKey in state.types.dynamic)) {
          state.types.dynamic[typeCollectionKey] = {}
        }
        state.types.dynamic[typeCollectionKey][nodeId] = res.data.agetDynamicPluginInfo;

        // clean up the node param after dynamic type is loaded
        const nodeData = state.graph.nodes.find(n => n.id === nodeId)?.data;
        if (cleanNodeData && nodeData) {
          const dynamicTypeInfo = res.data.agetDynamicPluginInfo;
          const paramSchema = dynamicTypeInfo.parameterSchema;

          nodeData.params = cleanParams(nodeData.params, paramSchema);
          nodeData.dynamicParams = cleanDynamicParams(nodeData.dynamicParams, paramSchema);
        }
      })
    }
  })
    .catch(get().actions.addErrorNotification);
}


function createNodeId(nodeIds: string[]): string {
  let i = 1;
  while (true) {
    if (!nodeIds.includes(i.toString())) return i.toString();
    i++;
  }
}

function $addNode(
  state: EditorState,
  data: Omit<FlowNodeData, "id" | "__typename">,
  position: XYPosition,
): GraphNode {
  const nodeId = createNodeId(state.graph.nodes.map(n => n.id));
  const n: GraphNode = {
    id: nodeId,
    data: {
      ...data,
      id: nodeId,
      __typename: 'TFlowPluginV2',
    },
    position,
    type: 'flowNode',
  }
  state.graph.nodes.push(n);

  if (state.app && state.graph.nodes.length === 0) {
    state.app.startNodeId = nodeId;
  }

  return n;
}

// return a new object with references replaced
function replaceReferences(idMapping: Map<string, string>, original: DynamicValueParam) {
  if (!original) return original;

  if (Array.isArray(original)) {
    return original.map(v => typeof v === 'string' ? v : replaceReferences(idMapping, v));
  }

  if (isComposite(original)) {
    const newProps: { [key: string]: DynamicValueParam } = {};
    for (const [key, val] of Object.entries(original.props)) {
      newProps[key] = replaceReferences(idMapping, val);
    }
    return { props: newProps };
  }

  if (isComplex(original)) {
    return {
      ...original,
      values: original.values.map(v => replaceReferences(idMapping, v)),
    }
  }

  // isSimple
  const possibleNewId = idMapping.get(original.reference);
  if (possibleNewId) {
    return { ...original, reference: possibleNewId };
  }
  else return structuredClone(original);
}

// use current selections in graph if selections is not provided, in that case this function updates styling of selected nodes and edges
function $updateSelections(state: EditorState, selections?: Selection[]) {

  const trueSelections = selections || [
    ...state.graph.nodes.filter(n => n.selected).map(n => ({ type: 'node', id: n.id })),
    ...state.graph.edges.filter(e => e.selected).map(e => ({ type: 'edge', id: e.id })),
  ];

  state.graph.nodes = state.graph.nodes.map(n => ({ ...n, selected: false }));
  state.graph.edges = state.graph.edges.map(e => ({ ...e, selected: false, style: getEdgeStyle() }));

  for (const selection of trueSelections) {
    if (selection.type === 'node') {
      const node = state.graph.nodes.find(n => n.id === selection.id);
      if (node) node.selected = true;
    }
    else if (selection.type === 'edge') {
      const edge = state.graph.edges.find(e => e.id === selection.id);
      if (edge) {
        edge.selected = true;
        edge.style = getEdgeStyle(state.editorView.edgeHightlightColor);
      }
    }
  }
}

function $removeNodeFromState(nodeId: string, state: EditorState): { removedNode: GraphNode | undefined, removedEdges: Edge[] } {
  const removedNode = state.graph.nodes.find(n => n.id === nodeId);
  const removedEdges: Edge[] = [];
  if (removedNode) {
    state.graph.nodes = state.graph.nodes.filter(n => n.id !== nodeId);

    for (const e of state.graph.edges) {
      if (e.source === nodeId || e.target === nodeId) {
        const removedEdge = $removeEdgeFromState(e.id, state);
        if (removedEdge) {
          removedEdges.push(removedEdge);
        }
      }
    }
    if (state.app && state.graph.nodes.length === 0) {
      state.app.startNodeId = null;
    }
    delete state.graph.typeSelections[nodeId];
  }

  return { removedNode, removedEdges };
}

function $removeEdgeFromState(edgeId: string, state: EditorState): Edge | undefined {
  const edge = state.graph.edges.find(e => e.id === edgeId);
  state.graph.edges = state.graph.edges.filter(e => e.id !== edgeId);
  return edge || null;
}

function $recordAction(state: EditorState, action: ActionRecord) {
  const now = Date.now();
  const prevEntry = state.graph.pastActions[state.graph.pastActions.length - 1];
  if (now - prevEntry?.timestamp < 1000) {
    prevEntry.actions.push(action);
  }
  else {
    state.graph.pastActions.push({ timestamp: now, actions: [action] });
  }
  while (state.graph.pastActions.length > 50) {
    state.graph.pastActions.shift();
  }
  // clear out redo queue when new action is recorded
  state.graph.futureActions = [];
}

function $undoActions(state: EditorState, recordsToUndo: ActionHistory, recordsForRedo: ActionHistory) {
  if (recordsToUndo.length === 0) return;
  const record = recordsToUndo.pop();
  const revertRecord = { timestamp: Date.now(), actions: [] as ActionRecord[] };
  while (record && record.actions.length > 0) {
    const action = record.actions.pop();
    const reversedAction = $applyAction(state, action);
    revertRecord.actions.push(reversedAction);
  }
  if (revertRecord.actions.length > 0) {
    recordsForRedo.push(revertRecord);
  }
}

function $getSelections(state: EditorState): Selection[] {
  return [
    ...state.graph.nodes.filter(n => n.selected).map(n => ({ type: 'node', id: n.id })),
    ...state.graph.edges.filter(e => e.selected).map(e => ({ type: 'edge', id: e.id })),
  ] as Selection[];
}

// apply action to state, return the reversed action
function $applyAction(state: EditorState, action: ActionRecord): ActionRecord {


  const timestamp = Date.now();

  switch (action.action) {
    case 'add':
      const removedNodes = [];
      const removedEdges = []
      for (const id of action.nodeIds) {
        const removed = $removeNodeFromState(id, state);
        if (removed.removedNode) {
          removedNodes.push(removed.removedNode);
        }
        removedEdges.push(...removed.removedEdges);
      }
      for (const id of action.edgeIds) {
        const removed = $removeEdgeFromState(id, state);
        if (removed) {
          removedEdges.push(removed);
        }
      }
      $updateSelections(state, action.prevSelection);
      return { action: 'remove', nodes: removedNodes, edges: removedEdges };
    case 'remove':
      const currentSelections = $getSelections(state);
      state.graph.nodes.push(...action.nodes);
      state.graph.edges.push(...action.edges);
      $updateSelections(state, [
        ...action.nodes.map(n => ({ type: 'node', id: n.id })),
        ...action.edges.map(e => ({ type: 'edge', id: e.id })),
      ] as Selection[]);
      return {
        action: 'add',
        nodeIds: action.nodes.map(n => n.id),
        edgeIds: action.edges.map(e => e.id),
        prevSelection: currentSelections
      };
    case 'change':
      const node = state.graph.nodes.find(n => n.id === action.prevNodeData.id);
      if (node) {
        const prevNodeData = node.data;
        node.data = action.prevNodeData;
        $updateSelections(state, [{ type: 'node', id: node.id }]);
        return { action: 'change', prevNodeData };
      }
      // This case should theoretically never happen (if the history is properly tracked)
      // having it here for safety
      else {
        const prevSelection = $getSelections(state);
        $addNode(state, action.prevNodeData, { x: 0, y: 0 });
        return { action: 'add', nodeIds: [action.prevNodeData.id], edgeIds: [], prevSelection };
      }
  }
}

// helper function to cleanup params based on schema, removing any extra fields or field with mismatched types
// mutation happens in place of the params object, though in some cases the entire object is replaced
// so the return value should always be used
function cleanParams(
  params: any,
  schemaDef: JSONSchema7Definition,
): any {
  if (typeof schemaDef === 'boolean') {
    return schemaDef ? params : {};
  }

  // Handle anyOf types by returning the result that preserves most of the original data
  if (schemaDef.anyOf) {
    let best = {};
    for (const subSchema of schemaDef.anyOf) {
      const cleanedParams = cleanParams(params, subSchema);
      if (isEqual(cleanedParams, params)) {
        return cleanedParams;
      }
      if (JSON.stringify(cleanedParams).length > JSON.stringify(best).length) {
        best = cleanedParams;
      }
    }
    return best;
  }

  if (schemaDef.type === 'object' && typeof params === 'object' && !Array.isArray(params)) {
    const cleanedParams: any = {};
    for (const key in schemaDef.properties) {
      if (params.hasOwnProperty(key)) {
        const cleanedValue = cleanParams(params[key], schemaDef.properties[key]);
        if (cleanedValue !== undefined) {
          cleanedParams[key] = cleanedValue;
        }
      }
    }
    if (schemaDef.additionalProperties) {
      for (const key in params) {
        if (!schemaDef.properties.hasOwnProperty(key)) {
          const cleanedValue = cleanParams(params[key], schemaDef.additionalProperties);
          if (cleanedValue !== undefined) {
            cleanedParams[key] = cleanedValue;
          }
        }
      }
    }
    return cleanedParams;
  }

  if (schemaDef.type === 'array' && Array.isArray(params)) {
    const cleanedArray = params.map(item => {
      if (Array.isArray(schemaDef.items)) {
        return schemaDef.items.map(def => cleanParams(item, def)).find(cleanedItem => cleanedItem !== undefined);
      } else {
        return cleanParams(item, schemaDef.items);
      }
    }).filter(item => item !== undefined);
    return cleanedArray.length === params.length ? cleanedArray : [];
  }

  const typeMapping: { [key in JSONSchema7TypeName]: string } = {
    'string': 'string',
    'number': 'number',
    'integer': 'number',
    'boolean': 'boolean',
    'object': 'object',
    'array': 'array',
    'null': 'null'
  };

  const schemaTypes = Array.isArray(schemaDef.type) ? schemaDef.type : [schemaDef.type];
  if (schemaTypes.some(type => typeMapping[type] === typeof params)) {
    return params;
  }

  return {};
}

// helper function to cleanup dynamic params based on schema, removing any extra fields in composite DV
// mutation happens in place of the params object, though in some cases the entire object is replaced
// so the return value should always be used
function cleanDynamicParams(dv: DynamicValueParam | undefined, schemaDef: JSONSchema7Definition): DynamicValueParam | undefined {
  if (typeof schemaDef === 'boolean') {
    return dv;
  }

  // Handle anyOf types
  if (schemaDef.anyOf) {
    let best = undefined;
    for (const subSchema of schemaDef.anyOf) {
      const cleanedParams = cleanDynamicParams(dv, subSchema);
      if (isEqual(cleanedParams, dv)) {
        return cleanedParams;
      }
      if (best === undefined || JSON.stringify(cleanedParams).length > JSON.stringify(best).length) {
        best = cleanedParams;
      }
    }
    return best;
  }

  if (isComposite(dv)) {
    for (const key in dv.props) {
      if (schemaDef.properties && schemaDef.properties[key]) {
        const cleanedValue = cleanDynamicParams(dv.props[key], schemaDef.properties[key]);
        if (cleanedValue !== undefined && cleanedValue !== null) {
          dv.props[key] = cleanedValue;
        } else {
          delete dv.props[key];
        }
      } else if (schemaDef.additionalProperties) {
        const cleanedValue = cleanDynamicParams(dv.props[key], schemaDef.additionalProperties);
        if (cleanedValue !== undefined && cleanedValue !== null) {
          dv.props[key] = cleanedValue;
        } else {
          delete dv.props[key];
        }
      } else {
        delete dv.props[key];
      }
    }
    return dv;
  }
  else {
    return dv;
  }
}

function $pasteGraph(state: EditorState, nodes: GraphNode[], edges: Edge[], offset?: XYPosition) {
  if (nodes.length === 0 && edges.length === 0) return;

  const trueOffset = offset || { x: 50, y: 50 };
  const nodeIdMap = new Map<string, string>();
  const newNodes: GraphNode[] = [];
  for (const oldNode of nodes) {
    const newPosition = { x: oldNode.position.x + trueOffset.x, y: oldNode.position.y + trueOffset.y };
    const newNode = $addNode(state, JSON.parse(JSON.stringify(oldNode.data)), newPosition);
    nodeIdMap.set(oldNode.id, newNode.id);
    newNodes.push(newNode);
  }

  // update references for all newly copied nodes
  for (const newNode of newNodes) {
    newNode.data.dynamicParams = replaceReferences(nodeIdMap, newNode.data.dynamicParams);
  }

  const newEdges: Edge[] = [];
  for (const oldEdge of edges) {
    const newEdge = createEdge(
      nodeIdMap.get(oldEdge.source) || oldEdge.source,
      nodeIdMap.get(oldEdge.target) || oldEdge.target,
      oldEdge.sourceHandle,
      JSON.parse(JSON.stringify(oldEdge.data)),
    );
    state.graph.edges.push(newEdge);
    newEdges.push(newEdge);
  }

  const prevSelections = $getSelections(state);
  const record: ActionRecord = {
    action: 'add',
    nodeIds: newNodes.map(n => n.id),
    edgeIds: newEdges.map(e => e.id),
    prevSelection: prevSelections,
  };
  $recordAction(state, record);

  const newSelections = newNodes.map(n => ({ type: 'node', id: n.id })).concat(newEdges.map(e => ({ type: 'edge', id: e.id }))) as Selection[];
  $updateSelections(state, newSelections);
}

async function loadRecipe(client: ApolloClient<object>, recipeId: string): Promise<{ recipe: TRecipeInfo, nodes: GraphNode[], edges: Edge[] }> {
  const { data } = await client.query({
    query: GET_RECIPE,
    variables: { recipeId },
    fetchPolicy: 'no-cache',
  });
  const recipe = data.recipe;
  const { nodesData, nodesLocations, edges } = getGraphForZustand(recipe.flowConfig, recipe.layout);
  return {
    recipe: {
      id: recipe.id,
      name: recipe.name,
      description: recipe.description,
    },
    nodes: Object.entries(nodesData).map(([id, data]) => ({
      id: id,
      data: data,
      position: nodesLocations[id],
      type: 'flowNode',
      selected: false,
    })),
    edges: Object.values(edges)
  }
}
