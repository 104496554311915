import { CompositeDynamicValue, DynamicValueParam, isComposite } from "../../../types/DynamicValueTypes";

export type MixedArrayItem<T> = {
  value: T | undefined,
  dynamicValue: DynamicValueParam | undefined,
}

// NOTE: toMixedArray would return the static value when dynamicValue is not a Composite
export function toMixedArray<T>(value: T[] | null | undefined, dynamicValue: DynamicValueParam | undefined): MixedArrayItem<T>[] | undefined {
  if (!isComposite(dynamicValue)) {
    return value == null ? undefined : value.map(v => ({ value: v, dynamicValue: undefined }));
  }

  const ret: MixedArrayItem<T>[] = value.map(v => ({ value: v, dynamicValue: undefined }));
  for (const [key, val] of Object.entries(dynamicValue.props)) {
    const idx = parseInt(key);
    if (isNaN(idx)) {
      continue;
    }
    if (idx in (dynamicValue.items_to_merge || [])) {
      ret[idx].dynamicValue = val;
    }
    else {
      ret.splice(idx, 0, { value: undefined, dynamicValue: val });
    }
  }

  return ret;
}

export function fromMixedArray<T>(items: MixedArrayItem<T>[] | undefined): { value: T[] | undefined, dynamicValue: DynamicValueParam | undefined } {
  if (items == null) {
    return { value: undefined, dynamicValue: undefined };
  }

  const value: T[] = [];
  const dynamicValue: CompositeDynamicValue = { props: {}, items_to_merge: [] };
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.value !== undefined) {
      value.push(item.value);
    }
    if (item.dynamicValue !== undefined) {
      dynamicValue.props[i.toString()] = item.dynamicValue;
      if (item.value !== undefined) {
        dynamicValue.items_to_merge?.push(i);
      }
    }
  }

  if (Object.keys(dynamicValue.props).length === 0) {
    return { value, dynamicValue: undefined };
  }
  else {
    if (dynamicValue.items_to_merge?.length === 0) {
      delete dynamicValue.items_to_merge;
    }
    console.log(dynamicValue);
    return { value, dynamicValue };
  }
}
