import { Box, Button, Dialog, Divider, IconButton, LinearProgress, MenuItem, Popover, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { captureWebContent } from "../../utils/webContent";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { PluginCard, NodeCreationMenu } from "./Editor/PluginSelectionMenu";
import { useEditorStore } from "../../hooks/EditorState";
import { useShallow } from "zustand/react/shallow";
import { useApolloClient, useMutation } from "@apollo/client";
import { RUN_PLUGIN } from "../../graphql/mutation";
import { TFlowPluginV2, TStyling } from "../../../generated/gql/graphql";
import ConfigurationPanel from "./Editor/ConfigurationPanel";
import { removeTypename } from '../../utils/removeTypename';
import JsonView from "react18-json-view";
import { EmbeddedChatAppWithMemory } from './Client/Extension/EmbeddedChatApp';
import { useClientStore } from "../../hooks/ClientState";
import { useUserAndWorkspaceStore } from "../../hooks/UserAndWorkspaceStore";
import { ParamInputField } from "../../components/pixie/param-editor/lexical/ParamInputField";
import { DynamicValueParam } from "../../types/DynamicValueTypes";
import { JSONSchema7TypeName } from "json-schema";


function TestingDataStorageView<T>(props: {
  testChoice: TestChoice,
  data?: T,
  onLoad?: (loadedData: T) => void,
}): React.ReactElement {
  const [storedData, setStoredData] = React.useState<T[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function loadStoredData() {
    const data = localStorage.getItem(props.testChoice);
    if (data) {
      setStoredData(JSON.parse(data));
    }
    else {
      setStoredData([]);
    }
  }

  function saveData(data: T[]) {
    localStorage.setItem(props.testChoice, JSON.stringify(data));
  }

  useEffect(() => {
    loadStoredData();
  }, []);

  return <>
    {(storedData.length > 0 || props.data !== undefined) &&
      <Button variant='outlined' onClick={() => setDialogOpen(true)}>Testing Data Storage</Button>
    }
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='md' fullWidth>
      <Stack p={2} spacing={4} divider={<Divider />}>
        {storedData.map((data, i) => <Stack key={i}>
          <Stack direction='row' spacing={1}>
            <Button onClick={() => {
              const newData = storedData.filter((_, j) => j !== i)
              setStoredData(newData);
              saveData(newData);
            }}>Delete</Button>
            <Button onClick={() => {
              if (props.onLoad) {
                props.onLoad(data);
                setDialogOpen(false);
              }
            }}>Load</Button>
          </Stack>
          <pre>{JSON.stringify(data, undefined, 2)}</pre>
        </Stack>)}
      </Stack>
      {props.data !== undefined && <Button onClick={() => {
        const newData = [...storedData, props.data!]
        setStoredData(newData);
        saveData(newData);
      }}>Save Current Data</Button>}
    </Dialog>
  </>;
}


function TestCaptureWebContent(): React.ReactElement {

  const [capturedContent, setCapturedContent] = React.useState<string | null>(null);
  // const [originalContent, setOriginalContent] = React.useState<string | null>(null);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [copied, setCopied] = React.useState(false);

  async function onCaptureWebContent() {
    const content = await captureWebContent(
      iframeRef.current.contentDocument.body,
      iframeRef.current.contentWindow,
      (node) => {
        const elem = node as HTMLElement;
        console.log("node type:", node.nodeType, ", id:", elem.id, ', style text:', elem.style?.cssText);
        if (node.nodeType === Node.ELEMENT_NODE) {
          if (elem.id === 'bpr-guid-4439848') {
            const computedStyle = iframeRef.current.contentWindow.getComputedStyle(elem);
            console.log("computed style:", computedStyle);
            console.log("computed style display:", computedStyle.display);
          }
        }
      },
    );
    setCapturedContent(content.html);
  }

  useEffect(() => {
    document.getElementById('fileInput').addEventListener('change', function (event) {
      const file = (event.target as any).files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = function () {
          // setOriginalContent(reader.result as string);
          const iframe = iframeRef.current;
          iframe.contentWindow?.document.open();
          iframe.contentWindow?.document.write(reader.result as string);
          iframe.contentWindow?.document.close();
        }
        reader.readAsText(file);
      }
    });
  }, []);

  return <Stack spacing={2} p={4} display='flex' justifyContent='center'>
    <input type="file" id="fileInput" />
    <Button variant="contained" color="primary" onClick={onCaptureWebContent}>Capture html</Button>
    <iframe ref={iframeRef} style={{
      width: '100%',
      height: '80vh',
      border: '0px',

    }}></iframe>
    <Stack direction='row' spacing={1} display='flex' alignItems='center'>
      <IconButton onClick={() => {
        navigator.clipboard.writeText(capturedContent);
        setCopied(true);
      }}>
        <ContentCopyOutlinedIcon />
      </IconButton>
      <Typography variant="h6">{copied ? "Copied to clipboard" : ''}</Typography>
    </Stack>
    <Box width='100%'><pre>{capturedContent}</pre></Box>
  </Stack>;
}

function TestRunPlugin(): React.ReactElement {
  const [
    loadStaticTypes,
    addNode,
    removeNode,
  ] = useEditorStore(
    useShallow(state => [
      state.graphql.loadStaticTypes,
      state.actions.graph.addNode,
      state.actions.graph.removeNode,
    ])
  );
  const client = useApolloClient();

  useEffect(() => {
    loadStaticTypes(client);
  }, [loadStaticTypes]);

  const [nodeId, setNodeId] = React.useState<string>('');
  const node: TFlowPluginV2 | undefined = useEditorStore(useShallow(state => state.graph.nodes.find(n => n.id === nodeId)?.data));
  const pluginCardProps = useEditorStore(useShallow(state => {
    if (!node) {
      return null;
    }
    if (node.pluginType.static) {
      return {
        type: node.pluginType.static,
        info: state.types.static[node.pluginType.static]
      };
    }
    else {
      const constructTYpe = node.pluginType.dynamic!.constructType;
      return {
        type: constructTYpe,
        info: state.types.construct[constructTYpe]
      };
    }
  }));

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [runPlugin] = useMutation(RUN_PLUGIN);
  const siteId = useUserAndWorkspaceStore(state => state.workspaceId);
  const [inProgress, setInProgress] = React.useState(false);
  const [result, setResult] = React.useState<any>(undefined);

  return <Stack spacing={2}>
    <TestingDataStorageView testChoice={TestChoice.RunPlugin} data={node} onLoad={(loadedNode) => {
      if (loadedNode) {
        removeNode(nodeId);
        addNode(loadedNode, { x: 0, y: 0 }, n => {
          removeNode(nodeId);
          setNodeId(n.id);
        });
      }
    }} />
    <Box onClick={e => setAnchorEl(e.currentTarget)} p={1}>
      {pluginCardProps
        ? <>
          <PluginCard {...pluginCardProps} />
          <Typography variant='subtitle2' color='gray'>click to change</Typography>
        </>
        : <Typography variant='h6' textAlign='center' sx={{
          cursor: 'pointer',
          border: '1px solid black',
          p: 1,
          ":hover": {
            boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
          }
        }}>Click here to select plugin type</Typography>
      }
    </Box>
    <Popover
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <NodeCreationMenu onAddNode={n => {
        removeNode(nodeId);
        setNodeId(n.id);
        setAnchorEl(null);
      }} />
    </Popover>
    {nodeId && <ConfigurationPanel nodeId={nodeId} dynamicDisabled />}
    <Button variant='contained' disabled={!node} onClick={() => {
      if (node) {
        setInProgress(true);
        runPlugin({
          variables: {
            siteId,
            plugin: removeTypename(node),
          }
        }).then(setResult).catch(e => setResult(`Error: ${e.message}`)).finally(() => setInProgress(false));
      }
    }}>Run</Button>
    {inProgress
      ? <LinearProgress />
      : result === undefined
        ? <></>
        : <JsonView src={result} collapseStringsAfterLength={50} />
    }
  </Stack>
}


function TestEmbedUX(): React.ReactElement {

  const flowId = useClientStore(state => state.flowId);
  const setFlowId = useClientStore(state => state.setFlowId);
  const [tempFlowId, setTempFlowId] = React.useState<string>('');
  const [changeFlowIdTimeout, setChangeFlowIdTimeout] = React.useState<NodeJS.Timeout | null>(null);
  const [styling, setStyling] = React.useState<TStyling>({});

  useEffect(() => {
    console.log('setting flow id');
    setFlowId('104');
  }, []);

  useEffect(() => {
    setTempFlowId(flowId);
  }, [flowId]);

  return <Stack spacing={2} p={4}>
    <TextField label='App Id' value={tempFlowId} onChange={e => {
      if (changeFlowIdTimeout) {
        clearTimeout(changeFlowIdTimeout);
      }
      const newFlowId = e.target.value;
      setTempFlowId(newFlowId);
      const newTimeout = setTimeout(() => {
        setFlowId(newFlowId || '104');
      }, 500);
      setChangeFlowIdTimeout(newTimeout);
    }} />
    <JsonView src={styling} editable />
    {flowId && <EmbeddedChatAppWithMemory
      useLocalhost
      onStylingLoaded={({ palette, font, layout }) => setStyling({ palette, font, layout })}
    />}
  </Stack>;
}


function TestLexicalEditor(): React.ReactElement {

  const loadApp = useEditorStore(state => state.graphql.loadApp);
  const loadStaticTypes = useEditorStore(state => state.graphql.loadStaticTypes);
  const client = useApolloClient();

  useEffect(() => {
    loadApp(client, "158"); //copywriter
    loadStaticTypes(client);
  }, [loadApp, loadStaticTypes, client]);

  const [dv, setDv] = React.useState<DynamicValueParam | undefined>([
    "Hello, ",
    {
      reference: "2",
      access_path: [{ type: 'property', value: 'message' }],
    },
    "!\n How are you doing today?\n Great to have you!",
  ]);
  const [v, setV] = React.useState<any>(undefined);
  const [targetType, setTargetType] = React.useState<JSONSchema7TypeName | null>(null);

  const targetSchemaDef = targetType ? { type: targetType } : true;

  return <Stack spacing={2}>
    <Select value={targetType || ''} onChange={e => setTargetType(e.target.value as JSONSchema7TypeName)}>
      {['string', 'number', 'boolean', 'array', 'object', 'null'].map(type =>
        <MenuItem key={type} value={type}>{type}</MenuItem>
      )}
      <MenuItem value=''>Any</MenuItem>
    </Select>
    <ParamInputField
      schemaDef={targetSchemaDef}
      initialValue={v}
      initialDynamicValue={dv}
      onChange={(newV, newDv) => {
        setV(newV);
        setDv(newDv);
      }}
      resetTrigger=""
      debug
    />
    <Typography variant='h6'>Value</Typography>
    {v === undefined
      ? <Typography>no value</Typography>
      : <JsonView src={v} editable />
    }
    <Typography variant='h6'>Dynamic Value</Typography>
    {dv === undefined
      ? <Typography>no dynamic value</Typography>
      : <JsonView src={dv} editable />
    }
  </Stack>
}


function SimpleWebPage(): React.ReactElement {
  const [clicked, setClicked] = React.useState(false);

  return <Stack spacing={2} p={4}>
    <Button variant='contained' onClick={() => setClicked(true)} id="the-button">Click me</Button>
    {clicked && <Typography variant='h6'>You clicked!</Typography>}
  </Stack>

}


enum TestChoice {
  CaptureWebContent = 'CaptureWebContent',
  RunPlugin = 'RunPlugin',
  EmbedUX = "EmbedUX",
  LexicalEditor = 'LexicalEditor',
  SimpleWebPage = 'SimpleWebPage',
}

export function TestPage(): React.ReactElement {
  const { path } = useRouteMatch();
  const location = useLocation();

  const getCurrentTestChoice = () => {
    const choice = location.pathname.split('/').pop();
    if (Object.values(TestChoice).includes(choice as TestChoice)) {
      return choice as TestChoice;
    }
    return null;
  }

  const [testChoice, setTestChoice] = React.useState<TestChoice | null>(getCurrentTestChoice());
  const routerHistory = useHistory();

  const getPath = (choice: TestChoice) => `${path}/${choice}`;

  return <Stack spacing={4} p={4} display='flex' alignItems='center'>
    <ToggleButtonGroup exclusive color='secondary' value={testChoice || ''} onChange={(e, v) => {
      setTestChoice(v as TestChoice);
      routerHistory.push(getPath(v as TestChoice));
    }}>
      {Object.values(TestChoice).map(choice =>
        <ToggleButton key={choice} value={choice} sx={{ textTransform: 'none' }} id={choice}>
          <Typography variant="h6">{choice}</Typography>
        </ToggleButton>
      )}
    </ToggleButtonGroup>
    <Box width='100%'>
      <Switch>
        <Route path={getPath(TestChoice.CaptureWebContent)}>
          <TestCaptureWebContent />
        </Route>
        <Route path={getPath(TestChoice.RunPlugin)}>
          <TestRunPlugin />
        </Route>
        <Route path={getPath(TestChoice.EmbedUX)}>
          <TestEmbedUX />
        </Route>
        <Route path={getPath(TestChoice.LexicalEditor)}>
          <TestLexicalEditor />
        </Route>
        <Route path={getPath(TestChoice.SimpleWebPage)}>
          <SimpleWebPage />
        </Route>
        <Route>
          <Typography variant='h4'>Please select a test</Typography>
        </Route>
      </Switch>
    </Box>
  </Stack>;
}
