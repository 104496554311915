import React from 'react';
import { ContentContainer } from '../../business-profile/content-card';
import { Grid, Stack } from '@mui/material';

export default function CardLayout({ hero, title, summary, buttons }) {
  return <ContentContainer sx={{ minHeight: '100vh' }}>
    <Grid container spacing={3} alignItems='center' sx={{ height: '100%' }} >
      <Grid item xs={12} md={5}>
        {hero}
      </Grid>
      <Grid item xs={12} md={7}>
        <Stack spacing={3} sx={{ p: { xs: 4, md: 6, lg: 8 } }}>
          {title}
          {summary}
          {buttons}
        </Stack>
      </Grid>
    </Grid>
  </ ContentContainer>
}
