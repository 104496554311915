import React, { useContext, useEffect, useState } from 'react';
import { Alert, AppBar, Box, Button, Container, Divider, InputAdornment, LinearProgress, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import { useHistory, useParams } from 'react-router-dom';
import PixieIcon from '../../components/pixie/CompanyIcon';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { ACCEPT_INVITE, LOGOUT } from '../../graphql/mutation';
import { AppContext } from '../../contexts/AppContext';
import AuthButton from '../../components/Auth';
import { useUserAndWorkspaceStore } from '../../hooks/UserAndWorkspaceStore';
import { useShallow } from 'zustand/react/shallow';

export default function InvitePage(): React.ReactElement {
  const { code } = useParams<{ code: string | undefined }>();
  const height = use100vh()

  return <Stack sx={{
    height: `${height}px`,
    display: 'flex',
  }}>
    <Box p={3} position='absolute'><PixieIcon /></Box>
    <Container maxWidth='sm' sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
      <AcceptInviteForm initialCode={code} />
    </Container>
  </Stack>
}


function AcceptInviteForm(props: {
  initialCode?: string,
}): React.ReactElement {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password1, setPassword1] = useState<string>('');
  const [authError, setAuthError] = useState<ApolloError | GraphQLError | string | undefined>(undefined);
  const [inProgress, setInProgress] = useState(false);
  const [code, setCode] = useState(props.initialCode || '');
  const [acceptInvite] = useMutation(ACCEPT_INVITE);
  const { setError } = useContext(AppContext);
  const [
    user,
    loadIdentity,
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.user,
    state.loadIdentity,
  ]));
  const client = useApolloClient();
  const routerHistory = useHistory();
  const [logout] = useMutation(LOGOUT);

  useEffect(() => {
    document.title = "Developer Onboarding | GoPixie.ai";
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== password1) setAuthError('password mismatch')
    else {
      setInProgress(true);
      setAuthError(undefined);
      await acceptInvite({ variables: { code, email: username, password } })
        .then(res => {
          loadIdentity(client, true).then(() => routerHistory.push('/workspace'));
          if (!res.data) setAuthError(res.errors?.[0] || 'something went wrong');
          else {
            setAuthError(undefined);
          }
        })
        .catch(e => setAuthError(e))
        .finally(() => setInProgress(false));
    }
  }

  if (user) {
    return <Stack p={{ xs: 2, md: 4, lg: 6 }} width='100%' spacing={3} display='flex' justifyContent='center' alignItems='center'>

      <Typography variant='h6'>You are signed-in as <b>{user.username}</b>. Please sign-out to continue new developer onboarding.</Typography>
      {inProgress
        ? <LinearProgress />
        : <Button variant='outlined' onClick={() => {
          setInProgress(true);
          logout()
            .then(r => {
              loadIdentity(client, false);
              setInProgress(false);
            })
            .catch(setError)
        }}>Sign out</Button>
      }
    </Stack>
  }

  return <form style={{ height: '100%', display: 'flex', justifyContent: 'center', width: '100%' }}>
    <Stack p={{ xs: 2, md: 4, lg: 6 }} width='100%' spacing={3} display='flex' justifyContent='center'>
      {authError && <Alert variant='outlined' severity='error'>{typeof authError === 'string' ? authError : authError.message}</Alert>}
      {!props.initialCode && <>
        <TextField
          disabled={inProgress}
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder='invite code'
          InputProps={{ style: { textAlign: 'center' } }}
        />
        <Divider />
      </>}
      <TextField
        disabled={inProgress}
        placeholder='email'
        autoComplete='username'
        variant="outlined"
        onChange={e => setUsername(e.target.value)}
        value={username}
        InputProps={{
          style: { textAlign: 'center' },
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        disabled={inProgress}
        type='password'
        placeholder='password'
        autoComplete={'new-password'}
        variant="outlined"
        onChange={e => setPassword(e.target.value)}
        value={password}
        InputProps={{
          style: { textAlign: 'center' },
          startAdornment: (
            <InputAdornment position="start">
              <PasswordOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        disabled={inProgress}
        type='password'
        placeholder='repeat password'
        autoComplete='new-password'
        variant="outlined"
        onChange={e => setPassword1(e.target.value)}
        value={password1}
        InputProps={{
          style: { textAlign: 'center' },
          startAdornment: (
            <InputAdornment position="start">
              <PasswordOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <Divider variant='middle' />
      {inProgress
        ? <LinearProgress />
        : <Button
          variant='contained'
          size='large'
          color='secondary'
          onClick={handleSubmit}
          disabled={!code || inProgress}
        >
          <Typography variant='h5'><b>Join</b></Typography>
        </Button>
      }
    </Stack>
  </form>
}
