import React from "react";
import { Button, Image } from "../../../generated/gql/graphql";
import { Buttons, ContentContainer, Hero, Summary, Title, withEditing } from "../business-profile/content-card";
import { Grid, Stack, useTheme } from "@mui/material";
import CardLayout from "../itento/layout/card";
import HeadlineLayout from "../itento/layout/headline";

interface IntroProps {
  id: string,
  title: string,
  variant: 'card' | 'mural' | 'overlay' | 'headline',
  subtitle?: string | null,
  description?: string | null,
  buttons: Button[],
  images: Image[],
  editing?: boolean,
  onSave?: (sprops: IntroProps) => Promise<void>,
}

function IntroBase(props: IntroProps): React.ReactElement {
  const layoutProps = {
    hero: <Hero images={props.images} />,
    title: <Title
      editing={props.editing}
      title={props.title}
      subtitle={props.subtitle || undefined}
      size={props.variant == 'headline' ? "medium" : "small"}
      onSave={async sprops => await props.onSave?.({ ...props, title: sprops.title, subtitle: sprops.subtitle })}
    />,

    summary: props.description ? <Summary
      editing={props.editing}
      summary={props.description}
      onSave={async sprops => await props.onSave?.({ ...props, description: sprops.summary as string })}
    /> : undefined,
    buttons: <Buttons buttons={props.buttons} size='large' />,
  };
  switch (props.variant) {
    case 'card': return <CardLayout {...layoutProps} />
    case 'headline': return <HeadlineLayout {...layoutProps} />
    default: return <CardLayout {...layoutProps} />
  }
}

const Intro = withEditing<IntroProps>(IntroBase, props => { return { id: props.id, label: props.title } });
export default Intro;
