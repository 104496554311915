// communication methods between content script and GoPixie's web client/editor

import { AppState } from "../../../../types/AppState";

export type RunAppCommand = {
  type: 'runApp';
  flowId?: string,
  clientId?: string;
  appState?: AppState;
}


export type HandshakeCommand = {
  type: 'handshake';
}

export type ExtensionCommand = RunAppCommand | HandshakeCommand;


type HandshakeAck = {
  type: 'handshakeAck';
}

function isRunAppCommand(command: ExtensionCommand | HandshakeAck): command is RunAppCommand {
  return command.type === 'runApp';
}

function isHandshakeCommand(command: ExtensionCommand | HandshakeAck): command is HandshakeCommand {
  return command.type === 'handshake';
}

export function sendCommandToExtension(command: ExtensionCommand) {
  window.postMessage(command);
}

function registerMessageHandler(handler: (message: ExtensionCommand | HandshakeAck) => void): () => void {
  const listener = (event: MessageEvent<ExtensionCommand | HandshakeAck>) => {
    if (event.source !== window) {
      return;
    }
    const message = event.data;
    if (message && message.type) {
      handler(message);
    }
  };
  window.addEventListener('message', listener);
  return () => {
    window.removeEventListener('message', listener);
  }
}

function messageWebClientHandshake() {
  window.postMessage({ type: 'handshakeAck' });
}


export function handleHandshake(): () => void {
  return registerMessageHandler((message) => {
    if (isHandshakeCommand(message)) {
      messageWebClientHandshake();
    }
  });
}

export function waitForHandshakeComplete(): Promise<void> {
  return new Promise((resolve) => {
    const unsubscribe = registerMessageHandler((message) => {
      if (message.type === 'handshakeAck') {
        unsubscribe();
        resolve();
      }
    });
  });
}

export function handleRunApp(handler: (flowId?: string, clientId?: string, appState?: AppState) => void): () => void {
  return registerMessageHandler((message) => {
    if (isRunAppCommand(message) && (message.flowId || message.clientId)) {
      handler(message.flowId, message.clientId, message.appState);
    }
  });
}
