import { TextField, Typography as MuiTypography, TypographyProps, Box, CircularProgress } from "@mui/material";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import Markdown from "./material-markdown";

export interface EditableTypographyProps extends PropsWithChildren<TypographyProps> {
  value: string,
  onSave: (value: string) => Promise<any>,
  editing?: boolean,
  markdown?: boolean,
  multiline?: boolean,
}

export default function EditableTypography({
  editing,
  onSave,
  markdown,
  value,
  multiline,
  ...childProps
}: EditableTypographyProps): React.ReactElement {
  const [isFocused, setIsFocused] = useState(false);
  const [saving, setSaving] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<any>(null);

  const cancelInput = () => {
    setInputValue(value);
    setIsFocused(false);
  }

  const saveInput = () => {
    setIsFocused(false);
    if (onSave) {
      setSaving(true);
      onSave(inputValue).finally(() => setSaving(false));
    }
  }

  // update to latest value when the props changed
  useEffect(() => {
    setInputValue(value)
  }, [value]);

  useEffect(() => {
    if (!editing) cancelInput();
  }, [editing]);

  const handleClick = () => {
    if (editing) {
      setIsFocused(true);
      // according to chatgpt this can fix some weird problem that onBlur not getting called
      setTimeout(() => {
        inputRef.current!.focus();
      }, 100);
    }
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      cancelInput();
    }
    if (e.key == 'Enter' && !e.shiftKey) {
      e.preventDefault();
      saveInput();
    }
  }

  return saving
    ? <CircularProgress />
    : <Box onClick={handleClick}>
      <TextField
        multiline={multiline}
        inputRef={inputRef}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        // use display instead of conditional rendering to make sure the inputRef is always set for focus/blurring
        sx={{ ...childProps.sx, display: editing && isFocused ? 'inherit' : 'none' }}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      {markdown
        ? <Box sx={{ display: editing && isFocused ? 'none' : 'inherit' }}><Markdown variant={childProps.variant}>{value}</Markdown></Box>
        : <MuiTypography {...childProps} sx={{ ...childProps.sx, display: editing && isFocused ? 'none' : 'inherit' }}>
          {value}
        </MuiTypography>
      }
    </Box>
}
