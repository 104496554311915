import { graphql } from '../../generated/gql/gql';

export const START_RUN = graphql(`
subscription StartRun($flowId: ID!, $startId: String, $userInputs: [IUserInput!]!, $clientId: String){
  astartRun(flowId: $flowId, startId: $startId, userInputs: $userInputs, clientId: $clientId) {
    ...AppRunResultFragment
  }
}`);

export const CONTINUE_RUN = graphql(`
subscription ContinueRun($userInputs: [IUserInput!]!, $clientId: String!, $replayMessages: Boolean){
  acontinueRun(userInputs: $userInputs, clientId: $clientId, replayMessages: $replayMessages) {
    ...AppRunResultFragment
  }
}`);

export const START_DEBUG_RUN = graphql(`
subscription StartDebugRun(
  $siteId: ID!,
  $flow: IFlowConfig!,
  $startId: String,
  $userInputs: [IUserInput!]!,
  $flowId: ID,
  $clientId: String,
  $aiConfigJson: JSON,
  $breakpoints: [String!]
){
  astartDebugRun(
    siteId: $siteId,
    flow: $flow,
    startId: $startId,
    userInputs: $userInputs,
    flowId: $flowId,
    clientId: $clientId,
    aiConfigJson: $aiConfigJson,
    breakpoints: $breakpoints
  ) {
    ...AppDebugRunResultFragment
  }
}`);

export const CONTINUE_DEBUG_RUN = graphql(`
subscription ContinueDebugRun(
  $siteId: ID!,
  $userInputs: [IUserInput!]!,
  $clientId: String!,
  $aiConfigJson: JSON,
  $replayMessages: Boolean,
  $rerunOptions: IRerunOptions,
  $breakpoints: [String!],
) {
  acontinueDebugRun(
    siteId: $siteId,
    userInputs: $userInputs,
    clientId: $clientId,
    aiConfigJson: $aiConfigJson,
    replayMessages: $replayMessages,
    rerunOptions: $rerunOptions,
    breakpoints: $breakpoints,
  ) {
    ...AppDebugRunResultFragment
  }
}`);
