import { Stack, Box, IconButton, Dialog, Link, Button } from '@mui/material';
import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import displayOptions from './customization/display-options';

export default function DismissibleDialog(props: {
  open: boolean,
  setOpen: (open: boolean) => void,
  children: React.ReactNode,
}): React.ReactElement {
  return <Dialog open={props.open}>
    <IconButton onClick={_e => props.setOpen(false)} sx={{ display: { xs: 'none', md: 'block' }, width: 'max-content', position: 'absolute', right: 0, top: 0 }}>
      <CloseRoundedIcon />
    </IconButton>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: displayOptions.page.p }}>
      {props.children}
    </Box>
    <Button size='small' onClick={_e => props.setOpen(false)} sx={{ display: { xs: 'block', md: 'none' } }}>Close</Button>
  </Dialog>;
}
