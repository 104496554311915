import React, { useState } from "react";


export const EmailSubscriptionContext = React.createContext({
  hasSubscribed: false,
  setHasSubscribed: (val: boolean) => { },
});


export function EmailSubscriptionContextProvider({ children }): React.ReactElement {
  const [hasSubscribed, setHasSubscribed] = useState(false);

  return <EmailSubscriptionContext.Provider value={{ hasSubscribed, setHasSubscribed }}>
    {children}
  </EmailSubscriptionContext.Provider>;
}
