import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Container,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Intro from '../../../components/content/intro';
import { GraphQLDataProvider } from '../../../components/data-provider';
import { GET_ABOUT, GET_CONTACT, GET_INTRO, GET_SERVICES } from '../../../graphql/query';
import ContactForm from '../../../components/business-profile/contact-form';
import DismissibleDialog from '../../../components/dismissible-dialog';
import ServiceListing from '../../../components/content/service-list';
import displayOptions from '../../../components/customization/display-options';
import About from '../../../components/content/about';
import Contact from '../../../components/content/contact';
import { SiteIdContext } from '../../../contexts/SiteIdContext';
import PixieIcon from '../../../components/pixie/CompanyIcon';
import AuthButton from '../../../components/Auth';
import { useHistory } from 'react-router-dom';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';


export default function LandingPage() {
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { initialSiteId } = useContext(SiteIdContext);
  const theme = useTheme();
  const [contactOpen, setContactOpen] = useState(false);
  const siteIds = useUserAndWorkspaceStore(state => state.workspaces.map(w => w.id));
  const routerHistory = useHistory();

  useEffect(() => {
    document.title = "GoPixie.ai";
  }, []);

  return <>
    <AppBar position='relative' elevation={0} color='inherit'>
      <Toolbar disableGutters sx={{ p: 1 }}>
        <Stack direction='row' width='100%' spacing={2} p={2} display='flex' justifyContent='space-between' alignItems='center'>
          <PixieIcon />
          <Stack direction='row' spacing={2} display='flex' alignItems='center'>
            {siteIds.length > 0 && <Button id="go-to-workspace" variant='outlined' color='secondary' sx={{ textTransform: 'none' }} onClick={() => {
              routerHistory.push('/workspace')
            }}>
              Go to Workspace
            </Button>}
            <AuthButton />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
    <Container>
      <Stack minHeight='100vh' display='flex' justifyContent='center' alignItems='center' spacing={4} pt={displayOptions.menu.spacingHeight}>
        {isSmallScreen ? <Toolbar /> : undefined}
        <GraphQLDataProvider
          query={GET_INTRO}
          options={{ variables: { siteId: initialSiteId } }}
          getChildren={data => data.intro ? <Intro {...data.intro} variant='headline' /> : <></>}
        />

        <GraphQLDataProvider
          query={GET_SERVICES}
          options={{ variables: { siteId: initialSiteId } }}
          getChildren={data => <ServiceListing data={data} />}
        />
        <GraphQLDataProvider
          query={GET_ABOUT}
          options={{ variables: { siteId: initialSiteId } }}
          getChildren={data => data.about ? <About {...data.about} /> : <></>}
        />

        <GraphQLDataProvider
          query={GET_CONTACT}
          options={{ variables: { siteId: initialSiteId } }}
          getChildren={data => data.contact ? <Contact {...data.contact} /> : <></>}
        />
        <Typography variant='subtitle2' color={theme.palette.text.secondary} p={1}>© 2024 Elf.ai LLC</Typography>
      </Stack>
      <DismissibleDialog open={contactOpen} setOpen={setContactOpen}>
        <ContactForm siteId={initialSiteId} onSubmit={() => setContactOpen(false)} />
      </DismissibleDialog>
    </Container>
  </>
}
