import { Box, Button, Grid, Stack, SxProps, Typography } from '@mui/material';
import React, { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import displayOptions from '../customization/display-options';
import { useTheme } from '@mui/material/styles';
import TagList from '../tag-list';
import { useHistory } from 'react-router-dom';
import { Button as GqlButton, Image, OpenDrawer, OpenLink, Route, Subscribe } from '../../../generated/gql/graphql';
import { AppContext } from '../../contexts/AppContext';
import { ItentoAppContext } from "../../contexts/ItentoAppContext";
import { PageInEdit } from "../../types/PageInEdit";
import EditableTypography from '../editable-typography';
import { Variant } from '@mui/material/styles/createTypography';
import EmailSubscription from '../EmailSubscription';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';


export function ActionButton(props: {
  label: string,
  action?: OpenDrawer | OpenLink | Route | Subscribe,
  size?: 'large' | 'medium' | 'small',
}): React.ReactElement {
  if (!props.action)
    return <Button
      size={props.size}
      variant='outlined'
      disabled={true}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
        borderRadius: 30,
        minWidth: 150,
        p: 1,
        pl: 4,
        pr: 4,
      }}
    >
      <Typography variant='h6'>{props.label}</Typography>
      <NavigateNextRoundedIcon />
    </Button>;

  if (props.action.__typename == 'Subscribe')
    return <EmailSubscription label={props.label} successLabel={(props.action as Subscribe).successLabel} />;

  const routerHistory = useHistory();
  const { setChatOpen } = useContext(ItentoAppContext);

  const btnProps: any = {};
  switch (props.action.__typename) {
    case 'Route':
      btnProps.onClick = () => routerHistory.push((props.action as Route).path);
      break;
    case 'OpenLink':
      btnProps.href = props.action.url;
      btnProps.target = props.action.newWindow ? '_blank' : '_self';
      break;
    case 'OpenDrawer':
      btnProps.onClick = () => setChatOpen(true);
      break;
  }
  return <Button
    size={props.size}
    variant='outlined'
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'none',
      borderRadius: 30,
      minWidth: 150,
      p: 1,
      pl: 4,
      pr: 4,
    }}
    {...btnProps}
  >
    <Typography variant='h6'>{props.label}</Typography>
    <NavigateNextRoundedIcon />
  </Button>;
}


export function Buttons(props: {
  sx?: SxProps,
  buttons?: GqlButton[],
  articleSlug?: string,
  size?: 'large' | 'medium' | 'small',
}): React.ReactElement {
  const routerHistory = useHistory();
  const buttons = props.buttons || [];

  const readMore = props.articleSlug
    ? <Button
      variant='contained'
      size={props.size}
      sx={{ textAlign: 'center', textTransform: 'none' }}
      onClick={() => routerHistory.push(`/${props.articleSlug}/`)}
    >
      Read More
    </Button>
    : <></>;
  return <Stack direction='row' spacing={2}>
    {buttons.map((b, idx) => <ActionButton key={`content-card-action-${idx}`} label={b.label} action={b.actions[0]} size={props.size} />)}
    {readMore}
  </Stack>
}

export type TextSize = 'large' | 'medium' | 'small';

export interface TitleProps {
  title: string,
  subtitle?: string,
  tags?: string[],
  size?: TextSize,
  editing?: boolean,
  onSave?: (props: TitleProps) => Promise<void>,
}

interface TextVariant {
  title: Variant,
  subtitle: Variant,
}

export function Title(props: TitleProps): React.ReactElement {
  const size = props.size || 'medium';
  const editing = props.editing && props.onSave !== undefined;

  function getTextVariant(): TextVariant {
    switch (size) {
      case 'large': return {
        title: 'h1',
        subtitle: 'h3',
      }
      case 'medium': return {
        title: 'h2',
        subtitle: 'h5',
      }
      case 'small': return {
        title: 'h3',
        subtitle: 'h6',
      }
    }
  }
  const textVariant = getTextVariant();

  return <Stack spacing={4}>
    <TagList tags={props.tags} />
    <EditableTypography
      editing={editing}
      variant={textVariant.title}
      value={props.title}
      onSave={async title => {
        if (props.onSave) await props.onSave({ ...props, title })
      }}
      sx={{ fontWeight: 'bolder' }}
    />
    {props.subtitle
      ? <EditableTypography
        editing={editing}
        variant={textVariant.subtitle}
        value={props.subtitle}
        onSave={async subtitle => {
          if (props.onSave) await props.onSave({ ...props, subtitle })
        }}
      />
      : <></>
    }
  </Stack>
}

export interface SummaryProps {
  summary?: React.ReactNode,
  size?: TextSize,
  editing?: boolean,
  onSave?: (props: SummaryProps) => Promise<void>,
}

interface TextVariant {
  title: Variant,
  subtitle: Variant,
}

export function Summary(props: SummaryProps): React.ReactElement {
  const size = props.size || 'large';
  const editing = props.editing && props.onSave !== undefined;

  function getTextVariant(): Variant {
    switch (size) {
      case 'large':
        return 'h5';
      case 'medium':
        return 'body1';
      case 'small':
        return 'body2';
    }
  }
  const textVariant = getTextVariant();

  return typeof props.summary == 'string'
    ? <EditableTypography
      multiline
      markdown
      value={props.summary}
      editing={editing}
      onSave={async summary => {
        if (props.onSave) await props.onSave({ ...props, summary })
      }}
      variant={textVariant}
    />
    : <>{props.summary}</>;
}

export function Hero(props: {
  images: Image[],
  isBackground?: boolean,
  backgroundShadeColor?: string,
}): React.ReactElement {
  const theme = useTheme();
  // Utility function to convert literal color to hex code
  const colorToHex = (color) => {
    const tempElem = document.createElement('div');
    tempElem.style.color = color;
    const colorString = getComputedStyle(tempElem).color;
    return colorString.split(' ').join('').toLowerCase();
  };

  // Utility function to convert hex code to RGB
  const colorToRgb = (hex) => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  const backgroundShadeColor = props.backgroundShadeColor || theme.palette.background.default;
  const backgroundShadeHex = backgroundShadeColor.includes('#') ? backgroundShadeColor : colorToHex(backgroundShadeColor);

  if (props.images.length == 0) return <></>;
  return props.isBackground
    ? <>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        backgroundImage: `url(${props.images[0].url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: `rgba(${colorToRgb(backgroundShadeHex)}, 0.5)`,
        backgroundBlendMode: 'multiply',
      }} />
    </>
    : <Box
      component='img'
      key={`image-${props.images[0].id}`}
      src={props.images[0].url}
      sx={{ width: '100%' }}
    />;
}

export interface EditableContent<OnSaveParams> {
  editing?: boolean,
  onSave?: (newProps: OnSaveParams) => Promise<any>,
}

export function withEditing<Props extends EditableContent<Props>>(
  WrappedComponent: ComponentType<Props>,
  getPageInfo: (props: Props) => PageInEdit,
) {
  // Define the component that will be returned by the HOC
  const WithEditing: React.FC<Omit<Props, "editing">> = (props) => {
    const [editing, setEditing] = useState(false);
    const { pageInEdit, setPageInEdit } = useContext(ItentoAppContext);
    const [editedProps, setEditedProps] = useState(props);
    const touchPressTimer = useRef<NodeJS.Timeout>();

    // has to do a type assertion here otherwise TS is not happy
    const propsWithEdit = { ...editedProps, editing, onSave: saveEdit } as Props;
    const pageInfo = getPageInfo(propsWithEdit);

    useEffect(() => {
      setEditedProps({ ...props });
    }, [props]);

    useEffect(() => {
      setEditing(pageInEdit?.id == pageInfo.id && pageInEdit?.label == pageInfo.label);
    }, [pageInEdit, pageInfo]);


    function startEdit() {
      if (props.onSave) {
        setPageInEdit({ ...pageInfo });
      }
    }

    async function saveEdit(newProps: Props) {
      if (props.onSave) {
        await props.onSave(newProps).then(_ => setEditedProps({ ...editedProps, ...newProps }));
      }
    }

    const handleTouchPress = () => {
      touchPressTimer.current = setTimeout(() => {
        startEdit();
      }, 2000); // Timeout set to 1 second or 1000 ms
    }

    const handleTouchRelease = () => {
      clearTimeout(touchPressTimer.current);
    }


    return <Box
      onDoubleClick={startEdit}
      onTouchStart={handleTouchPress}
      onTouchEnd={handleTouchRelease}
      onContextMenu={e => e.preventDefault()}
    ><WrappedComponent {...propsWithEdit} /></Box>;
  };

  // Optionally, add a display name for the HOC component (for better debugging)
  const hocDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithEditing.displayName = `withEditing(${hocDisplayName})`;

  return WithEditing;
}

export function ContentContainer(props: {
  title?: string,
  sx?: SxProps,
  children: React.ReactNode,
}) {
  const theme = useTheme();
  const { sx, children } = props;

  return <Stack
    sx={{
      // this is important, otherwise the center layout's background will be positioned in relation to body instead of the container
      position: 'relative',
      width: '100%',
      m: 0,
      scrollSnapAlign: 'start',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      userSelect: 'none',
      // pt: displayOptions.menu.spacingHeight,
      // pb: displayOptions.menu.spacingHeight,
      backgroundColor: theme.palette.background.default,
      ...sx,
    }}
  >
    {props.title
      ? <Typography variant="h3" sx={{
        width: '100%',
        textAlign: 'center',
        mb: theme.spacing(4),
        pb: displayOptions.page.p,
        pl: displayOptions.page.p,
        pr: displayOptions.page.p,
      }}>{props.title}</Typography>
      : <></>
    }
    <Box sx={{
      pl: displayOptions.page.p,
      pr: displayOptions.page.p,
    }}>
      {children}
    </Box>
  </Stack>
}

export type ContentCardVariant = 'left' | 'right' | 'bottom' | 'center' | 'text-listing';

export interface ContentCardProps {
  id: string,
  title: string,
  subtitle?: string,
  summary?: React.ReactNode,
  body?: string,
  buttons?: GqlButton[],
  hero?: Image,
  tags?: string[],
  sx?: SxProps<Theme>,
  // content positioning, for center the hero will become background
  variant?: ContentCardVariant,
  onSave?: (props: ContentCardProps) => Promise<void>,
  size?: TextSize,
  editing?: boolean,
}

function ContentCardBase(props: ContentCardProps): React.ReactElement {
  const [editedProps, setEditedProps] = useState(props);

  async function updateProps(changedProps) {
    const updatedProps = { ...editedProps, ...changedProps };
    setEditedProps(updatedProps);
    await props.onSave!(updatedProps);
  }

  const variant = props.variant || 'left';

  function contentCol() {
    switch (variant) {
      case 'left':
      case 'right':
        return displayOptions.page.grid.columnSize;
      case 'bottom':
      case 'center':
      case 'text-listing':
        return { xs: 12, md: 12, lg: 12 };
    }
  }
  const contentColumnCount = contentCol();

  function heroCol() {
    if (!props.hero) return 0;
    switch (variant) {
      case 'text-listing':
        return 0;
      case 'left':
      case 'right':
        return 4;
      case 'bottom':
      case 'center':
        return 12;
    }
  }
  const heroColumnCount = heroCol();

  const title = <Title {...props} onSave={async titleProps => await updateProps({ ...titleProps })} />

  const summary = <Summary {...props} onSave={async summaryProps => await updateProps({ ...summaryProps })} />

  const buttons = <Buttons buttons={props.buttons || []} />

  const content =
    <Grid item xs={contentColumnCount.xs} md={contentColumnCount.md - heroColumnCount} lg={contentColumnCount.lg - heroColumnCount}>
      <Stack direction='column' spacing={3}>
        {props.variant == 'text-listing'
          ? <>{title}{buttons}{summary}</>
          : <>{title}{summary}{buttons}</>
        }
      </Stack>
    </Grid>;

  const hero = <Grid item xs={12} md={heroColumnCount}>
    <Hero images={props.hero ? [props.hero] : []} isBackground={props.variant == 'center'} />
  </Grid>

  function getLayout(): React.ReactNode {
    switch (variant) {
      case 'left':
        return <>
          {content}
          {hero}
        </>;
      case 'right':
      case 'bottom':
      case 'center':
        return <>
          {hero}
          {content}
        </>;
      case 'text-listing':
        return content;
    }
  }

  return <Grid
    container
    alignItems='center'
    spacing={displayOptions.page.grid.spacing}
  >
    {getLayout()}
  </Grid>;
}


const ContentCard = withEditing<ContentCardProps>(ContentCardBase, props => { return { id: props.id, label: props.title } });
export default ContentCard;
