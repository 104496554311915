import { graphql } from '../../generated/gql/gql';

export const UPLOAD_DOCUMENT = graphql(`
  mutation UploadDocument($siteId: ID!, $file: Upload!, $description: String) {
    auploadDocument(siteId: $siteId, file: $file, description: $description)
  }
`);

export const DELETE_DOCUMENT = graphql(`
  mutation DeleteDocument($documentId: ID!) {
    adeleteDocument(documentId: $documentId)
  }
`);

export const UPLOAD_IMAGE = graphql(`
  mutation UploadImage($siteId: ID!, $description: String!, $file: Upload!) {
    auploadImage(siteId: $siteId, description: $description, file: $file)
  }
`);


export const ADD_DATASET = graphql(`
  mutation AddDataset($file: Upload!, $siteId: ID, $flowId: ID, $name: String!, $description: String, $columnDescriptions:[IKeyValue!]) {
    aaddDataset(file: $file, siteId: $siteId, flowId: $flowId, name: $name, description: $description, columnDescriptions: $columnDescriptions)
  }
`);

export const UPDATE_DATASET = graphql(`
  mutation UpdateDataset($datasetId: ID!, $name: String, $file: Upload, $format: TDatasetFormat, $description: String, $columnDescriptions:[IKeyValue!]) {
    updateDataset(datasetId: $datasetId, name: $name, file: $file, format: $format, description: $description, columnDescriptions: $columnDescriptions)
  }
`);

export const SUBMIT_CONTACT_FORM = graphql(`
  mutation SubmitContactForm($siteId: ID!, $email: String!, $subscribeForUpdate: Boolean, $comment: String) {
    asubmitContactForm(siteId: $siteId, email: $email, subscribeForUpdate: $subscribeForUpdate, comment: $comment)
  }
`);

export const UPDATE_BLOG = graphql(`
mutation UpdateBlog($blogId: ID!, $title: String!, $introduction: String!, $article: String!) {
  aupdateBlog(blogId: $blogId, title: $title, introduction: $introduction, article: $article) {
    id
  }
}
`);

export const LOGIN = graphql(`
mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    userId
    username
    email
    isDeveloper
  }
}
`);

export const LOGOUT = graphql(`
mutation Logout {
  logout
}
`);

export const SIGNUP = graphql(`
mutation Signup($email: String! $password: String!) {
  signup(email: $email, password: $password) {
    userId
    username
    email
    isDeveloper
  }
}
`);

export const ACCEPT_INVITE = graphql(`
mutation AcceptInvite($code: String!, $email: String! $password: String!) {
  aacceptInvite(code: $code, email: $email, password: $password) {
    workspaceId
  }
}
`);

export const ADD_SECRET = graphql(`
mutation AddSecret($siteId: ID!, $name: String!, $secretType: SecretType!, $value: JSON!) {
  aaddSecret(siteId: $siteId, name: $name, secretType: $secretType, value: $value)
}
`);

export const DELETE_SECRET = graphql(`
mutation DeleteSecret($siteId: ID!, $secretId: ID!) {
  adeleteSecret(siteId: $siteId, secretId: $secretId)
}
`);

export const MESSAGE_FLOW_V2 = graphql(`
  mutation MessageFlowV2($userInput: IUserInput!, $clientId: String!){
    amessageFlowV2(userInput: $userInput, clientId: $clientId) {
      role
      content
    }
  }
`);

export const CREATE_FLOW_V2 = graphql(`
  mutation CreateFlowV2(
    $siteId: ID!,
    $flowName: String!,
    $flow: IFlowConfig!,
    $layout: [IFlowNodePositionV2!]!,
    $isPublic: Boolean!,
    $startNodeId: String,
    $aiConfig: JSON,
    $aiConfigByNode: JSON,
    $signinRequired: Boolean,
  ){
    acreateFlowV2(
      siteId: $siteId,
      flowName: $flowName,
      flow: $flow,
      layout: $layout,
      isPublic: $isPublic,
      startNodeId: $startNodeId,
      aiConfig: $aiConfig,
      aiConfigByNode: $aiConfigByNode,
      signinRequired: $signinRequired,
    )
  }
`);

export const UPDATE_FLOW_V2 = graphql(`
mutation UpdateFlowV2(
  $flowId: ID!,
  $flowName: String,
  $flow: IFlowConfig,
  $layout: [IFlowNodePositionV2!],
  $isPublic: Boolean,
  $startNodeId: String,
  $aiConfig: JSON,
  $aiConfigByNode: JSON,
  $signinRequired: Boolean,
  $defaultEditorView: ID,
  $editorData: JSON,
){
  aupdateFlowV2(
    flowId: $flowId,
    flowName: $flowName,
    flow: $flow,
    layout: $layout,
    isPublic: $isPublic,
    startNodeId: $startNodeId,
    aiConfig: $aiConfig,
    aiConfigByNode: $aiConfigByNode,
    signinRequired: $signinRequired,
    defaultEditorView: $defaultEditorView,
    editorData: $editorData,
  ) {
    id
  }
}
`);

export const DELETE_FLOW_V2 = graphql(`
  mutation DeleteFlowV2($flowId: ID!){
    adeleteFlowV2(flowId: $flowId)
  }
`);

// export const SAVE_LABELED_RESULT = graphql(`
// mutation SaveLabeledResult($flowId: ID!, $pluginId: String!, $clientId: String!, $nodeId: String!, $version: Int!, $label: DataLabel!, $resultOverride: JSON) {
//   asaveLabeledResult(
//     flowId: $flowId,
//     pluginId: $pluginId,
//     clientId: $clientId,
//     nodeId: $nodeId,
//     version: $version,
//     label: $label,
//     resultOverride: $resultOverride,
// 	)
// }
// `);


export const ADD_WHATSAPP_WEBHOOK = graphql(`
mutation AddWhatsappWebhook($siteId: ID!, $flowId: ID!, $secretId: ID!) {
  aaddWhatsappWebhook(siteId: $siteId, flowId: $flowId, secretId: $secretId)
}
`);

export const RUN_PLUGIN = graphql(`
mutation RunPlugin($siteId: ID!, $plugin: IFlowPluginV2!, $aiConfig: JSON) {
  arunPlugin(siteId: $siteId, plugin: $plugin, aiConfig: $aiConfig)
}
`);

export const UPLOAD_FILE = graphql(`
  mutation UploadFile($file: Upload!, $siteId: ID, $flowId: ID) {
    auploadFile(file: $file, siteId: $siteId, flowId: $flowId)
  }
`);

export const UPLOAD_FILE_FROM_URL = graphql(`
  mutation UploadFileFromUrl($url: String!, $siteId: ID, $flowId: ID, $siteMapUrl: String) {
    auploadFileFromUrl(url: $url, siteId: $siteId, flowId: $flowId, siteMapUrl: $siteMapUrl)
  }
`);

export const UPLOAD_FILES_FROM_SITE_MAP = graphql(`
  mutation UploadFilesFromSiteMap($siteMapUrl: String!, $siteId: ID, $flowId: ID) {
    auploadFilesFromSiteMap(siteMapUrl: $siteMapUrl, siteId: $siteId, flowId: $flowId)
  }
`);

export const DELETE_FILE = graphql(`
  mutation DeleteFile($siteId: ID!, $fileId: ID!) {
    adeleteFile(siteId: $siteId, fileId: $fileId) {
      id
      name
      createdAt
      url
      siteMapUrl
    }
  }
`);

export const SET_STYLING = graphql(`
  mutation SetStyling($flowId: ID!, $palette: IPalette, $font: IFont, $layout: ILayout, $openAfter: Int, $icon: IIcon) {
    asetStyling(flowId: $flowId, palette: $palette, font: $font, layout: $layout, openAfter: $openAfter, icon: $icon)
  }
`);

export const DELETE_STYLING = graphql(`
  mutation DeleteStyling($flowId: ID!) {
    adeleteStyling(flowId: $flowId)
  }
`);

export const DELETE_APP_TEMPLATE = graphql(`
  mutation DeleteAppTemplate($templateId: ID!) {
    adeleteAppTemplate(templateId: $templateId)
  }
`);

export const CREATE_APP_TEMPLATE = graphql(`
  mutation CreateAppTemplate($flowId: ID!, $settingsNodes: [String!], $title: String) {
    acreateAppTemplate(flowId: $flowId, settingsNodes: $settingsNodes, title: $title)
  }
`);

export const START_CHECKOUT = graphql(`
  mutation StartCheckout($siteId: ID!, $subscriptionPlan: SubscriptionPlan!) {
    astartCheckout(siteId: $siteId, subscriptionPlan: $subscriptionPlan)
  }
`);

export const START_BILLING_PORTAL_SESSION = graphql(`
  mutation StartBillingPortalSession($siteId: ID!) {
    astartBillingPortalSession(siteId: $siteId)
  }
`);

export const UPDATE_EDITOR_SETTINGS = graphql(`
  mutation UpdateEditorSettings($editorSettings: IEditorSettings!) {
    updateEditorSettings(editorSettings: $editorSettings)
  }
`);

export const CREATE_RECIPE = graphql(`
  mutation CreateRecipe($name: String!, $flowConfig: IFlowConfig!, $description: String, $layout: [IFlowNodePositionV2!]) {
    acreateRecipe(name: $name, flowConfig: $flowConfig, description: $description, layout: $layout) {
      ...RecipeInfoFragment
    }
  }
`);

export const UPDATE_RECIPE = graphql(`
  mutation UpdateRecipe($recipeId: ID!, $name: String, $flowConfig: IFlowConfig, $description: String, $layout: [IFlowNodePositionV2!]) {
    aupdateRecipe(recipeId: $recipeId, name: $name, flowConfig: $flowConfig, description: $description, layout: $layout) {
      ...RecipeInfoFragment
    }
  }
`);

export const DELETE_RECIPE = graphql(`
  mutation DeleteRecipe($recipeId: ID!) {
    adeleteRecipe(recipeId: $recipeId)
  }
`);

export const LABEL_AI_PLUGIN_RUNS = graphql(`
  mutation LabelAiPluginRuns($labels: [IPluginRunLabel!]!) {
    alabelAiPluginRuns(labels: $labels)
  }
`);

export const AUTO_LABEL_AI_PLUGIN_RUNS = graphql(`
  mutation AutoLabelAiPluginRuns($appId: ID!, $nodeId: String!, $instructions: String!) {
    aautoLabelAiPluginRuns(appId: $appId, nodeId: $nodeId, instructions: $instructions) {
      sessionId
      totalLabeled
      labeledPositive
      labeledNegative
    }
  }
`);

export const CREATE_FINETUNE_MODEL = graphql(`
  mutation CreateFinetuneModel($appId: ID!, $nodeId: String!) {
    acreateFinetuneModel(appId: $appId, nodeId: $nodeId) {
      id
      status
    }
  }
`);

export const CHECK_FINETUNING_JOB_STATUSES = graphql(`
  mutation CheckFinetuningJobStatuses($appId: ID!) {
    acheckFinetuningJobStatuses(appId: $appId) {
      id
      status
    }
  }
`);

export const CREATE_KNOWLEDGE_BASE = graphql(`
  mutation CreateKnowledgeBase($workspaceId: ID!, $name: String!, $description: String) {
    acreateKnowledgeBase(workspaceId: $workspaceId, name: $name, description: $description) {
      id
      name
      description
    }
  }
`);

export const DELETE_KNOWLEDGE_BASE = graphql(`
  mutation DeleteKnowledgeBase($id: ID!) {
    adeleteKnowledgeBase(id: $id) {
      id
    }
  }
`);

export const ADD_DOCUMENTS_TO_KNOWLEDGE_BASE = graphql(`
  mutation AddDocumentsToKnowledgeBase($knowledgeBaseId: ID!, $fileIds: [ID!]!) {
    aaddDocumentsToKnowledgeBase(knowledgeBaseId: $knowledgeBaseId, fileIds: $fileIds)
  }
`);

export const REMOVE_DOCUMENTS_FROM_KNOWLEDGE_BASE = graphql(`
  mutation RemoveDocumentsFromKnowledgeBase($knowledgeBaseId: ID!, $fileIds: [ID!]!) {
    aremoveDocumentsFromKnowledgeBase(knowledgeBaseId: $knowledgeBaseId, fileIds: $fileIds)
  }
`);

export const CREATE_APP_FROM_TEMPLATE = graphql(`
  mutation CreateAppFromTemplate($siteId: ID!, $templateId: ID!, $name: String!) {
    acreateAppFromTemplate(siteId: $siteId, templateId: $templateId, name: $name)
  }
`);
