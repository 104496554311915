import React, { useContext, useState, useCallback, useEffect } from 'react';
import { GET_AI_PLUGIN_RUNS } from '../../../../graphql/query';
import { useQuery } from '@apollo/client';
import { AppContext } from '../../../../contexts/AppContext';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Card,
  TextField,
  InputAdornment,
  Divider,
  MenuItem,
  Select,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';

import DatasetRoundedIcon from '@mui/icons-material/DatasetRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { VerticalCarousel } from '../../../../components/carousel-v2';
import { FlowNodeName } from '../FlowNodeName';
import JsonView from 'react18-json-view';
import { ArrayInputField } from '../../../../components/pixie/param-editor/ArrayInputField';
import { TaiPluginRun } from '../../../../../generated/gql/graphql';
import { AiResponseRating } from '../../../../components/pixie/AiResponseRating';
import { NodeSelect } from '../../../../components/pixie/param-editor/NodeSelect';

const PAGE_SIZE = 20; // Number of items per page

export function AiLearningPanel(): React.ReactElement {
  const rerunFrom = useEditorStore(useShallow(state => state.app?.rerunFromNodeAfterBreakpoint));
  const setRerunFrom = useEditorStore(state => state.actions.setRerunFromNodeAfterBreakpoint);
  const breakpoints = useEditorStore(useShallow(state => state.app?.breakpoints || []));
  const setBreakpoints = useEditorStore(state => state.actions.setBreakpoints);
  const nodeIds = useEditorStore(useShallow(state => state.graph.nodes.map(n => n.id)));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [runsById, setRunsById] = useState<Map<string, TaiPluginRun>>(new Map());
  const [currentRunId, setCurrentRunId] = useState<string | null>(null);
  const [orderedIds, setOrderedIds] = useState<string[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [targetIndex, setTargetIndex] = useState<number>(0);
  const selectedAiNodeIdInGraph = useEditorStore(state => {
    const nodeId = state.actions.graph.getSelectedNodeId();
    const requiresAi = nodeId && state.actions.graph.getTypeInfo()[nodeId]?.pluginInfo?.requiresAi;
    return requiresAi ? nodeId : null;
  });
  const appId = useEditorStore(state => state.app.id);
  const { setError } = useContext(AppContext);
  const [selectedAiNodeIdInPanel, setSelectedAiNodeIdInPanel] = useState<string | null>(null);
  const nodeTypeInfo = useEditorStore(state => state.actions.graph.getTypeInfo());
  const [unlabeledOnly, setUnlabeledOnly] = useState(false);

  const { data: runsData, loading, refetch, fetchMore } = useQuery(GET_AI_PLUGIN_RUNS, {
    variables: {
      appId: appId as string,
      nodeId: selectedAiNodeIdInPanel!,
      limit: PAGE_SIZE,
      skip: 0,
      unlabeledOnly,
    },
    skip: !appId || !selectedAiNodeIdInPanel,
    onError: setError,
  });

  useEffect(() => {
    if (selectedAiNodeIdInGraph) {
      setSelectedAiNodeIdInPanel(selectedAiNodeIdInGraph);
    }
  }, [selectedAiNodeIdInGraph]);

  useEffect(() => {
    if (runsData?.agetAiPluginRuns) {
      const newRunsById = new Map(runsData.agetAiPluginRuns.results.map(run => [run.id, run]));
      const newOrderedIds = runsData.agetAiPluginRuns.results.map(run => run.id);

      setRunsById(newRunsById);
      setOrderedIds(newOrderedIds);
      setCurrentRunId(newOrderedIds[0] || null);
    }
  }, [runsData]);

  const loadMoreRuns = useCallback(async (skip: number, targetIndex: number) => {
    if (isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      const result = await fetchMore({
        variables: {
          skip,
          limit: PAGE_SIZE,
        },
      });

      if (result.data.agetAiPluginRuns) {
        const newRuns = result.data.agetAiPluginRuns.results;
        setRunsById(prev => {
          const updated = new Map(prev);
          newRuns.forEach(run => updated.set(run.id, run));
          return updated;
        });

        setOrderedIds(prev => {
          const updated = [...prev];
          newRuns.forEach((run, idx) => {
            const position = skip + idx;
            if (position < updated.length) {
              updated[position] = run.id;
            } else {
              updated.push(run.id);
            }
          });
          // Set the current run ID to the target index after loading
          if (targetIndex >= 0 && targetIndex < updated.length) {
            setCurrentRunId(updated[targetIndex]);
          }
          return updated;
        });
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoadingMore(false);
    }
  }, [fetchMore, isLoadingMore, setError]);

  const handleRunChange = async (newTargetIndex: number) => {
    if (newTargetIndex < 0 || newTargetIndex >= (pageInfo?.totalResultCount || 0)) return;

    setTargetIndex(newTargetIndex); // Update immediately for UI
    const targetPage = Math.floor(newTargetIndex / PAGE_SIZE);
    const skip = targetPage * PAGE_SIZE;

    if (newTargetIndex >= orderedIds.length) {
      await loadMoreRuns(skip, newTargetIndex);
    } else {
      const targetId = orderedIds[newTargetIndex];
      if (targetId) {
        setCurrentRunId(targetId);
      }
    }
  };

  const pageInfo = runsData?.agetAiPluginRuns?.pageInfo;
  const totalRuns = pageInfo?.totalResultCount || 0;
  const currentRun = currentRunId ? runsById.get(currentRunId) : undefined;
  const currentIndex = currentRunId ? orderedIds.indexOf(currentRunId) : -1;

  return <Stack spacing={2}>
    <Typography variant='subtitle1'><b>AI Learning Configurations</b></Typography>
    <NodeSelect
      selectedNodeId={rerunFrom?.nodeId}
      onChange={(nodeId) => {
        if (nodeId) {
          setRerunFrom({ nodeId, nodeVersion: 0 });
        } else {
          setRerunFrom(null);
        }
      }}
    />
    {rerunFrom && <ArrayInputField
      value={breakpoints}
      onChange={setBreakpoints}
      renderItemEditor={(itemValue, onChange, idx) => (
        <NodeSelect
          key={idx}
          selectedNodeId={itemValue}
          onChange={onChange}
        />
      )}
      defaultItemValue={() => nodeIds[0]}
    />}
    <Divider />
    <Typography variant='subtitle1'><b>Past runs</b></Typography>
    {!appId ? (
      <Typography>Save the app to start recording AI function runs.</Typography>
    ) : (
      <Stack spacing={2}>
        <NodeSelect
          selectedNodeId={selectedAiNodeIdInPanel}
          onChange={nodeId => setSelectedAiNodeIdInPanel(nodeId)}
          filterOptions={id => nodeTypeInfo[id]?.pluginInfo?.requiresAi}
        />
        {!selectedAiNodeIdInPanel
          ? <Typography>Select an AI-powered function to view past runs.</Typography>
          : loading
            ? <LinearProgress />
            : <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={unlabeledOnly}
                    onChange={(e) => setUnlabeledOnly(e.target.checked)}
                  />
                }
                label="Unlabelled only"
              />
              {pageInfo == null || pageInfo.totalResultCount == 0 ? (
                <Typography>No AI runs recorded yet.</Typography>
              ) : (
                <Button
                  variant='outlined'
                  onClick={() => setDialogOpen(true)}
                  sx={{ textTransform: 'none' }}
                >
                  View {pageInfo?.totalResultCount || 0} past runs
                </Button>
              )}
            </Stack>
        }
      </Stack>
    )}

    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Typography variant='h6'>Past runs of </Typography>
          <FlowNodeName variant='description' nodeId={selectedAiNodeIdInPanel!} />
        </Stack>
        <IconButton onClick={() => setDialogOpen(false)}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>

      {loading ? (
        <LinearProgress />
      ) : orderedIds.length === 0 ? (
        <Typography p={2}>No AI runs recorded yet.</Typography>
      ) : (
        <Stack spacing={2} p={2}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
            <IconButton
              onClick={() => handleRunChange(currentIndex - 1)}
              disabled={currentIndex <= 0 || isLoadingMore}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <TextField
              size="small"
              sx={{ width: '100px' }}
              value={isLoadingMore ? targetIndex + 1 : currentIndex + 1}
              onChange={(e) => {
                const val = parseInt(e.target.value) - 1;
                if (!isNaN(val)) handleRunChange(val);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">of {totalRuns}</InputAdornment>,
              }}
              disabled={isLoadingMore}
            />

            <IconButton
              onClick={() => handleRunChange(currentIndex + 1)}
              disabled={currentIndex >= totalRuns - 1 || isLoadingMore}
            >
              <NavigateNextIcon />
            </IconButton>
          </Stack>

          {isLoadingMore ? <Box sx={{ height: '70vh' }}>
            <LinearProgress />
          </Box> : currentRun ? (
            <Stack spacing={1} height='70vh'>
              <Typography variant="subtitle2" color="text.secondary">
                {new Date(currentRun.createdAt).toLocaleString()}
              </Typography>


              <AiResponseRating
                label={currentRun.label}
                aiPluginRunLogIds={[currentRun.id]}
                onSave={() => refetch()}
              />
              <Stack spacing={2} p={2} divider={<Divider />} sx={{ overflowY: 'auto' }}>

                <Stack spacing={1}>
                  <Typography variant="subtitle2">Result</Typography>
                  <JsonView src={currentRun.pluginResult} />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="subtitle2">Parameters</Typography>
                  <JsonView src={currentRun.actualizedParam} />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="subtitle2">AI Input</Typography>
                  <JsonView src={{
                    messages: currentRun.aiInput,
                    tools: currentRun.functionDescriptions,
                    toolChoices: currentRun.toolChoices
                  }}
                  />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="subtitle2">AI Output</Typography>
                  <JsonView src={currentRun.aiResponse} />
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Typography>Loading run data...</Typography>
          )}
        </Stack>
      )}
    </Dialog>
  </Stack>;
}
