import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketConnectionContext } from "../../../../contexts/WebSocketConnectionContext";

export function GraphQLClientProvider({ children }): React.ReactElement {
  const [graphqlClient, setgraphqlClient] = useState<ApolloClient<any> | undefined>(undefined);
  const [hasClosed, setHasClosed] = useState(false);
  const [connectedTrigger, setConnectedTrigger] = useState(false);

  useEffect(() => {
    const httpLink = createUploadLink({
      uri: '/graphql'
    });

    function getWsUrl() {
      var loc = window.location, new_uri;
      if (loc.protocol === "https:") {
        new_uri = "wss:";
      } else {
        new_uri = "ws:";
      }
      new_uri += "//" + loc.host + "/graphql";
      return new_uri;
    }

    const wsLink = new GraphQLWsLink(createClient({
      url: getWsUrl(),
      on: {
        closed: () => setHasClosed(true),
        connected: () => setConnectedTrigger(t => !t),
      },
      retryWait(retries) {
        // Implementing exponential backoff
        // Example: wait 1s after the first attempt, 2s after the second, 4s after the third, etc.
        const delay = Math.min(1000 * Math.pow(2, retries), 30000); // Cap the delay at 30 seconds
        return new Promise(resolve => setTimeout(resolve, delay));
      },
    }));

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );
    setgraphqlClient(new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
      assumeImmutableResults: true,
    }));
  }, []);

  return graphqlClient
    ? <ApolloProvider client={graphqlClient}>
      <WebSocketConnectionContext.Provider value={{ hasClosed, connectedTrigger }}>
        {children}
      </WebSocketConnectionContext.Provider>
    </ApolloProvider>
    : <LinearProgress />;
}
