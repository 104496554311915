import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import JsonView from 'react18-json-view';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';

import { TypeString } from '../common';
import { ParamInputField as LexicalParamInputField } from './lexical/ParamInputField';
import { useEditorStore } from '../../../hooks/EditorState';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';
import { AccessPathSegment, DynamicValueParam, isComposite } from '../../../types/DynamicValueTypes';
import {
  getSchema,
  typeToString,
  getMatchingDefinitionIndex,
  getDynamicValue,
  getValue,
  getUpdatedDynamicValue,
  getUpdatedValue,
} from '../../../utils/dynamic-value-utils';
import { StaticParamInputField } from './StaticParamInputField';
import { FlowParamInputType } from '../../../../generated/gql/graphql';
import { ArrayInputField } from './ArrayInputField';
import JsonSchemaRefDialog from './ParamEditor.JsonSchemaRefDialog';
import { MixedArrayItem, fromMixedArray, toMixedArray } from './ParamEditor.mixed-array';
import KnowledgeBaseSelect from '../../input/KnowledgeBaseSelect';

function isPropertyRequired(schema: JSONSchema7, propertyName: string): boolean {
  return schema.required?.includes(propertyName) && getSchema(schema.properties?.[propertyName])?.default === undefined;
}

function TypeAnnotation(props: {
  // The overall schemaDef with anyOf (if applicable), not the selected one
  schemaDef: JSONSchema7Definition,
  // expected to be 0 for non-anyOf schema when it's not dynamic
  selectedOrInferredType: number | 'dynamic',
  onSelectType: (selected: number | 'dynamic' | null) => void,
  dynamicDisabled?: boolean,
}): React.ReactElement {
  const theme = useTheme();
  const subSchemaDefs = useMemo(() => {
    const schema = getSchema(props.schemaDef);
    if (!schema?.anyOf) {
      return [props.schemaDef];
    }
    if (schema.default === null) {
      return schema.anyOf.filter(def => getSchema(def)?.type !== 'null');
    }
    else {
      return schema.anyOf;
    }
  }, [props.schemaDef]);

  const clickable = subSchemaDefs.length > 1 || !props.dynamicDisabled;

  return <Box pb={2}>
    <Stack
      direction='row'
      divider={<Typography sx={{ color: theme.palette.text.disabled }}>|</Typography>}
      flexWrap='wrap'
      alignItems='center'
      gap={1}
    >
      {subSchemaDefs.map((def, idx) =>
        <TypeString
          key={idx}
          onClick={clickable
            ? () => props.onSelectType(props.selectedOrInferredType === idx ? null : idx)
            : undefined
          }
          sx={{
            cursor: clickable ? 'pointer' : 'default',
            userSelect: 'none',
            fontWeight: idx === props.selectedOrInferredType ? 'bold' : 'normal',
            color: idx === props.selectedOrInferredType
              ? theme.palette.primary.main
              : props.selectedOrInferredType === 'dynamic'
                ? theme.palette.primary.light
                : theme.palette.text.disabled,
            border: idx === props.selectedOrInferredType
              ? `1px solid ${theme.palette.primary.main}`
              : props.selectedOrInferredType === 'dynamic'
                ? `1px solid ${theme.palette.primary.light}`
                : undefined,
          }}
        >
          {typeToString(def)}
        </TypeString>
      )}
      {!props.dynamicDisabled && <TypeString
        onClick={() => props.onSelectType(props.selectedOrInferredType === 'dynamic' ? null : 'dynamic')}
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
          fontWeight: props.selectedOrInferredType === 'dynamic' ? 'bold' : 'normal',
          color: props.selectedOrInferredType === 'dynamic' ? theme.palette.warning.main : theme.palette.text.disabled,
          border: props.selectedOrInferredType === 'dynamic' ? `1px solid ${theme.palette.warning.main}` : undefined,
        }}
      >
        <AutoAwesomeIcon fontSize='inherit' /> Dynamic
      </TypeString>}
    </Stack>
  </Box>
}


function getInitialValue(schemaDef: JSONSchema7Definition): any {
  const schema = getSchema(schemaDef);
  if (!schema) {
    return undefined;
  }
  if (schema.default !== undefined) {
    return schema.default;
  }
  if (schema.enum?.length) {
    return schema.enum[0];
  }
  switch (schema.type) {
    case 'object':
      return Object.fromEntries(
        Object.entries(schema.properties || {})
          .filter(([k]) => isPropertyRequired(schema, k))
          .map(([k, subSchemaDef]) => [k, getInitialValue(subSchemaDef)])
          .filter(([_, v]) => v !== undefined)
      );
    case 'array':
      return [];
    case 'string':
      return '';
    case 'number':
    case 'integer':
      return 0;
    case 'boolean':
      return false;
    case 'null':
      return null;
    default:
      if (Array.isArray(schema.type)) {
        for (const type of schema.type) {
          const value = getInitialValue({ ...schema, type });
          if (value !== undefined) {
            return value;
          }
        }
      }
      for (const subSchemaDef of schema.anyOf || []) {
        const value = getInitialValue(subSchemaDef);
        if (value !== undefined) {
          return value;
        }
      }
      return undefined;
  }
}


function useTypeSelectionStorage(
  uniqueId?: string,
  path?: AccessPathSegment[],
  resetTrigger?: any,
) {
  // code related to anyOf schema
  const storeTypeSelection = useEditorStore(state => uniqueId
    ? state.actions.getParamEditorTypeSelection(uniqueId, path)
    : null
  );
  const _setStoreTypeSelection = useEditorStore(state => state.actions.setParamEditorTypeSelection);
  const setStoreTypeSelection = useCallback((selection: number | 'dynamic' | null) => {
    _setStoreTypeSelection(uniqueId, path, selection);
  }, [uniqueId, path, _setStoreTypeSelection]);

  const [localTypeSelection, setLocalTypeSelection] = React.useState<number | 'dynamic' | null>(null);

  useEffect(() => {
    if (resetTrigger !== undefined) {
      setLocalTypeSelection(null);
    }
  }, [resetTrigger]);

  const storage = useMemo(
    () => uniqueId
      ? { typeSelection: storeTypeSelection, setTypeSelection: setStoreTypeSelection }
      : { typeSelection: localTypeSelection, setTypeSelection: setLocalTypeSelection },
    [uniqueId, storeTypeSelection, setStoreTypeSelection, localTypeSelection]
  );

  return storage;
}


type ParamEditorCommonProps = {
  schemaDef: JSONSchema7Definition,
  value: any,
  dynamicValue?: DynamicValueParam,
  // NOTE this has to be a single function instead of separate functions for value/dynamic value changes
  // because the way how update is implemented, separate value/dynamic value update calls would cause the first update to be discarded
  onChange: (value: any, dynamicValue: DynamicValueParam | undefined) => void,
  dynamicDisabled?: boolean,
  resetTrigger?: any,
  // uniqueId is used to persist type selections for the editor instance
  // as well as for resetting the dynamic-enabled input fields when uniqueId changes
  // if not provided, the type selections state will be local to the component
  uniqueId?: string,
}


function isComplexField(schemaDef: JSONSchema7Definition): boolean {
  const schema = getSchema(schemaDef);
  if (!schema) {
    return false;
  }
  switch (schema.type) {
    case 'object':
    case 'array':
      return true;
    default:
      return false;
  }
}


function ParamEditorInternal(props: ParamEditorCommonProps & {
  path: AccessPathSegment[],
  optional?: boolean,
  dev?: boolean,
  hideRootHeader?: boolean,
  // indentation offset for the header, negative value to reduce indentation, positive value to increase
  indentationOffset?: number,
  // optionally transforming schema before rendering each field
  transformFieldSchemaDef?: (schemaDef: JSONSchema7Definition) => JSONSchema7Definition,
  customEditorRenderer?: (props: ParamEditorCommonProps & { defaultEditor: React.ReactNode, typeSelection: number | "dynamic" | null }) => React.ReactNode,
}): React.ReactElement {
  const [showDev, setShowDev] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const { typeSelection, setTypeSelection } = useTypeSelectionStorage(props.uniqueId, props.path, props.resetTrigger);

  // selected type if typeSelection is not null, 0 for non-anyOf schema, or cannot infer type
  const selectedOrInferredType = useMemo(() => {
    if (typeSelection !== null) {
      return typeSelection;
    }
    const schema = getSchema(props.schemaDef);
    if (props.dynamicValue && !isComposite(props.dynamicValue)) {
      return 'dynamic';
    }
    if (!schema?.anyOf) {
      return 0;
    }
    // default to the first type if none of the types match
    return Math.max(0, getMatchingDefinitionIndex(schema.anyOf, props.value));
  }, [props.schemaDef, typeSelection, props.value, props.dynamicValue]);

  // clear non-dynamic manual type selection when dynamic value is set
  useEffect(() => {
    if (props.dynamicValue !== undefined && props.dynamicValue !== null && typeof typeSelection === 'number') {
      setTypeSelection(null);
    }
  }, [selectedOrInferredType, props.dynamicValue]);

  // schema definition of the selected type (if anyOf), otherwise props.schemaDef
  const selectedSchemaDef = useMemo(() => {
    const schema = getSchema(props.schemaDef);
    if (!schema?.anyOf || selectedOrInferredType === 'dynamic') {
      return props.schemaDef;
    }
    return schema.anyOf[selectedOrInferredType];

  }, [props.schemaDef, typeSelection, selectedOrInferredType]);

  const theme = useTheme();
  const hasValue = props.value !== undefined || props.dynamicValue !== undefined;

  const [inputFieldResetTrigger, setInputFieldResetTrigger] = React.useState(false);
  const reloadInputField = useCallback(() => {
    setInputFieldResetTrigger(v => !v);
  }, []);

  // any of these changes will trigger the reset of the input field
  // using a string value to prevent unnecessary re-renders
  const compositeInputFieldResetTrigger = useMemo(() => JSON.stringify({
    id: props.uniqueId,
    editorTrigger: props.resetTrigger,
    inputFieldResetTrigger,
  }), [props.uniqueId, props.resetTrigger, inputFieldResetTrigger]);

  const valueInputField = useMemo(() => {
    // show the native input field if the editor is static only, or static type is explicitly selected
    if (props.dynamicDisabled || typeof typeSelection === 'number') {
      return <StaticParamInputField
        schemaDef={selectedSchemaDef}
        value={props.value}
        onChange={value => props.onChange(value, undefined)}
      />
    }
    return <LexicalParamInputField
      schemaDef={props.schemaDef}
      initialValue={props.value}
      initialDynamicValue={props.dynamicValue}
      onChange={props.onChange}
      resetTrigger={compositeInputFieldResetTrigger}
      debug={props.dev}
    />
  }, [
    props.onChange,
    props.uniqueId,
    props.value,
    props.dynamicValue,
    props.dynamicDisabled,
    props.schemaDef,
    props.dev,
    inputFieldResetTrigger,
    typeSelection,
    selectedSchemaDef,
  ]);

  const editorBody = useMemo(() => {
    // always show the dynamic (lexical) input field when "dynamic" type is explicitly selected
    // This has no effect for non complex fields, but for object or array fields this will show the text input
    // instead of the the nested fields/array input field
    if (typeSelection === 'dynamic') {
      return valueInputField;
    }

    const schema = getSchema(selectedSchemaDef);
    // show the native input field when there's no type (i.e. schemaDef === true, or invalid schema)
    if (!schema?.type) {
      return valueInputField;
    }
    // show nothing for null type
    if (schema.type === 'null') {
      return <></>;
    }
    switch (schema.type) {
      case 'object':
        return <>
          {Object.entries(schema.properties || {})
            .sort(([k1], [k2]) => {
              const required1 = isPropertyRequired(schema, k1);
              const required2 = isPropertyRequired(schema, k2);
              if (required1 && !required2) return -1;
              else if (required2 && !required1) return 1;
              return 0;
            })
            .map(([k, subSchemaDef], idx) => {
              if (props.transformFieldSchemaDef) {
                subSchemaDef = props.transformFieldSchemaDef(subSchemaDef);
              }
              return <ParamEditorInternal
                key={idx}
                {...props}
                // need to override props from parent
                hideRootHeader={false}
                path={[...props.path, { type: 'property', value: k }]}
                schemaDef={subSchemaDef}
                value={getValue(props.value, [{ type: 'property', value: k }])}
                dynamicValue={getDynamicValue(props.dynamicValue, [{ type: 'property', value: k }])}
                onChange={(value, dynamicValue) => {
                  props.onChange(getUpdatedValue(
                    props.value, value, [{ type: 'property', value: k }]),
                    getUpdatedDynamicValue(props.dynamicValue, dynamicValue, [{ type: 'property', value: k }])
                  );
                }}
                optional={!schema.required?.includes(k) || getSchema(subSchemaDef)?.default !== undefined}
              />
            })}
          { /* Show additional properties input field if additionalProperties is defined */}
          {schema.additionalProperties && <ArrayInputField
            value={Object.entries(props.value || {}).filter(([k]) => !schema.properties?.[k])}
            onChange={(value) => {
              const properties = Object.fromEntries(Object.entries(props.value || {}).filter(([k]) => schema.properties?.[k]));
              const additionalProperties = value ? value.reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {}) : value;
              props.onChange({ ...properties, ...additionalProperties }, undefined);
            }}
            defaultItemValue={(idx) => {
              let val = getInitialValue(schema.additionalProperties);
              // we need to set value other than undefined to avoid the value being cleared
              if (val === undefined) {
                val = null;
              }
              // we need to set the key to an initial value that's not already present
              const additionalPropertKeys = Object.keys(props.value || {}).filter(k => !schema.properties?.[k]);
              let keyIdx = 0;
              while (additionalPropertKeys.includes(`property${keyIdx}`)) {
                keyIdx++;
              }
              return [`property${keyIdx}`, val] as [string, any];
            }}
            renderItemEditor={(itemValue, onChange, index) => {
              const defaultKey = `property${index}`;
              const key = itemValue?.[0] || defaultKey;
              return <Stack spacing={2}>
                <TextField
                  variant='outlined'
                  label='Key'
                  value={key}
                  onChange={e => onChange([e.target.value || defaultKey, itemValue?.[1]])}
                />
                <ParamEditorInternal
                  {...props}
                  // need to override props from parent
                  path={[...props.path, { type: 'property', value: key }]}
                  schemaDef={props.transformFieldSchemaDef?.(schema.additionalProperties) || schema.additionalProperties}
                  value={itemValue?.[1]}
                  dynamicValue={undefined}
                  onChange={(value) => onChange([key, value])}
                  optional={false}
                  indentationOffset={0 - props.path.filter(seg => seg.type === 'property').length}
                  hideRootHeader
                />
              </Stack>
            }}
          />}
        </>
      case 'array':
        return props.dynamicDisabled
          ? <ArrayInputField
            value={props.value}
            onChange={value => props.onChange(value, undefined)}
            defaultItemValue={() => {
              let itemSchemaDef = Array.isArray(schema.items) ? schema.items[0] : schema.items;
              if (props.transformFieldSchemaDef) {
                itemSchemaDef = props.transformFieldSchemaDef(itemSchemaDef);
              }
              return getInitialValue(itemSchemaDef);
            }}
            renderItemEditor={(itemValue, onChange, index) => {
              let itemSchemaDef = Array.isArray(schema.items) ? schema.items[0] : schema.items;
              if (props.transformFieldSchemaDef) {
                itemSchemaDef = props.transformFieldSchemaDef(itemSchemaDef);
              }
              return <ParamEditorInternal
                {...props}
                // need to override props from parent
                path={[...props.path, { type: 'arrayIndex', value: index }]}
                schemaDef={itemSchemaDef}
                value={itemValue}
                dynamicValue={undefined}
                onChange={onChange}
                optional={false}
                indentationOffset={0 - props.path.filter(seg => seg.type === 'property').length}
                hideRootHeader
              />
            }}
          />
          : <ArrayInputField<MixedArrayItem<any>>
            value={toMixedArray(props.value, props.dynamicValue)}
            onChange={mixedArray => {
              const { value, dynamicValue } = fromMixedArray(mixedArray);
              props.onChange(value, dynamicValue);
            }}
            defaultItemValue={() => {
              let itemSchemaDef = Array.isArray(schema.items) ? schema.items[0] : schema.items;
              if (props.transformFieldSchemaDef) {
                itemSchemaDef = props.transformFieldSchemaDef(itemSchemaDef);
              }
              return { value: getInitialValue(itemSchemaDef), dynamicValue: undefined };
            }}
            renderItemEditor={(mixedArrayItem, onChange, index) => {
              let itemSchemaDef = Array.isArray(schema.items) ? schema.items[0] : schema.items;
              if (props.transformFieldSchemaDef) {
                itemSchemaDef = props.transformFieldSchemaDef(itemSchemaDef);
              }
              return <ParamEditorInternal
                {...props}
                // need to override props from parent
                path={[...props.path, { type: 'arrayIndex', value: index }]}
                schemaDef={itemSchemaDef}
                value={mixedArrayItem.value}
                dynamicValue={mixedArrayItem.dynamicValue}
                onChange={(v, dv) => onChange({ value: v, dynamicValue: dv })}
                optional={false}
                indentationOffset={0 - props.path.filter(seg => seg.type === 'property').length}
                hideRootHeader
              />
            }}
          />
      default:
        return valueInputField;
    }
  }, [selectedSchemaDef, valueInputField, typeSelection]);

  const shouldShowInputElems = !props.optional || hasValue || typeSelection !== null;

  return <Stack>
    {/* header */}
    {!props.hideRootHeader && <Stack>
      <Stack direction='row' spacing={1} display='flex' alignItems='center'>
        <Typography variant='subtitle1'><b>
          {Array.from(
            { length: Math.max(0, props.path.filter(seg => seg.type === 'property').length - 1 + (props.indentationOffset || 0)) },
            (_, i) => <SubdirectoryArrowRightIcon key={i} fontSize='inherit' sx={{ color: theme.palette.text.secondary }} />
          )}
          {props.path.filter(seg => seg.type === 'property').pop()?.value}{props.optional ? '' : '*'}
        </b></Typography>
        <Typography variant='body2' sx={{ color: theme.palette.text.secondary, pr: 1, fontWeight: props.optional ? undefined : 'bold' }}>
          {props.optional ? 'optional' : 'required'}
        </Typography>


        <Stack direction='row' flexGrow={1} display='flex' justifyContent='flex-end'>
          {/* Show plus/minus button to add/remove value when the field is optional */}
          {props.optional &&
            (hasValue || typeSelection !== null
              ? <IconButton color='error' onClick={() => {
                setTypeSelection(null);
                props.onChange(undefined, undefined);
                reloadInputField();
              }}>
                <RemoveRoundedIcon />
              </IconButton>
              : <IconButton color='success' onClick={() => {
                // we need to initialize the value for the first non-null type
                // we cannot directly use the selected schema definition or the default value here
                // because when null type is in anyOf and the default is also null, selectedSchemaDef will point to the null type
                // alternatively we could just set the type selection to have the subfield input body to show,
                // but that's not prefered because explictly setting type selection has other implications
                const schema = getSchema(props.schemaDef);
                const firstNonNullSchemaDef = schema?.anyOf?.find(def => getSchema(def)?.type !== 'null');
                const initVal = getInitialValue(firstNonNullSchemaDef || selectedSchemaDef);
                props.onChange(initVal, undefined);
                reloadInputField();
              }}>
                <AddRoundedIcon />
              </IconButton>
            )
          }
          {/* avoid showing collapse button when the field is optional and has no value */}
          {shouldShowInputElems && isComplexField(selectedSchemaDef) && <IconButton onClick={() => setCollapsed(c => !c)}>
            {collapsed
              ? <ExpandMoreRoundedIcon color='primary' />
              : <ExpandLessRoundedIcon color='primary' />
            }
          </IconButton>}
        </Stack>
      </Stack>

      {/* Hide type annotation/selection and editor body when field is optional, has no value, and does not has a selected type */}
      <Collapse in={shouldShowInputElems}>
        <TypeAnnotation
          dynamicDisabled={props.dynamicDisabled}
          schemaDef={props.schemaDef}
          selectedOrInferredType={selectedOrInferredType}
          onSelectType={(selection) => {
            if (selection !== selectedOrInferredType) {
              if (selection === 'dynamic') {
                props.onChange(undefined, props.dynamicValue);
                reloadInputField();
              }
              else if (selection !== null) {
                // TODO attempt to convert value to the selected type
                props.onChange(undefined, undefined);
                reloadInputField();
              }
            }
            setTypeSelection(selection);
          }}
        />
      </Collapse>
    </Stack>}

    {/* debug ux */}
    {props.dev && <Typography variant='body2' onClick={() => setShowDev(d => !d)} sx={{ cursor: 'pointer', color: theme.palette.text.disabled }}>
      {showDev ? 'Hide' : 'Show'} Developer Info
    </Typography>}
    {showDev && props.dev && <>
      <Typography variant='subtitle2'>Value</Typography>
      <JsonView src={props.value} />
      <Typography variant='subtitle2'>Dynamic Value</Typography>
      {props.dynamicValue
        ? <JsonView src={props.dynamicValue} />
        : <Typography variant='body2'>No dynamic value</Typography>
      }
      <Typography variant='subtitle2'>Path</Typography>
      <JsonView src={props.path} />
      <Typography variant='subtitle2'>Schema</Typography>
      <JsonView src={props.schemaDef} />
      <Typography variant='subtitle2'>Type Selection</Typography>
      <JsonView src={typeSelection} />
    </>}

    {/* editor body, including nested fields */}
    <Collapse in={shouldShowInputElems && !collapsed} mountOnEnter>
      <Stack spacing={2}>
        {props.customEditorRenderer
          ? props.customEditorRenderer({
            ...props,
            typeSelection,
            schemaDef: selectedSchemaDef,
            defaultEditor: editorBody,
          })
          : editorBody}
        {/* Disable collapse when header is hidden, because expand button is shown in the header */}
        {!props.hideRootHeader && isComplexField(selectedSchemaDef) && <Button onClick={() => setCollapsed(true)}>
          <ExpandLessRoundedIcon fontSize='small' />
          <Typography variant='body2' sx={{ textTransform: 'none' }}>
            Collapse
          </Typography>
        </Button>}
        <Divider variant='middle' />
      </Stack>
    </Collapse>
  </Stack>
}


type SpecializedJSONSchema7 = JSONSchema7 & { inputType: FlowParamInputType };

function isSpecializedSchema(schema: JSONSchema7): schema is SpecializedJSONSchema7 {
  return (schema as SpecializedJSONSchema7).inputType !== undefined;
}

const metaSchema = {
  type: "object",
  title: "JsonSchema",
  properties: {
    type: {
      type: "string",
      enum: ["object", "array", "string", "number", "integer", "boolean", "null"],
    },
    properties: {
      type: "object",
      additionalProperties: {
        inputType: FlowParamInputType.JsonSchema,
      }
    },
    required: {
      type: "array",
      items: {
        type: "string",
      }
    },
    anyOf: {
      type: "array",
      items: {
        inputType: FlowParamInputType.JsonSchema,
      }
    },
    title: { type: "string" },
    description: { type: "string" },
    enum: {
      type: "array",
      items: true,
      minItems: 1,
      uniqueItems: true,
    },
    items: {
      inputType: FlowParamInputType.JsonSchema,
    },
  },
  // it is important to keep the inputType field in the schema so customEditorRenderer can detect this
  inputType: FlowParamInputType.JsonSchema,
};

function transformFieldSchemaDef(schemaDef: JSONSchema7Definition): JSONSchema7Definition {
  const schema = getSchema(schemaDef);
  if (!schema) {
    return schemaDef;
  }

  if (isSpecializedSchema(schema)) {
    switch (schema.inputType) {
      case FlowParamInputType.JsonSchema:
        return metaSchema as unknown as JSONSchema7Definition;
      default:
        return schemaDef;
    }
  }

  return schemaDef;
}

function renderCustomizedEditor(props: ParamEditorCommonProps & { defaultEditor: React.ReactNode, typeSelection: number | "dynamic" | null }): React.ReactNode {
  const schema = getSchema(props.schemaDef);
  if (!schema) {
    return props.defaultEditor;
  }

  if (isSpecializedSchema(schema)) {
    switch (schema.inputType) {

      // add a special dialog to reference type in the graph when rendering JSONSchema field
      case FlowParamInputType.JsonSchema:
        return <>
          <JsonSchemaRefDialog onChange={
            (schema: JSONSchema7) => {
              props.onChange(schema, undefined);
            }
          } />
          {props.defaultEditor}
        </>
      case FlowParamInputType.KnowledgeBaseId:
        if (props.typeSelection === 'dynamic') {
          return props.defaultEditor;
        }
        return <KnowledgeBaseSelect
          value={props.value}
          onChange={(value) => {
            props.onChange(value, undefined);
          }}
        />
    }
  }
  return props.defaultEditor;
}


export function ParamEditor(props: ParamEditorCommonProps): React.ReactElement {

  const dev = useUserAndWorkspaceStore(state => state.isDevelopmentMode());

  return <ParamEditorInternal
    uniqueId={props.uniqueId}
    path={[]}
    schemaDef={props.schemaDef}
    value={props.value}
    dynamicValue={props.dynamicValue}
    onChange={props.onChange}
    dynamicDisabled={props.dynamicDisabled}
    dev={dev}
    resetTrigger={props.resetTrigger}
    transformFieldSchemaDef={transformFieldSchemaDef}
    customEditorRenderer={renderCustomizedEditor}
    hideRootHeader
  />
}

export default ParamEditor;
