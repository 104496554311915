import React, { useState } from 'react';
import { Select, MenuItem, Stack, Button, Dialog, Tooltip, Typography, Box } from '@mui/material';
import { useUserAndWorkspaceStore } from '../../hooks/UserAndWorkspaceStore';
import { useShallow } from 'zustand/react/shallow';
import { KnowledgeBaseEditor } from '../KnowledgeBaseEditor';


function CreateKnowledgeBaseButton({ onClick }) {

  return <>
    <Button
      variant="contained"
      color="success"
      sx={{ textTransform: 'none' }}
      onClick={onClick}
    >
      Create knowledge base
    </Button>
  </>
}


export default function KnowledgeBaseSelect(props: {
  name?: string,
  // important: do not include __typename
  value?: string,
  onChange: (knowledgeBaseId: string) => void,
}): React.ReactElement {
  const knowledgeBases = useUserAndWorkspaceStore(useShallow(state => state.assets.knowledgeBases));
  const knowledgeBaseId = props.value in knowledgeBases ? props.value : "";
  const knowledgeBase = knowledgeBaseId ? knowledgeBases[knowledgeBaseId] : undefined;

  const [dialogOpen, setDialogOpen] = useState(false);

  if (Object.keys(knowledgeBases).length === 0) {
    return <Box>
      <CreateKnowledgeBaseButton onClick={() => setDialogOpen(true)} />
    </Box>
  }

  return (
    <Stack spacing={1} width='100%'>
      <Select
        value={knowledgeBaseId}
        onChange={e => {
          const val = e.target.value;
          if (val) {
            props.onChange(e.target.value);
          }
        }}
      >
        {Object.entries(knowledgeBases).map(([name, kb]) => (
          <MenuItem key={name} value={kb.id}>
            <Tooltip title={kb.description}><Typography>{kb.name}</Typography></Tooltip>
          </MenuItem>
        ))}
        <MenuItem value="">
          <CreateKnowledgeBaseButton onClick={(e: MouseEvent) => {
            e.stopPropagation();
            setDialogOpen(true)
          }} />
        </MenuItem>
      </Select>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth='lg'>
        <KnowledgeBaseEditor
          knowledgeBase={knowledgeBase}
          onSubmit={(newKnowledgeBaseId) => {
            props.onChange(newKnowledgeBaseId);
            setDialogOpen(false);
          }}
        />
      </Dialog>
    </Stack>
  );
}
