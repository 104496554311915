import React, { useContext, useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { AppContext } from '../../../../contexts/AppContext';
import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme, Dialog, Button } from '@mui/material';
import { AssetsContext } from '../../../../contexts/AssetsContext';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DELETE_KNOWLEDGE_BASE, DELETE_FILE } from '../../../../graphql/mutation';
import { useUserAndWorkspaceStore } from '../../../../hooks/UserAndWorkspaceStore';
import { useShallow } from 'zustand/react/shallow';
import { TKnowledgeBase } from '../../../../../generated/gql/graphql';
import { KnowledgeBaseEditor } from '../../../../components/KnowledgeBaseEditor';
import { UploaderV2 } from '../../../../components/input/uploaderV2';
import { DeleteButton } from './DeleteButton';


function KnowledgeBaseDeleteButton(props: {
  knowledgeBase: TKnowledgeBase,
}): React.ReactElement {
  const { setError, setSuccessMessage } = useContext(AppContext);
  const loadWorkspaceAssets = useUserAndWorkspaceStore(state => state.loadWorkspaceAssets);
  const [deleteKnowledgeBase] = useMutation(DELETE_KNOWLEDGE_BASE);
  const client = useApolloClient();

  const handleDelete = async () => {
    await deleteKnowledgeBase({
      variables: { id: props.knowledgeBase.id }
    })
      .then(() => {
        setSuccessMessage(`Knowledge base ${props.knowledgeBase.name} deleted.`);
        loadWorkspaceAssets(client);
      })
      .catch(setError);
  };

  return <DeleteButton
    onDelete={handleDelete}
    size="small"
  />;
}

function FileDeleteButton(props: {
  fileId: string,
  fileName: string,
}): React.ReactElement {
  const { setError, setSuccessMessage } = useContext(AppContext);
  const workspaceId = useUserAndWorkspaceStore(state => state.workspaceId);
  const loadWorkspaceAssets = useUserAndWorkspaceStore(state => state.loadWorkspaceAssets);
  const client = useApolloClient();
  const [deleteFileMutation, { loading }] = useMutation(DELETE_FILE);

  const handleDelete = async () => {
    try {
      await deleteFileMutation({
        variables: {
          siteId: workspaceId,
          fileId: props.fileId
        }
      });
      setSuccessMessage(`File "${props.fileName}" deleted successfully.`);
      // Refresh the files list
      loadWorkspaceAssets(client);
    } catch (error) {
      setError(error);
    }
  };

  return <DeleteButton
    onDelete={handleDelete}
    size="small"
    disabled={loading}
  />;
}

export function ResourcesPanel(): React.ReactElement {
  const { setError, setSuccessMessage } = useContext(AppContext);
  const {
    documents,
  } = useContext(AssetsContext);
  const workspaceId = useUserAndWorkspaceStore(state => state.workspaceId);
  const files = useUserAndWorkspaceStore(useShallow(state => state.assets.files));
  const knowledgeBases = useUserAndWorkspaceStore(useShallow(state => state.assets.knowledgeBases));
  const loadWorkspaceAssets = useUserAndWorkspaceStore(state => state.loadWorkspaceAssets);
  const theme = useTheme();
  const [knowledgeBaseEditorDialogOpen, setKnowledgeBaseEditorDialogOpen] = useState(false);
  const [selectedKnowledgeBaseId, setSelectedKnowledgeBaseId] = useState<string | null>(null);
  const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);
  const client = useApolloClient();

  return <Stack spacing={2}>
    <Typography variant='subtitle1'><b>Knowledge Bases</b></Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Object.values(knowledgeBases).map((kb, idx) => (
            <TableRow
              key={idx}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
                display: 'flex',
              }}
            >
              <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <Typography>{kb.name}</Typography>
                  {kb.description && <Typography variant='body2' color={theme.palette.text.secondary}>
                    {kb.description}
                  </Typography>}
                </Stack>
              </TableCell>
              <TableCell sx={{ gap: 1 }}>
                <IconButton color='primary' onClick={() => {
                  setSelectedKnowledgeBaseId(kb.id);
                  setKnowledgeBaseEditorDialogOpen(true);
                }}>
                  <OpenInNewRoundedIcon fontSize='small' />
                </IconButton>
                <KnowledgeBaseDeleteButton knowledgeBase={kb} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '& .MuiTableCell-root': { display: 'flex', alignItems: 'center', justifyContent: 'center' },
              display: 'flex',
            }}
          >
            <TableCell colSpan={2} align="center" sx={{ justifyContent: 'center' }}>
              <Button
                color='success'
                startIcon={<AddRoundedIcon />}
                onClick={() => {
                  setSelectedKnowledgeBaseId(null);
                  setKnowledgeBaseEditorDialogOpen(true);
                }}
              >
                Add Knowledge Base
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <Typography variant='subtitle1'><b>Documents</b></Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Object.values(files).map((file, idx) => (
            <TableRow
              key={idx}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
                display: 'flex',
              }}
            >
              <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                <Typography>{file.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <FileDeleteButton fileId={file.id} fileName={file.name} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '& .MuiTableCell-root': { display: 'flex', alignItems: 'center', justifyContent: 'center' },
              display: 'flex',
            }}
          >
            <TableCell colSpan={2} align="center" sx={{ justifyContent: 'center' }}>
              <Button
                color='success'
                startIcon={<AddRoundedIcon />}
                onClick={() => setAddFileDialogOpen(true)}
              >
                Add File
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    {/* Add Knowledge Base Dialog */}
    <Dialog open={knowledgeBaseEditorDialogOpen} onClose={() => {
      setSelectedKnowledgeBaseId(null);
      setKnowledgeBaseEditorDialogOpen(false);
    }} fullWidth maxWidth='lg'>
      <KnowledgeBaseEditor
        knowledgeBase={selectedKnowledgeBaseId ? knowledgeBases[selectedKnowledgeBaseId] : undefined}
        onSubmit={(newKnowledgeBaseId) => {
          setSuccessMessage("Knowledge base created successfully");
          loadWorkspaceAssets(client);
          setKnowledgeBaseEditorDialogOpen(false);
        }}
      />
    </Dialog>

    {/* Add File Dialog */}
    <Dialog open={addFileDialogOpen} onClose={() => setAddFileDialogOpen(false)} fullWidth maxWidth='md'>
      <UploaderV2
        siteId={workspaceId}
        onComplete={(fileIdByNames) => {
          setSuccessMessage("File(s) uploaded successfully");
          loadWorkspaceAssets(client);
          setAddFileDialogOpen(false);
        }}
        onCanceled={() => setAddFileDialogOpen(false)}
      />
    </Dialog>
  </Stack>
}

export default ResourcesPanel;
