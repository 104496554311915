import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Card, Stack, TextField, Typography, useTheme } from '@mui/material';
import { XYPosition } from 'reactflow';
import { PluginCategory, PluginConstructType, PluginType, TFlowPluginType, TPluginConstructInfo, TPluginInfo } from '../../../../generated/gql/graphql';
import { toFlowNode } from './FlowNode';
import { GraphNode } from '../../../types/GraphNode';
import { CompositeDynamicValue } from '../../../types/DynamicValueTypes';
import { AppContext } from '../../../contexts/AppContext';
import { MainPluginIcon, PluginIcon, PluginSecondaryCategories } from './FlowNodeName';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InputAdornment from '@mui/material/InputAdornment';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';


export function PluginCard(props: {
  type: PluginType | PluginConstructType,
  info?: TPluginInfo | TPluginConstructInfo,
  onClick?: () => void,
}): React.ReactElement {
  const [elevation, setElevation] = useState(0);

  return <Card
    elevation={elevation}
    onMouseOver={() => setElevation(5)}
    onMouseLeave={() => setElevation(0)}
    sx={{
      cursor: 'pointer',
    }}
    onClick={props.onClick}
  >
    <Stack p={2} direction='row' spacing={3}>
      {props.info
        ? <>
          <Typography variant='h4'><MainPluginIcon info={props.info} /></Typography>
          <Stack spacing={1}>
            <Typography variant='subtitle1'><b>{props.info.name}</b></Typography>
            <PluginSecondaryCategories info={props.info} />
            <Typography variant='body1'>{props.info.description}</Typography>
          </Stack></>
        : <Typography variant='h4'>{props.type}</Typography>
      }
    </Stack>
  </Card>
}


const createNodeData = (pluginType: TFlowPluginType) => ({
  pluginType,
  params: {},
  dynamicParams: { props: {} } as CompositeDynamicValue,
});

type NodeCreationProps = {
  position?: XYPosition,
  onAddNode?: (node: GraphNode) => void,
}

export function useNodeCreation(props: NodeCreationProps) {
  const addNode = useEditorStore(state => state.actions.graph.addNode);
  const position = useMemo(() => props.position || { x: 0, y: 0 }, [props.position]);

  const createPluginNode = useCallback((pluginType: PluginType) => {
    const nodeData = createNodeData({ static: pluginType });
    addNode(nodeData, position, props.onAddNode);
  }, [position, props.onAddNode, addNode]);

  const createConstructedPluginNode = useCallback((pluginConstructorType: PluginConstructType) => {
    const nodeData = createNodeData({
      __typename: 'TFlowPluginType',
      dynamic: {
        constructType: pluginConstructorType,
        param: {}
      }
    });
    addNode(nodeData, position, props.onAddNode);
  }, [position, props.onAddNode, addNode]);

  return { createPluginNode, createConstructedPluginNode }
}

export function NodeCreationMenu(props: NodeCreationProps): React.ReactElement {
  const { createPluginNode: onSelectPlugin, createConstructedPluginNode: onSelectPluginConstructor } = useNodeCreation(props);

  return <PluginSelectionMenu
    onSelectPlugin={onSelectPlugin}
    onSelectPluginConstructor={onSelectPluginConstructor}
  />
}

// TODO exclude search/filter bar from scrolling
export function PluginSelectionMenu(props: {
  onSelectPlugin?: (pluginType: PluginType) => void,
  onSelectPluginConstructor?: (pluginConstructorType: PluginConstructType) => void,
}) {
  const theme = useTheme();
  const user = useUserAndWorkspaceStore(useShallow(state => state.user));

  const [
    plugins,
    pluginConstructs,
  ] = useEditorStore(useShallow(state => [
    Object.values(state.types.static),
    Object.values(state.types.construct),
  ]));

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState<(PluginCategory | 'User Input' | 'Display' | 'AI Powered')[]>([]);

  return <Stack spacing={2} p={2}>
    <TextField
      value={query}
      onChange={e => setQuery(e.target.value)}
      placeholder='Enter to search...'
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        )
      }}
    />
    <Stack direction='row' spacing={1} display='flex' flexWrap='wrap' alignItems='center'>
      <Typography variant='body2' color={theme.palette.text.secondary}>Filter by function type: </Typography>
      {Object.values(PluginCategory).map((c, idx) =>
        <Typography key={idx} variant='h6' sx={{ cursor: 'pointer' }}
          onMouseDown={() => setFilters(f => f.includes(c)
            ? f.filter(item => item !== c)
            : [...f, c]
          )}
        >
          <PluginIcon category={c} disabled={!filters.includes(c)} />
        </Typography>
      )}
    </Stack>
    {plugins
      .filter(p => p.pluginType.static && (user?.isDeveloper || !p.inDevelopment))
      .filter(p => !filters.length || p.categories.some(c => filters.includes(c)))
      .filter(p => !query
        || p.pluginType.static!.toLowerCase().includes(query.toLowerCase())
        || p.name.toLowerCase().includes(query.toLowerCase())
        || p.description.toLowerCase().includes(query.toLowerCase())
      )
      .map(
        (p, idx) => <PluginCard type={p.pluginType.static!} key={idx} info={p}
          onClick={() => props.onSelectPlugin?.(p.pluginType.static!)}
        />
      )
    }
    {pluginConstructs
      .filter(p => user?.isDeveloper || !p.inDevelopment)
      .filter(p => !filters.length || p.categories.some(c => filters.includes(c)))
      .filter(p => !query
        || p.constructType.toLowerCase().includes(query.toLowerCase())
        || p.name.toLowerCase().includes(query.toLowerCase())
        || p.description.toLowerCase().includes(query.toLowerCase())
      )
      .map(
        (p, idx) => <PluginCard type={p.constructType} key={idx} info={p}
          onClick={() => props.onSelectPluginConstructor?.(p.constructType)}
        />
      )}
  </Stack>
}
