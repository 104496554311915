import { Button, SxProps } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { AppContext } from '../../contexts/AppContext';
import { FetchResult } from '@apollo/client';
import { Pulse } from '../animations';

export default function MutationButton<TData>(props: {
  children: React.ReactNode,
  mutation: () => Promise<FetchResult<TData>>,
  onCompletion?: (data: TData) => void,
  completionMessage?: string,
  sx?: SxProps,
}): React.ReactElement {
  const [inProgress, setInProgress] = useState(false);
  const { setError, setSuccessMessage } = useContext(AppContext);

  const sx = props.sx || {
    borderRadius: 5,
    fontSize: 32,
    p: 2,
    backgroundColor: inProgress ? undefined : 'secondary.main',
    color: inProgress ? undefined : 'secondary.contrastText',
    '&:hover': inProgress ? undefined : {
      backgroundColor: 'secondary.dark',
    }
  };

  const onClick = useCallback(() => {
    setInProgress(true);
    props.mutation()
      .then(res => {
        if (res.errors) setError(res.errors[0]);
        else {
          if (!res.data) setError("Something went wrong");
          else {
            const completionMessage = props.completionMessage || 'Operation successful.';
            setSuccessMessage(completionMessage);
            props.onCompletion?.(res.data);
          }
        }
      })
      .catch(setError)
      .finally(() => setInProgress(false));
  }, [props.mutation, props.completionMessage, props.onCompletion]);

  return inProgress
    ? <Pulse><Button disabled sx={sx}>{props.children}</Button></Pulse>
    : <Button sx={sx} onClick={onClick}>{props.children}</Button>;
}
