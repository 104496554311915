export enum SettingsMenuOption {
  AppSettings = 'App settings',
  AiLearning = 'AI learning',
  Resources = "Resources",
  LinkedAccounts = 'Linked accounts',
  LaunchChannels = 'Launch channels',
  RunHistory = 'Run history',
  AppAppearance = 'App appearance',
  AppTemplate = "App Template",
}
