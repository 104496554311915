import { useMutation } from "@apollo/client";
import { useTheme, CircularProgress } from "@mui/material";
import { Stack, IconButton, Typography } from "@mui/material";
import React, { useState, useCallback } from "react";
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { LABEL_AI_PLUGIN_RUNS } from "../../graphql/mutation";

export function AiResponseRating(props: {
  label: boolean | null | undefined;
  aiPluginRunLogIds: string[];
  onSave?: (label: boolean) => void;
}): React.ReactElement {
  const [isSavingLabel, setIsSavingLabel] = useState(false);
  const [saveLabel] = useMutation(LABEL_AI_PLUGIN_RUNS);

  const saveLabels = useCallback((label: boolean) => {
    setIsSavingLabel(true);
    saveLabel({
      variables: {
        labels: props.aiPluginRunLogIds.map(id => ({
          id,
          label,
        })),
      }
    }).then(res => {
      if (res.errors) {
        console.error(res.errors);
      }
      else {
        props.onSave?.(label);
        //setAiLabel(props.nodeId, props.nodeVersion, label);
      }
    }).catch(console.error)
      .finally(() => setIsSavingLabel(false));
  }, [props.aiPluginRunLogIds, props.onSave]);

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Evaluate AI Response</Typography>
      {isSavingLabel
        ? <CircularProgress />
        : <Stack direction='row' spacing={2}>
          <IconButton
            color={props.label === true ? 'success' : 'default'}
            onClick={() => props.label !== true && saveLabels(true)}
          ><ThumbUpRoundedIcon /></IconButton>
          <IconButton
            color={props.label === false ? 'error' : 'default'}
            onClick={() => props.label !== false && saveLabels(false)}
          ><ThumbDownRoundedIcon /></IconButton>
        </Stack>}
    </Stack>
  );
}
