import {
  Box, Button, Dialog, Grid, LinearProgress, Popover, Stack, Typography, useTheme,
} from '@mui/material';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'reactflow/dist/style.css';


import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { PluginCard, NodeCreationMenu, useNodeCreation } from './PluginSelectionMenu';

import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow'
import { useGraphPosition } from '../../../hooks/useGraphPosition';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';
import { useApolloClient, useMutation } from '@apollo/client';
import { GraphNode } from '../../../types/GraphNode';
import { XYPosition } from 'reactflow';
import { UPDATE_EDITOR_SETTINGS } from '../../../graphql/mutation';

function AddNodeModal(props: {
  onAddNode?: (node: GraphNode) => void,
  open?: boolean,
  onClose?: () => void,
  position?: XYPosition,
}): React.ReactElement {
  const theme = useTheme();
  return <Dialog
    open={props.open}
    onClose={props.onClose}
    fullWidth
    maxWidth='sm'
    sx={{ overflow: 'hidden' }}
  >
    <Stack height='90vh'>
      <Stack>
        <Stack spacing={2} direction='row' sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          background: theme.palette.secondary.dark,
          color: theme.palette.secondary.contrastText,
        }}><MemoryRoundedIcon fontSize='large' />
          <Typography variant='h5'><b>Choose function</b></Typography>
        </Stack>
      </Stack>
      <Box overflow='auto'>
        <NodeCreationMenu
          onAddNode={n => {
            props.onAddNode?.(n);
            props.onClose?.();
          }}
          position={props.position}
        />
      </Box>
    </Stack>
  </Dialog>
}


export function AddNodeButton(props: {
  popoverAnchorEl: Element,
}): React.ReactElement {
  const [dialogOpenReason, setDialogOpenReason] = useState<'add' | 'shortcut' | null>(null);
  const { center } = useGraphPosition();
  const client = useApolloClient();

  const closeTimer = useRef(null); // Store timer reference for delayed close
  const [
    shortcuts,
    loading,
    loadEditorSettings,
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.getShortcuts(),
    state.loadingStatus.editorSettings,
    state.loadEditorSettings,
  ]))
  const [
    plugins,
    pluginConstructs,
    shortcutsPopoverOpen,
    setShortcutsPopoverOpen,
  ] = useEditorStore(useShallow(state => [
    Object.values(state.types.static),
    Object.values(state.types.construct),
    state.editorView.shortcutsPopoverOpen,
    state.actions.setShortcutsPopoverOpen,
  ]));

  const [updateEditorSettings] = useMutation(UPDATE_EDITOR_SETTINGS);

  const nodeCreationProps = useMemo(() => ({
    position: center, onAddNode: () => setDialogOpenReason(null),
  }), [center])
  const {
    createPluginNode,
    createConstructedPluginNode,
  } = useNodeCreation(nodeCreationProps);

  // Function to open the popover immediately
  const hoverAddNode = useCallback(() => {
    clearTimeout(closeTimer.current); // Cancel any pending close
    setShortcutsPopoverOpen(true);
  }, []);

  // Function to close the popover with a short delay
  const leaveAddNode = useCallback(() => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
    }
    closeTimer.current = setTimeout(() => setShortcutsPopoverOpen(false), 200);
  }, []);

  return <>
    <Button
      variant='contained'
      color='success'
      sx={{
        display: 'flex',
        borderRadius: 20,
        p: 2,
        m: 2,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
        textTransform: 'none',
      }}
      onClick={() => setDialogOpenReason('add')}
      onMouseEnter={hoverAddNode}
      onMouseLeave={leaveAddNode}
    >
      <AddRoundedIcon fontSize='large' />
    </Button>
    <AddNodeModal
      open={Boolean(dialogOpenReason)}
      onClose={() => setDialogOpenReason(null)}
      onAddNode={n => {
        if (dialogOpenReason === 'shortcut') {
          updateEditorSettings({
            variables: {
              editorSettings: {
                shortcuts: [
                  ...shortcuts,
                  {
                    pluginType: n.data.pluginType.static,
                    pluginConstructorType: n.data.pluginType.dynamic?.constructType,
                  },
                ]
              }
            }
          }).then(() => loadEditorSettings(client))
        }
      }}
      position={center}
    />

    <Popover
      open={shortcutsPopoverOpen}
      anchorEl={props.popoverAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      disableAutoFocus
      disableEnforceFocus
      // this to prevent triggering mouseLeave event on the hovered button
      style={{ pointerEvents: 'none' }}
      slotProps={{
        paper: {
          sx: {
            background: 'transparent',
            boxShadow: 'none',
          }
        }
      }}
    >
      {loading
        ? <LinearProgress />
        : <Grid container
          style={{ pointerEvents: 'auto' }}
          onMouseEnter={hoverAddNode}
          onMouseLeave={leaveAddNode}
          p={2}
          spacing={2}
          alignItems='stretch'
        >
          {shortcuts.map((s, idx) =>
            <Grid item key={idx} xs={6} sm={4} md={3} lg={2}>
              {s.pluginType && <PluginCard
                key={idx}
                type={s.pluginType}
                info={plugins.find(p => p.pluginType.static === s.pluginType)}
                onClick={() => {
                  createPluginNode(s.pluginType);
                  setShortcutsPopoverOpen(false);
                }}
              />}
              {s.pluginConstructorType && <PluginCard
                key={idx}
                type={s.pluginConstructorType}
                info={pluginConstructs.find(p => p.constructType === s.pluginConstructorType)}
                onClick={() => {
                  createConstructedPluginNode(s.pluginConstructorType);
                  setShortcutsPopoverOpen(false);
                }}
              />}
            </Grid>
          )}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Button
              variant='outlined'
              color='success'
              onClick={() => setDialogOpenReason('shortcut')}
            >
              <AddRoundedIcon fontSize='large' />
              <Typography>Add Shortcut</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    </Popover>
  </>
}
