import {
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { Variant } from '@mui/material/styles/createTypography';

export type NameChangeMode = "edit" | "display";

export default function NameChange(props: {
  value: string,
  onChange: (v: string) => void,
  children: React.ReactNode,
  mode: NameChangeMode,
  setMode: (m: NameChangeMode) => void,
}): React.ReactElement {
  const [tempValue, setTempValue] = useState(props.value);

  useEffect(() => setTempValue(props.value), [props.value]);

  return props.mode == 'edit'
    ? <Stack width='100%' direction="row" display='flex' alignItems='center' justifyContent='space-between'>
      <TextField
        variant='standard'
        value={tempValue}
        error={tempValue.length == 0}
        onChange={e => setTempValue(e.target.value)}
        sx={{ pr: 4 }}
      />
      <Stack direction='row'>
        <IconButton onClick={() => {
          props.onChange(tempValue);
          props.setMode('display');
        }}>
          <CheckRoundedIcon color='success' fontSize='small' />
        </IconButton>
        <IconButton onClick={() => {
          setTempValue(props.value);
          props.setMode('display');
        }}>
          <CloseRoundedIcon color='error' fontSize='small' />
        </IconButton>
      </Stack>
    </Stack>
    : <>{props.children}</>
}

export function NameChangeV2(props: {
  value: string,
  onChange: (v: string) => void,
  variant?: Variant,
  display?: React.ReactElement,
}): React.ReactElement {
  const theme = useTheme();
  const [mode, setMode] = useState<NameChangeMode>('display');
  const [tempValue, setTempValue] = useState(props.value);
  const textFieldRef = useRef<any>(null);

  useEffect(() => {
    if (mode == 'edit') {
      textFieldRef.current?.focus();
    }
  }, [mode]);

  useEffect(() => setTempValue(props.value), [props.value]);

  const exitEdit = () => {
    setTempValue(props.value);
    setMode('display');
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      props.onChange(tempValue);
      setMode('display');
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      exitEdit();
    }
  };

  return mode == 'edit'
    ? <TextField
      variant='standard'
      value={tempValue}
      error={tempValue.length == 0}
      inputRef={textFieldRef}
      onChange={e => setTempValue(e.target.value)}
      onKeyDown={handleKeyDown}
      onBlur={() => { console.log('bluring'); exitEdit() }}
      sx={{
        pr: 1,
        '& .MuiInputBase-input': theme.typography[props.variant || 'body1'],
      }}
    />
    : <Stack direction='row' spacing={1} onClick={() => setMode('edit')} sx={{
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
    }}>
      {props.display || <Typography variant={props.variant}>{props.value}</Typography>}
      <BorderColorRoundedIcon color='disabled' fontSize='inherit' />
    </Stack>
}
