import { Button, Typography } from '@mui/material';
import React from 'react';

export default function TagList(props: {
  tags?: string[],
}) {
  if (!props.tags || props.tags.length == 0) return <></>;
  return <Typography variant='subtitle1'>
    {props.tags.map(t => <Button key={`tag-${t}`} sx={{ textTransform: 'none' }}>#{t}</Button>)}
  </Typography>
}
