import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { FlowNodeName } from '../../../features/Pixie/Editor/FlowNodeName';

interface NodeSelectProps {
  selectedNodeId: string | null | undefined;
  onChange?: (nodeId: string) => void;
  filterOptions?: (nodeId: string) => boolean;
}

export function NodeSelect({ selectedNodeId, onChange, filterOptions }: NodeSelectProps): React.ReactElement {
  const nodeIds = useEditorStore(useShallow(state => state.graph.nodes.map(n => n.id)));

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange?.(event.target.value);
  };

  return (
    <Select
      value={selectedNodeId || ''}
      onChange={handleChange}
    >
      {nodeIds.filter(id => !filterOptions || filterOptions(id)).map(id => (
        <MenuItem key={id} value={id}>
          <FlowNodeName nodeId={id} variant='description' />
        </MenuItem>
      ))}
    </Select>
  );
}
