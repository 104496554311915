import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { AppContext } from '../../../../contexts/AppContext';
import { CircularProgress, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useTheme } from '@mui/material';


import { AssetsContext } from '../../../../contexts/AssetsContext';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { DocumentCta } from '../../../../components/pixie/param-inputs/KnowledgeBaseSelect';
import { ADD_KNOWLEDGE_LINK, ADD_KNOWLEDGE_SITEMAP, DELETE_DOCUMENT, DELETE_KNOWLEDGE_LINK, DELETE_KNOWLEDGE_SITEMAP } from '../../../../graphql/mutation';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useUserAndWorkspaceStore } from '../../../../hooks/UserAndWorkspaceStore';


function DocumentDeleteButton(props: {
  documentId: string,
}): React.ReactElement {
  const { setError } = useContext(AppContext);
  const { refetchDocuments } = useContext(AssetsContext);
  const [deleteDocument] = useMutation(DELETE_DOCUMENT);
  const [inProgress, setInProgress] = useState(false);

  return <IconButton color='error' onClick={() => {
    setInProgress(true);
    deleteDocument({ variables: { documentId: props.documentId } })
      .then(() => refetchDocuments())
      .catch(setError)
      .finally(() => setInProgress(false));
  }}>
    {inProgress
      ? <CircularProgress size='1rem' />
      : <DeleteOutlineRoundedIcon fontSize='small' />
    }
  </IconButton>

}


function KnowledgeDeleteButton(props: {
  knowledgeBaseId: string,
  variant: "link" | "sitemap",
}): React.ReactElement {
  const { setError } = useContext(AppContext);
  const { refetchKnowledgeLinks, refetchKnowledgeSitemaps } = useContext(AssetsContext);
  const [deleteLink] = useMutation(DELETE_KNOWLEDGE_LINK);
  const [deleteSitemap] = useMutation(DELETE_KNOWLEDGE_SITEMAP);
  const [inProgress, setInProgress] = useState(false);

  const deleteKnowledgeBase = props.variant === 'link'
    ? () => deleteLink({ variables: { linkId: props.knowledgeBaseId } }).then(() => refetchKnowledgeLinks())
    : () => deleteSitemap({ variables: { sitemapId: props.knowledgeBaseId } }).then(() => refetchKnowledgeSitemaps());

  return <IconButton color='error' onClick={() => {
    setInProgress(true);
    deleteKnowledgeBase()
      .catch(setError)
      .finally(() => setInProgress(false));
  }}>
    {inProgress
      ? <CircularProgress size='small' />
      : <DeleteOutlineRoundedIcon fontSize='small' />
    }
  </IconButton>

}

export function KnowledgeBasesPanel(): React.ReactElement {
  const { setError } = useContext(AppContext);
  const {
    documents,
    knowledgeLinks,
    refetchKnowledgeLinks,
    knowledgeSitemaps,
    refetchKnowledgeSitemaps,
  } = useContext(AssetsContext);
  const [link, setLink] = useState('');
  const [sitemap, setSitemap] = useState('');
  const [addKnowledgeLink] = useMutation(ADD_KNOWLEDGE_LINK);
  const [addKnowledgeSitemap] = useMutation(ADD_KNOWLEDGE_SITEMAP);
  const theme = useTheme();
  const siteId = useUserAndWorkspaceStore(state => state.workspaceId);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const saveKnowledgeLink = () => {
    setSaveInProgress(true);
    addKnowledgeLink({ variables: { siteId, url: link } })
      .then(() => {
        refetchKnowledgeLinks();
        setLink('');
      })
      .catch(setError)
      .finally(() => setSaveInProgress(false));
  }

  const saveKnowledgeSitemap = () => {
    setSaveInProgress(true);
    addKnowledgeSitemap({ variables: { siteId, url: sitemap } })
      .then(() => {
        refetchKnowledgeLinks();
        refetchKnowledgeSitemaps();
        setSitemap('');
      })
      .catch(setError)
      .finally(() => setSaveInProgress(false));
  }

  return <Stack spacing={2}>
    <Typography variant='subtitle1'><b>Documents</b></Typography>
    <DocumentCta />
    {documents.length > 0 && <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {documents.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
                display: 'flex',
              }}
            >
              <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                <Typography>{row.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <DocumentDeleteButton documentId={row.documentId} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    }
    <Typography variant='subtitle1'><b>Links</b></Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {knowledgeLinks.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
                display: 'flex',
              }}
            >
              <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <Typography>{row.url}</Typography>
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Updated {(new Date(row.updated)).toLocaleString()}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">
                <KnowledgeDeleteButton knowledgeBaseId={row.id} variant='link' />
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
              display: 'flex',
            }}
          >
            <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
              <TextField placeholder='http://... or https://...'
                variant='standard'
                value={link}
                disabled={saveInProgress}
                onChange={e => setLink(e.target.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (link) saveKnowledgeLink();
                  }
                }}
              />
            </TableCell>
            <TableCell align="right">
              {saveInProgress
                ? <CircularProgress size='1rem' />
                : <IconButton disabled={!link} color='secondary' onClick={saveKnowledgeLink}>
                  <SaveOutlinedIcon fontSize='small' />
                </IconButton>
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <Typography variant='subtitle1'><b>Sitemaps</b></Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {knowledgeSitemaps.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
                display: 'flex',
              }}
            >
              <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <Typography>{row.url}</Typography>
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Updated {(new Date(row.updated)).toLocaleString()}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">
                <KnowledgeDeleteButton knowledgeBaseId={row.id} variant='sitemap' />
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
              display: 'flex',
            }}
          >
            <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
              <TextField placeholder='http://... or https://...'
                variant='standard'
                value={sitemap}
                disabled={saveInProgress}
                onChange={e => setSitemap(e.target.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (sitemap) saveKnowledgeSitemap();
                  }
                }}
              />
            </TableCell>
            <TableCell align="right">
              {saveInProgress
                ? <CircularProgress size='1rem' />
                : <IconButton disabled={!sitemap} color='secondary' onClick={saveKnowledgeSitemap}>
                  <SaveOutlinedIcon fontSize='small' />
                </IconButton>
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Stack>
}
