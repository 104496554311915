import { FetchResult, Observer, useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CONTINUE_DEBUG_RUN, CONTINUE_RUN, START_DEBUG_RUN, START_RUN } from '../graphql/subscription';
import { IFlowConfig, IRerunOptions, IUserInput, RoleV2, TAppBreakpoint, TAppDebugInfo, TAppDebugRunResult, TAppDisplayUpdate, TAppRunResult, TAppStatusUpdate, TFlowRunMetadata, TMessage, TUserInputRequirement, TaiPluginRunInfo } from '../../generated/gql/graphql';
import { useShallow } from 'zustand/react/shallow';
import { useClientStore } from './ClientState';
import { makeWebPageInteraction, navigateToUrl } from '../utils/webPageInteraction';
import { useUserAndWorkspaceStore } from './UserAndWorkspaceStore';
import { useEditorStore } from './EditorState';


export type AppRunOptions = {
  debug: false;
  flowId: string;
  startId: string | null;
} | {
  debug: true;
  flowConfig: IFlowConfig;
  flowId?: string;
  startId: string | null;
  aiConfig?: any;
};

export type AppRun = {
  start: () => void;
  send: (
    userInputs: IUserInput[],
    onComplete?: () => void,
    rerunOptions?: IRerunOptions,
    replayMessages?: boolean,
  ) => void;
  rerun: (nodeId: string, nodeVersion: number) => void;
  disconnect: (keepSession?: boolean) => void;
  inProgress: boolean;
  isReady: boolean;
};
type Subscription = {
  unsubscribe: () => void;
  closed: boolean;
};

export function useAppRun(
  options: AppRunOptions,
  onSigninRequired: () => void,
  replayMessagesOnConnect?: boolean,
): AppRun {
  const client = useApolloClient();
  const fetchPolicy = 'no-cache';

  const onSigninRequiredRef = useRef(onSigninRequired);
  useEffect(() => {
    onSigninRequiredRef.current = onSigninRequired;
  }, [onSigninRequired]);

  const siteId = useUserAndWorkspaceStore(state => state.workspaceId);
  const [
    clientId,
    serverInProgress,
    setClientId,
    increaseUnreadCount,
    setInitializing,
    setServerInProgress,
    setInputRequirement,
    addError,
    setCompleted,
    addDebugLog,
    addAiPluginRunLog,
    addMessage,
    resetClient,
    setInterrupted,
    setWebInteractionOutcome,
    updateDebugLogs,
    clearChatEntries,
  ] = useClientStore(useShallow(state => [
    state.clientId,
    state.serverInProgress,
    state.setClientId,
    state.increaseUnreadCount,
    state.setInitializing,
    state.setServerInProgress,
    state.setInputRequirement,
    state.addError,
    state.setCompleted,
    state.addDebugLog,
    state.addAiPluginRunLog,
    state.addMessage,
    state.resetClient,
    state.setInterrupted,
    state.setWebInteractionOutcome,
    state.updateDebugLogs,
    state.clearChatEntries,
  ]));
  const currentBreakpoint = useEditorStore(useShallow(state => state.app?.currentBreakpoint));
  const currentBreakpointRef = useRef(currentBreakpoint);
  useEffect(() => {
    currentBreakpointRef.current = currentBreakpoint;
  }, [currentBreakpoint]);

  const setCurrentBreakpoint = useEditorStore(state => state.actions.setCurrentBreakpoint);
  const breakpoints = useEditorStore(useShallow(state => state.app?.breakpoints || []));

  // to properly set the state to a function, it need to be a function that returns the state
  // otherwise setState would just call the function and save the result
  const [disconnect, setDisconnect] = useState<(keepSession?: boolean) => void>(() => () => { });
  const subRef = useRef<Subscription | null>(null);

  const queue = useRef<(
    TUserInputRequirement |
    TMessage |
    TAppDisplayUpdate |
    TAppDebugInfo |
    TAppStatusUpdate |
    TFlowRunMetadata |
    TaiPluginRunInfo |
    TAppBreakpoint
  )[]>([]);

  // using ref to accurately track if the queue is processing, to avoid duplicate processing
  const queueProcessingInProgress = useRef(false);
  // this is the same as queueProcessingInProgress, but can be outdated. Used for UX rendering
  const [clientInProgress, setClientInProgress] = useState(false);

  const inProgress = serverInProgress || clientInProgress;

  const addStatusMessage = useCallback((message: string, persistent: boolean = false) => {
    addMessage({ __typename: 'TStatusMessage', content: message, persistent });
  }, []);

  const processQueue = useCallback(async () => {
    if (queueProcessingInProgress.current) return;
    if (currentBreakpointRef.current) return;
    queueProcessingInProgress.current = true;
    let isBreakpointHit = false;
    try {
      // NOTE: if queue.current changes during processing, this will not pick up the new queue
      while (queue.current.length > 0 && !isBreakpointHit) {
        const result = queue.current.shift();
        switch (result.__typename) {
          case 'TFlowRunMetadata':
            setClientId(result.clientId);
            setInitializing(false);
            break;
          case 'TMessage':
            addMessage(result);
            increaseUnreadCount();
            break;
          case 'TAppDisplayUpdate':
            if (result.statusMessage) {
              addMessage(result.statusMessage);
            }
            if (result.navigationUrl) {
              addStatusMessage(`Navigate to ${result.navigationUrl}`, true);
              await navigateToUrl(result.navigationUrl);
            }
            if (result.interaction) {
              const actionInputStr = result.interaction.actionInput ? ` with input \`${result.interaction.actionInput}\`` : '';
              addStatusMessage(`${result.interaction.action} on element \`${result.interaction.selector}\`${actionInputStr}`, true);
              await makeWebPageInteraction(result.interaction)
                .then(changedHTML => {
                  if (result.interaction.description) {
                    addStatusMessage(result.interaction.description, true);
                  }
                  setWebInteractionOutcome({ htmlDelta: changedHTML });
                })
                .catch(e => {
                  addError(e);
                  setWebInteractionOutcome({
                    error: {
                      name: e.name,
                      message: e.message,
                      response: e.response,
                    }
                  });
                });
            }
            if (result.jsonData) {
              addMessage({
                __typename: 'TMessage',
                role: RoleV2.Assistant,
                content: '```\n' + JSON.stringify(result.jsonData, null, 2) + '\n```'
              });
            }
            break;
          case 'TUserInputRequirement':
            setInputRequirement(result);
            break;
          case 'TAppDebugInfo':
            addDebugLog(result);
            break;
          case 'TAppStatusUpdate':
            setCompleted(result.hasEnded);
            if (result.signinRequired) {
              onSigninRequiredRef.current();
            }
            break;
          case 'TAIPluginRunInfo':
            addAiPluginRunLog(result);
            break;
          case 'TAppBreakpoint':
            setCurrentBreakpoint(result);
            isBreakpointHit = true;
            break;
        }
      }
    } catch (e) {
      // TODO: save information about the client error to be used for resuming
      subRef.current?.unsubscribe();
      addError(e);
      setInterrupted('error');
      setInitializing(false);
      setServerInProgress(false);
    }
    queueProcessingInProgress.current = false;
    setClientInProgress(false);
  }, []);

  const setSubscription = useCallback((sub: Subscription) => {
    subRef.current = sub;
    // NOTE disconnect is NOT called on existing subscription (if it exists)
    // as doing so might introduce inconsistency in the state
    // We are expecting the caller of this hook to properly disconnect the previous subscription
    // whenever they changes AppRunOptions
    setDisconnect(() => (keepSession?: boolean) => {
      sub.unsubscribe();
      // we have to reset state here instead of watching for subscription.closed
      // as there's no way to properly trigger when the closed value changes.
      setInitializing(false);
      setServerInProgress(false);
      if (!keepSession) {
        resetClient();
      }
    });
  }, []);


  const getObserver = useCallback(<T extends FetchResult>(
    getResult: (data: T) => TAppRunResult | TAppDebugRunResult | undefined,
    onComplete?: () => void
  ): Observer<T> => {
    return {
      next(value) {
        const res = getResult(value);
        if (res) {
          queue.current.push(res.result);
          setClientInProgress(true);
          processQueue();
        }
        if (value.errors && value.errors.length > 0) {
          addError(value.errors[0]);
        }
      },
      error(errorValue) {
        console.error('subscription error', errorValue);
        addError(errorValue);
        setServerInProgress(false);
        setInitializing(false);
        setInterrupted('error');
        onComplete?.();
      },
      complete() {
        setServerInProgress(false);
        setInitializing(false);
        onComplete?.();
      },
    };
  }, []);

  // app cannot start when it's debug and doesn't have siteId
  const isReady = !options.debug || Boolean(siteId);

  const start = useCallback(() => {
    // NOTE siteId is required for debug endpoints.
    // NOTE start should not be called when isReady is false
    // TODO remove the need for siteId in debug endpoints
    if (!isReady) {
      throw new Error('AppRun is not ready to start');
    };

    setServerInProgress(true);
    if (clientId) {
      if (options.debug) {
        setSubscription(
          client
            .subscribe({
              query: CONTINUE_DEBUG_RUN,
              variables: {
                siteId,
                clientId: clientId,
                userInputs: [],
                aiConfigJson: options.aiConfig,
                breakpoints,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.acontinueDebugRun
            ))
        );
      }
      else {
        setSubscription(
          client
            .subscribe({
              query: CONTINUE_RUN,
              variables: {
                clientId: clientId,
                userInputs: [],
                replayMessages: replayMessagesOnConnect,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.acontinueRun
            ))
        );
      };
    }
    else {
      if (options.debug) {
        setSubscription(
          client
            .subscribe({
              query: START_DEBUG_RUN,
              variables: {
                siteId,
                flow: options.flowConfig,
                flowId: options.flowId,
                startId: options.startId,
                userInputs: [],
                aiConfigJson: options.aiConfig,
                breakpoints,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.astartDebugRun
            ))
        );
      }
      else {
        setSubscription(
          client
            .subscribe({
              query: START_RUN,
              variables: {
                flowId: options.flowId,
                startId: options.startId,
                userInputs: [],
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.astartRun
            ))
        );
      }
    }
  }, [options, siteId, isReady, breakpoints]);

  const send = (
    userInputs: IUserInput[],
    onComplete?: () => void,
    // only effective when debug is true
    rerunOptions?: IRerunOptions,
    replayMessages?: boolean,
  ) => {
    if (!clientId) return;
    setServerInProgress(true);
    if (options.debug) {
      setSubscription(
        client
          .subscribe({
            query: CONTINUE_DEBUG_RUN,
            variables: {
              siteId,
              clientId,
              userInputs,
              aiConfigJson: options.aiConfig,
              replayMessages,
              rerunOptions,
              breakpoints,
            },
            fetchPolicy,
          })
          .subscribe(getObserver(v => v.data?.acontinueDebugRun, onComplete))
      );
    }
    else {
      setSubscription(
        client
          .subscribe({
            query: CONTINUE_RUN,
            variables: {
              clientId,
              userInputs,
              replayMessages,
            },
            fetchPolicy,
          })
          .subscribe(getObserver(v => v.data?.acontinueRun, onComplete))
      );
    }
  };

  const removeDebugLogsAfter = useCallback((nodeId: string, nodeVersion: number) => {
    updateDebugLogs(logs => {
      const idx = logs.findIndex(l => l.nodeId === nodeId && l.nodeVersion === nodeVersion);
      if (idx < 0) return logs;
      // we are assuming the entire step (node) will be rerun
      return logs.slice(0, idx);
    });
  }, []);

  const rerun = useCallback((nodeId: string, nodeVersion: number) => {
    disconnect(true);
    removeDebugLogsAfter(nodeId, nodeVersion);
    clearChatEntries();
    setInterrupted(null);
    send(
      [],
      null,
      {
        nodeId,
        version: nodeVersion,
      },
      true,
    );
  }, [disconnect, removeDebugLogsAfter, send]);

  const appRun = useMemo(() => ({
    start,
    send,
    disconnect,
    rerun,
    inProgress,
    isReady,
  }), [start, send, disconnect, rerun, inProgress, isReady]);

  return appRun;
}
