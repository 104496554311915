import React, { useState, useRef, useEffect } from 'react';
import { CircularProgress, IconButton, Button, Tooltip } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

export type DeleteButtonProps = {
  onDelete: () => Promise<void>;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  tooltip?: string;
};

export function DeleteButton({ onDelete, label, tooltip, size = 'medium', disabled = false }: DeleteButtonProps): React.ReactElement {
  const [confirmMode, setConfirmMode] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (inProgress) return;

    if (confirmMode) {
      setInProgress(true);
      onDelete().finally(() => {
        setInProgress(false);
        setConfirmMode(false);
      });
    } else {
      setConfirmMode(true);
    }
  };

  // Handle clicks outside the component
  useEffect(() => {
    if (!confirmMode) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setConfirmMode(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [confirmMode]);

  if (confirmMode) {
    return (
      <div ref={containerRef}>
        <Button
          ref={buttonRef}
          variant="contained"
          color="error"
          size={size}
          onClick={inProgress ? undefined : handleClick}
          disabled={disabled}
          sx={{ borderRadius: 10 }}
        >
          {inProgress ? <CircularProgress size={size === 'small' ? '1.5rem' : '2rem'} color="inherit" /> : 'Delete?'}
        </Button>
      </div>
    );
  }

  const button = (
    <div ref={containerRef}>
      <IconButton
        ref={buttonRef}
        color="default"
        onClick={handleClick}
        size={size}
        disabled={disabled}
        aria-label={label || "Delete"}
      >
        <DeleteOutlineRoundedIcon fontSize={size} color='error' />
      </IconButton>
    </div>
  );

  return tooltip ?
    <Tooltip title={tooltip}>
      {button}
    </Tooltip>
    : button;
}
