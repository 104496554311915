import React from 'react';
import { Divider, FormControlLabel, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { FlowNodeName } from '../FlowNodeName';
import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { useUserAndWorkspaceStore } from '../../../../hooks/UserAndWorkspaceStore';
import { AIConfigurationView } from '../AIConfigurationView';


export function AppSettingsPanel(): React.ReactElement {
  const startNodeId = useEditorStore(state => state.app.startNodeId);
  const setStartNodeId = useEditorStore(state => state.actions.setStartNodeId);
  const nodeIds = useEditorStore(useShallow(state => state.graph.nodes.map(n => n.id)));
  const aiConfig = useEditorStore(useShallow(state => state.app.aiConfig));
  const setAiConfig = useEditorStore(state => state.actions.setAiConfig);
  const [requireSignin, setRequireSignin] = useEditorStore(useShallow(state => [
    state.app.signinRequired,
    state.actions.setSigninRequired,
  ]))
  const [__developerMode, userIsDeveloper, setDeveloperMode] = useUserAndWorkspaceStore(useShallow(state => [
    state.__developerMode,
    state.user?.isDeveloper,
    state.setDeveloperMode,
  ]));

  return <Stack spacing={2}>
    <Typography variant='subtitle1'><b>Starting point</b></Typography>
    {nodeIds.length == 0
      ? <Typography>Add a function to configure starting point.</Typography>
      : <Select
        value={startNodeId || nodeIds[0]}
        onChange={e => setStartNodeId(e.target.value)}
      >
        {nodeIds.map(
          id => <MenuItem key={id} value={id}>
            <FlowNodeName nodeId={id} variant='description' />
          </MenuItem>
        )}
      </Select>
    }

    <Divider />

    <Typography variant='subtitle1'><b>Authentication</b></Typography>
    <FormControlLabel
      control={<Switch checked={requireSignin} onChange={(_, checked) => setRequireSignin(checked)} />}
      label="Require user to signin"
    />

    <Divider />
    <Typography variant='subtitle1'><b>AI settings</b></Typography>
    <AIConfigurationView aiConfig={aiConfig} setAiConfig={setAiConfig} />

    {userIsDeveloper && <FormControlLabel
      control={<Switch checked={__developerMode} onChange={(_, checked) => setDeveloperMode(checked)} />}
      label="Developer Mode"
    />}
  </Stack>
}
