import { useMemo } from "react";
import { useStoreApi } from "reactflow";

export function useGraphPosition() {
  const store = useStoreApi();
  const {
    height,
    width,
    transform: [transformX, transformY, zoomLevel]
  } = store.getState();

  const center = useMemo(() => {
    const zoomMultiplier = 1 / zoomLevel;

    // Figure out the center of the current viewport
    const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
    const centerY =
      -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;
    return { x: centerX, y: centerY };
  }, [height, width, transformX, transformY, zoomLevel]);

  const ret = useMemo(() => ({ center }), [center]);
  return ret;
}
