

export enum SettingsMenuOption {
  AppSettings = 'App settings',
  KnowledgeBases = "Knowledge bases",
  LabeledResults = 'Examples for AI',
  RunHistory = 'Run history',
  LinkedAccounts = 'Linked accounts',
  LaunchChannels = 'Launch channels',
  AppAppearance = 'App appearance',
  AppTemplate = "App Template",
}
