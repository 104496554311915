import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Selection } from '../types/Selection';
import { useEditorStore } from './EditorState';
import { arrayToObject } from '../utils/common';
import { useShallow } from 'zustand/react/shallow';
import { useUserAndWorkspaceStore } from './UserAndWorkspaceStore';
import { useNodeCreation } from '../features/Pixie/Editor/PluginSelectionMenu';
import { useGraphPosition } from './useGraphPosition';
import { create, createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';


type CommandEvent = MouseEvent | KeyboardEvent | React.MouseEvent | React.KeyboardEvent;


function isKeyboardEvent(event?: CommandEvent): event is KeyboardEvent | React.KeyboardEvent {
  return event && 'key' in event;
}

export type EditorCommandType =
  "graphCopy"
  | 'graphPaste'
  | "graphSelectAll"
  | "graphUndo"
  | "graphRedo"
  | "graphAddNodeShortcut";

export type EditorCommand = {
  type: EditorCommandType
  code: string
  ctrl?: boolean
  shift?: boolean
  meta?: boolean
  alt?: boolean
  // check whether keyboard command should trigger action
  // only applies to keyboard triggers, action can still be directly invoked
  triggerCondition?: (event: CommandEvent) => boolean
  action: (event?: CommandEvent) => void,
}
export type EditorCommandMap = {
  [Key in EditorCommandType]: EditorCommand
}

export function useEditorCommands() {

  const [
    selections,
    clipboard,
    copyElements,
    setClipboard,
    selectAll,
    undo,
    redo,
  ] = useEditorStore(useShallow(state => [
    state.actions.graph.getSelections(),
    state.clipboard,
    state.actions.graph.copyElements,
    state.actions.setClipboard,
    state.actions.graph.selectAll,
    state.actions.graph.undo,
    state.actions.graph.redo,
  ]));

  const pastActions = useEditorStore(useShallow(state => state.app.graph.pastActions));
  const futureActions = useEditorStore(useShallow(state => state.app.graph.futureActions));
  const shortcuts = useUserAndWorkspaceStore(useShallow(state => state.getShortcuts()));
  const { center } = useGraphPosition();
  const { createPluginNode, createConstructedPluginNode } = useNodeCreation({ position: center });

  const commands = useMemo(() => {
    const commandList = [];

    commandList.push(...shortcuts.slice(0, 10).map((s, i) => {
      return {
        type: `graphAddNodeShortcut${i}`,
        code: `Digit${i + 1}`,
        ctrl: true,
        alt: true,
        action: (event?: CommandEvent) => {
          if (isKeyboardEvent(event)) {
            event.stopPropagation();
            event.preventDefault();
          }
          if (s.pluginType) {
            createPluginNode(s.pluginType);
          }
          else if (s.pluginConstructorType) {
            createConstructedPluginNode(s.pluginConstructorType);
          }
        },
      }
    }));

    commandList.push(...[
      {
        type: 'graphSelectAll',
        code: 'KeyA',
        ctrl: true,
        alt: true,
        action: (event?: CommandEvent) => {
          if (isKeyboardEvent(event)) {
            event.stopPropagation();
            event.preventDefault();
          }
          selectAll();
        },
      },
      {
        type: 'graphUndo',
        code: 'KeyZ',
        ctrl: true,
        alt: true,
        action: (event?: CommandEvent) => {
          console.log('undo', pastActions, futureActions);
          if (isKeyboardEvent(event)) {
            event.stopPropagation();
            event.preventDefault();
          }
          undo();
        },
      },
      {
        type: 'graphRedo',
        code: 'KeyY',
        ctrl: true,
        alt: true,
        action: (event?: CommandEvent) => {
          console.log('redo', pastActions, futureActions);
          if (isKeyboardEvent(event)) {
            event.stopPropagation();
            event.preventDefault();
          }
          redo();
        },
      }
    ]);

    if (selections.length > 0) {
      commandList.push({
        type: 'graphCopy',
        code: 'KeyC',
        ctrl: true,
        alt: true,
        // TODO enable trigger condition if go back to common ctrl+c command
        // triggerCondition: () => {
        //   const selection = window.getSelection();
        //   if (!selection) return true;
        //   const selectionText = selection.toString();
        //   // pretty hacky, but works for now when selecting multiple in react-flow
        //   // TODO find a cleaner way to check whether selection is react-flow
        //   return selectionText.length === 0 || selectionText === '\nReact Flow';
        // },
        action: (event?: CommandEvent) => {
          if (isKeyboardEvent(event)) {
            event.stopPropagation();
            event.preventDefault();
          }
          setClipboard([...selections]);
        },
      })
    }

    if (clipboard.length > 0) {
      commandList.push(
        {
          type: 'graphPaste',
          code: 'KeyV',
          ctrl: true,
          alt: true,
          action: (event?: CommandEvent) => {
            if (isKeyboardEvent(event)) {
              event.stopPropagation();
              event.preventDefault();
            }
            copyElements(clipboard);
          }
        }
      )
    }

    const commands: EditorCommandMap = arrayToObject(commandList, 'type') as EditorCommandMap;
    return commands;
  }, [selections, clipboard]);

  return commands;
}

interface KeyboardState {
  ctrlPressed: boolean
  shiftPressed: boolean
  metaPressed: boolean
  altPressed: boolean
  setCtrlPressed: (pressed: boolean) => void
  setShiftPressed: (pressed: boolean) => void
  setMetaPressed: (pressed: boolean) => void
  setAltPressed: (pressed: boolean) => void
}

const useKeyboardState = create<KeyboardState>()(
  devtools(immer((set) => ({
    ctrlPressed: false,
    shiftPressed: false,
    metaPressed: false,
    altPressed: false,
    setCtrlPressed: (pressed: boolean) => {
      set(state => {
        state.ctrlPressed = pressed;
      });
    },
    setShiftPressed: (pressed: boolean) => {
      set(state => {
        state.shiftPressed = pressed;
      });
    },
    setMetaPressed: (pressed: boolean) => {
      set(state => {
        state.metaPressed = pressed;
      });
    },
    setAltPressed: (pressed: boolean) => {
      set(state => {
        state.altPressed = pressed;
      });
    },
  })))
)

// NOTE: this must be used in children of ReactFlow component for now
// TODO having graph center position stored in EditorStore instead of useGraphPosition
export function useKeyboardShortcuts(element: HTMLElement | Document = document) {

  const commands = useEditorCommands();
  const setShortcutsPopoverOpen = useEditorStore(state => state.actions.setShortcutsPopoverOpen);
  const [
    shouldShowShortcuts,
    setCtrlPressed,
    setShiftPressed,
    setMetaPressed,
    setAltPressed,
  ] = useKeyboardState(useShallow(state => [
    state.ctrlPressed && state.altPressed,
    state.setCtrlPressed,
    state.setShiftPressed,
    state.setMetaPressed,
    state.setAltPressed,
  ]));

  React.useEffect(() => {
    setShortcutsPopoverOpen(shouldShowShortcuts);
  }, [shouldShowShortcuts]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        setCtrlPressed(true);
      }
      if (event.key === 'Shift') {
        setShiftPressed(true);
      }
      if (event.key === 'Meta') {
        setMetaPressed(true);
      }
      if (event.key === 'Alt') {
        setAltPressed(true);
      }

      const command = Object.values(commands).find(cmd =>
        cmd.code === event.code &&
        (cmd.ctrl === undefined || cmd.ctrl === event.ctrlKey) &&
        (cmd.shift === undefined || cmd.shift === event.shiftKey) &&
        (cmd.meta === undefined || cmd.meta === event.metaKey) &&
        (cmd.alt === undefined || cmd.alt === event.altKey)
      );
      if (command && (!command.triggerCondition || command.triggerCondition(event))) {
        command.action(event);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        setCtrlPressed(false);
      }
      if (event.key === 'Shift') {
        setShiftPressed(false);
      }
      if (event.key === 'Meta') {
        setMetaPressed(false);
      }
      if (event.key === 'Alt') {
        setAltPressed(false);
      }
    }

    element.addEventListener('keydown', handleKeyDown);
    element.addEventListener('keyup', handleKeyUp);

    return () => {
      element.removeEventListener('keydown', handleKeyDown);
      element.removeEventListener('keyup', handleKeyUp);
    };
  }, [element, commands]);

  const ret = useMemo(() => ({ commands }), [commands]);
  return ret;
}
