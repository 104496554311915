import { useMutation } from '@apollo/client';
import { Box, Button, LinearProgress, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SUBMIT_CONTACT_FORM } from '../graphql/mutation';
import { AppContext } from '../contexts/AppContext';
import { EmailSubscriptionContext } from "../contexts/EmailSubscriptionContext";
import { defaultSiteId } from '../constants/defaultSiteId';


export default function EmailSubscription({ label, successLabel }): React.ReactElement {
  const [email, setEmail] = useState('');
  const [submitContactForm] = useMutation(SUBMIT_CONTACT_FORM);
  const [isValid, setIsValid] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const siteId = defaultSiteId;
  const { setError, setSuccessMessage } = useContext(AppContext);
  const { hasSubscribed, setHasSubscribed } = useContext(EmailSubscriptionContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Perform additional validation using a regular expression
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    setIsValid(emailPattern.test(inputEmail));
  };

  function handleSubscribe() {
    if (email.length == 0 || !isValid) setIsValid(false);
    else {
      setInProgress(true);
      submitContactForm({ variables: { siteId, email, subscribeForUpdate: true, comment: `cta - ${label}` } })
        .then(_ => {
          setSuccessMessage("successfully subscribed for email updates.");
          setHasSubscribed(true);
        })
        .catch(e => setError(e))
        .finally(() => setInProgress(false));
    }
  }

  return hasSubscribed
    ? <Box>
      <Button
        variant='contained'
        disabled
        sx={{
          '&.Mui-disabled': {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.contrastText,
          },
        }}>
        <Typography variant='h6' p='4px'><b>{successLabel}</b></Typography>
      </Button>
    </Box>
    : inProgress
      ? <LinearProgress />
      : <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2} display='flex' alignItems={isSmallScreen ? 'center' : 'flex-start'}>
        <TextField
          type='email'
          variant='outlined'
          placeholder='your email'
          value={email}
          onChange={handleEmailChange}
          error={!isValid}
          helperText={!isValid ? 'Please enter a valid email address.' : ''}
          onKeyDown={e => { if (e.key == 'Enter') handleSubscribe() }}
          inputProps={{ style: { textAlign: 'center' } }}
        />
        <Button variant='contained' sx={{ textTransform: 'none' }} size='large' onClick={handleSubscribe} color='secondary'>
          <Typography variant='h6' p='4px'><b>{label}</b></Typography>
        </Button>
      </Stack >
}
