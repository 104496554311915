import {
  Connection,
  Handle,
  Position,
  getConnectedEdges,
  useStore,
} from "reactflow";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { EditorGraphContext } from "./Editor.contexts";
import { useEditorStore } from "../../../hooks/EditorState";
import { useShallow } from "zustand/react/shallow";


export function isValidConnection(conn: Connection): boolean {
  return conn.sourceHandle != 'prev' && conn.targetHandle == 'prev';
}

function ConnectHandleLabel(props: {
  label: string,
  position: Position.Top | Position.Bottom,
}): React.ReactElement {
  const theme = useTheme();

  if (!props.label) return <></>
  const offsets = props.position == Position.Top
    ? { top: -55 }
    : { bottom: -55 }

  return <Paper
    sx={{
      p: 1,
      position: 'absolute',
      left: '100%',
      background: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
      ...offsets,
    }}
    elevation={3}
  >
    <Typography variant='h5' position='relative'><b>{props.label}</b></Typography>
  </Paper>
}

export function ConnectHandle(props: {
  nodeId: string,
  handleId: string,
  // set to static when used in a connectionHandleGroup
  // or relying parent to position the handles
  static?: boolean,
  highlighted?: boolean,
}): React.ReactElement {
  const [hover, setHover] = useState(false);
  const highlightedConnectHandles = useEditorStore(useShallow(state => state.app.highlightedConnectHandles));
  const showLabel = hover || props.highlighted || highlightedConnectHandles?.find(
    handle => props.nodeId == handle.nodeId && props.handleId == handle.handleId
  );

  const outgoingEdgeCount = useEditorStore(state =>
    Object.values(state.app.graph.edges)
      .filter(e => e.source === props.nodeId && e.sourceHandle === props.handleId).length
  )

  const handleType = useMemo(() => {
    switch (props.handleId) {
      case 'prev':
        return "target";
      default:
        return "source";
    }
  }, [props.handleId]);
  const position = useMemo(() => {
    switch (props.handleId) {
      case 'prev':
        return Position.Top;
      default:
        return Position.Bottom;
    }
  }, [props.handleId]);

  const offsets = useMemo(() => {
    switch (props.handleId) {
      case 'prev':
        return { top: -30 };
      default:
        return { bottom: -30 };
    }

  }, [props.handleId]);

  return <Box
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
  >
    <Handle
      type={handleType}
      position={position}
      id={props.handleId}
      isConnectable={handleType == 'target' || !outgoingEdgeCount}
      style={props.static
        ? { position: 'static' }
        : offsets
      }
    >
      {handleType == 'source' && showLabel && <ConnectHandleLabel label={props.handleId} position={position} />}
    </Handle>
  </Box>
}

// we assume handle group is always at bottom
export function ConnectHandleGroup(props: {
  nodeId: string,
  handleIds: string[],
  highlighted?: string[],
}): React.ReactElement {

  return props.handleIds.length == 1
    ? <ConnectHandle
      nodeId={props.nodeId}
      handleId={props.handleIds[0]}
      highlighted={props.highlighted?.includes(props.handleIds[0])}
    />
    : <Box
      position='absolute'
      width='100%'
      bottom={-30}
      display='flex'
      justifyContent='space-evenly'
    >
      {props.handleIds.map(
        (handleId, idx) => <ConnectHandle
          nodeId={props.nodeId}
          key={idx}
          handleId={handleId}
          static
          highlighted={props.highlighted?.includes(handleId)}
        />
      )}
    </Box>
}
