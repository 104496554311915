import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const containerRef = useContext(AppContext).containerRef!;

  useEffect(() => {
    containerRef.current!.scrollTo(0, 0);
  }, [pathname]);

  return null;
}