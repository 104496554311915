import React, { createContext } from "react";
import { SiteDocument, TDatasetIdWIthName } from "../../generated/gql/graphql";
import { useQuery } from "@apollo/client";
import { GET_DATASETS, GET_DOCUMENTS } from "../graphql/query";
import { useUserAndWorkspaceStore } from "../hooks/UserAndWorkspaceStore";

export const AssetsContext = createContext({
  documents: [] as SiteDocument[],
  refetchDocuments: () => { },
  datasets: [] as TDatasetIdWIthName[],
  refetchDatasets: () => { },
});


export default function SiteAssetsProvider(props: {
  siteId: string,
  children: React.ReactNode,
}): React.ReactElement {
  const user = useUserAndWorkspaceStore(state => state.user);

  const documentsQuery = useQuery(GET_DOCUMENTS, { variables: { siteId: props.siteId }, skip: !user });
  const datasetsQuery = useQuery(GET_DATASETS, { variables: { siteId: props.siteId }, skip: !user });


  return <AssetsContext.Provider value={{
    documents: documentsQuery.data?.documents || [],
    refetchDocuments: documentsQuery.refetch,
    datasets: datasetsQuery.data?.datasets || [],
    refetchDatasets: datasetsQuery.refetch,
  }}>
    {props.children}
  </AssetsContext.Provider>
}
