import React, { useState } from "react";
import { ContentBlock, Image } from "../../../generated/gql/graphql";
import { Collapse, Divider, IconButton, Stack, Typography, useTheme } from "@mui/material";
import ContentCard, { ContentContainer, Title, withEditing } from "../business-profile/content-card";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditableTypography from "../editable-typography";


interface ExpandableTextProps {
  pageId: string,
  initialText: string,
  additionalText: string,
  editing?: boolean,
  onSave: (sprops: ExpandableTextProps) => Promise<void>,
}


const ExpandableTextBase = (props: ExpandableTextProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return <Stack spacing={2}
    onClick={handleExpandClick}
    sx={{ cursor: 'pointer' }}
  >
    <Stack
      direction='row'
      spacing={2}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <EditableTypography
        variant="h6"
        value={props.initialText}
        editing={props.editing}
        onSave={async v => await props.onSave({ ...props, initialText: v })}
      />
      {expanded ? <RemoveIcon /> : <AddIcon />}
    </Stack>
    <Collapse in={expanded}>
      <EditableTypography
        markdown
        value={props.additionalText}
        variant="body1"
        editing={props.editing}
        onSave={async v => await props.onSave({ ...props, additionalText: v })}
      />
    </Collapse >
    <Divider />
  </Stack >;
};

export const ExpandableText = withEditing(ExpandableTextBase, sprops => { return { id: sprops.pageId, label: sprops.initialText } });

export default function About(props: {
  id: string,
  title: string,
  images?: Image[],
  content: ContentBlock[],
}): React.ReactElement {
  const theme = useTheme();

  return <Stack spacing={8} sx={{
    // borderTop: '2px solid black',
    // borderBottom: '2px solid black',
    p: { sx: 4, lg: 8 }
  }}>
    <Title title={props.title} size="small" />
    <Stack spacing={4}>
      {props.content.map((item, idx) => <ExpandableText
        key={`about-content-${idx}`}
        pageId={props.id}
        initialText={item.title}
        additionalText={item.body || "No answers yet."}
        onSave={async sprops => {/* TODO */ }}
      />)}
    </Stack>
  </Stack>
}
