import { useMutation, useApolloClient } from "@apollo/client/react";
import React, { useContext, useState, useEffect } from "react";
import { CREATE_KNOWLEDGE_BASE, ADD_DOCUMENTS_TO_KNOWLEDGE_BASE, DELETE_KNOWLEDGE_BASE } from "../graphql/mutation";
import {
  Button, LinearProgress, Stack, TextField, Typography, Divider, Alert,
  Grid, Card, Box, Paper, Tooltip
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { TKnowledgeBase } from "../../generated/gql/graphql";
import { AppContext } from "../contexts/AppContext";
import { AssetsContext } from "../contexts/AssetsContext";
import { useUserAndWorkspaceStore } from '../hooks/UserAndWorkspaceStore';
import { UploaderV2 } from "./input/uploaderV2";
import { useShallow } from 'zustand/react/shallow';
import { DeleteButton } from "../features/Pixie/Editor/SettingsMenu/DeleteButton";

// DocumentCard component for grid items
interface DocumentCardProps {
  name: string;
  description?: string;
  isSelected?: boolean;
  onClick: () => void;
  colorVariant?: 'primary' | 'success'; // Allow custom color variant
  icon?: React.ReactNode; // Allow custom icon
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  name,
  description,
  isSelected = false,
  onClick,
  colorVariant = 'primary',
  icon
}) => {
  return (
    <Card
      variant="outlined"
      onClick={onClick}
      sx={{
        height: '100%',
        minHeight: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        borderRadius: 2,
        borderWidth: isSelected ? 2 : 1,
        borderColor: isSelected ? `${colorVariant}.dark` : 'action.disabled',
        borderStyle: 'solid',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderWidth: 2,
          borderColor: `${colorVariant}.main`,
          boxShadow: 2,
        },
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center'
        }}
      >
        {icon && (
          <Box sx={{ mb: 1 }}>
            {icon}
          </Box>
        )}
        <Typography
          variant="body1"
          fontWeight={500}
          noWrap
          title={name}
          color={icon ? `${colorVariant}.main` : 'text.primary'}
          sx={{ width: '100%' }}
        >
          {name}
        </Typography>

        {isSelected && (
          <CheckCircleIcon
            sx={{
              color: `${colorVariant}.dark`,
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          />
        )}
      </Box>
    </Card>
  );
};

export function KnowledgeBaseEditor(props: {
  knowledgeBase?: TKnowledgeBase,
  onSubmit: (knowledgeBaseId: string) => void,
}): React.ReactElement {
  const { setError, setSuccessMessage } = useContext(AppContext);
  const workspaceId = useUserAndWorkspaceStore(state => state.workspaceId);
  const loadWorkspaceAssets = useUserAndWorkspaceStore(state => state.loadWorkspaceAssets);
  const files = useUserAndWorkspaceStore(useShallow(state => state.assets.files));
  const isFilesLoading = useUserAndWorkspaceStore(state => state.loadingStatus.files);

  const [name, setName] = useState<string>(props.knowledgeBase?.name || '');
  const [description, setDescription] = useState<string>(props.knowledgeBase?.description || '');
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [isUploadMode, setIsUploadMode] = useState(false);

  const [createKnowledgeBase] = useMutation(CREATE_KNOWLEDGE_BASE);
  const [addDocumentsToKnowledgeBase] = useMutation(ADD_DOCUMENTS_TO_KNOWLEDGE_BASE);
  const [deleteKnowledgeBase] = useMutation(DELETE_KNOWLEDGE_BASE);

  const client = useApolloClient();

  function handleCreateKnowledgeBase() {
    if (!workspaceId) {
      setError("No workspace selected");
      return;
    }

    setUploadInProgress(true);
    createKnowledgeBase({
      variables: {
        workspaceId,
        name,
        description,
      }
    })
      .then(res => {
        if (res.errors) {
          setError(res.errors[0]);
          return null; // Return null to indicate failure
        } else {
          const knowledgeBaseId = res.data.acreateKnowledgeBase.id;
          setSuccessMessage(`Knowledge base ${name} created.`);

          // If documents are selected, add them to the knowledge base
          if (selectedFiles.length > 0) {
            return addDocumentsToKnowledgeBase({
              variables: {
                knowledgeBaseId,
                fileIds: selectedFiles,
              }
            })
              .then(() => knowledgeBaseId)
              .catch(error => {
                setError(error);
                return knowledgeBaseId; // Still return the ID so we can continue
              });
          }

          return knowledgeBaseId;
        }
      })
      .then(knowledgeBaseId => {
        if (knowledgeBaseId) {
          // Reload knowledge bases after creating
          loadWorkspaceAssets(client);
          props.onSubmit(knowledgeBaseId);
        }
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => setUploadInProgress(false));
  }

  async function handleDeleteKnowledgeBase() {
    if (!props.knowledgeBase) return;

    setUploadInProgress(true);
    await deleteKnowledgeBase({
      variables: {
        id: props.knowledgeBase.id,
      }
    })
      .then(() => {
        setSuccessMessage(`Knowledge base ${props.knowledgeBase?.name} deleted.`);
        loadWorkspaceAssets(client);
        props.onSubmit('');
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => setUploadInProgress(false));
  }

  function handleAddDocuments() {
    if (!props.knowledgeBase || selectedFiles.length === 0) return;

    setUploadInProgress(true);
    addDocumentsToKnowledgeBase({
      variables: {
        knowledgeBaseId: props.knowledgeBase.id,
        fileIds: selectedFiles,
      }
    })
      .then(() => {
        setSuccessMessage(`Documents added to knowledge base ${props.knowledgeBase?.name}.`);
        setSelectedFiles([]);
        loadWorkspaceAssets(client);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => setUploadInProgress(false));
  }

  const handleToggleDocument = (documentId: string) => {
    setSelectedFiles(prev =>
      prev.includes(documentId)
        ? prev.filter(id => id !== documentId)
        : [...prev, documentId]
    );
  };

  const handleUploadComplete = (fileIdByNames: { [fileName: string]: string }) => {
    if (Object.keys(fileIdByNames).length > 0) {
      // Get the IDs of the newly uploaded files
      const newFileIds = Object.values(fileIdByNames);

      // Refresh document list
      loadWorkspaceAssets(client).then(() => {
        // Auto-select the newly uploaded files
        setSelectedFiles(prevSelected => {
          // Combine previous selections with new file IDs
          const updatedSelection = [...prevSelected, ...newFileIds];
          return updatedSelection;
        });
      });

      setSuccessMessage(`${Object.keys(fileIdByNames).length} document(s) uploaded successfully.`);
    }

    // Exit upload mode after successful upload
    setIsUploadMode(false);
  };

  const handleCancelUpload = () => {
    setIsUploadMode(false);
  };

  return <Stack spacing={2} p={3}>
    {props.knowledgeBase
      ? <Typography variant="h5">Edit Knowledge Base <b>{props.knowledgeBase.name}</b></Typography>
      : <Typography variant='h5'><b>Create Knowledge Base</b></Typography>
    }

    <TextField
      label="Name"
      value={name}
      onChange={e => setName(e.target.value)}
      error={name === ''}
      disabled={uploadInProgress}
    />

    <TextField
      label="Description (optional)"
      multiline
      minRows={4}
      value={description}
      onChange={e => setDescription(e.target.value)}
      disabled={uploadInProgress}
    />
    <Divider />
    <Typography variant="h6">Documents</Typography>

    {/* Toggle between document grid and uploader UI */}
    {isUploadMode
      ? <Paper
        elevation={2}
        sx={{
          borderRadius: 2,
          borderWidth: 2,
          borderColor: 'success.main',
          borderStyle: 'solid',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '400px',
          p: 2
        }}
      >
        <UploaderV2
          onComplete={handleUploadComplete}
          onCanceled={handleCancelUpload}
          siteId={workspaceId}
        // TODO should we set flowId here?
        // not setting it will make files available to all apps in workspace vs only available in current app
        />
      </Paper>
      : isFilesLoading
        ? <Box sx={{ py: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" color="text.secondary" mb={2}>
            Loading documents...
          </Typography>
          <LinearProgress sx={{ width: '75%' }} />
        </Box>
        : <Box>
          <Grid container spacing={2}>
            {Object.values(files).map(f => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={f.id}>
                <DocumentCard
                  name={f.name}
                  description={JSON.stringify(f)}
                  isSelected={selectedFiles.includes(f.id)}
                  onClick={() => handleToggleDocument(f.id)}
                />
              </Grid>
            ))}

            {/* Add document button */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DocumentCard
                name="Add Document"
                colorVariant="success"
                onClick={() => setIsUploadMode(true)}
                icon={<AddIcon sx={{ color: 'success.main', fontSize: 36 }} />}
              />
            </Grid>
          </Grid>
        </Box>
    }
    <Divider />

    {uploadInProgress
      ? <LinearProgress />
      : <Stack direction="row" spacing={2} justifyContent="flex-end">
        {props.knowledgeBase
          ? <>
            <Button
              onClick={handleAddDocuments}
              variant="contained"
              color="primary"
              disabled={selectedFiles.length === 0}
            >
              Update Knowledge Base
            </Button>
            <DeleteButton onDelete={handleDeleteKnowledgeBase} />
          </>
          : <Button
            onClick={handleCreateKnowledgeBase}
            variant="contained"
            disabled={name === ''}
          >
            Create Knowledge Base
          </Button>
        }
      </Stack>
    }
  </Stack >
}
