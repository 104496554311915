import React, { useState } from "react";
import { GetServicesQuery } from "../../../generated/gql/graphql";
import { Box, Container, Divider, Grid, Stack, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Buttons, ContentContainer, Hero, Summary, SummaryProps, Title, TitleProps, withEditing } from "../business-profile/content-card";


// export default function ServiceListing(props: {
//   title?: string,
//   data: GetServicesQuery,
// }) {
//   if (props.data.services.length == 0) return <></>;

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));
//   const isMd = useMediaQuery(theme.breakpoints.only('md'));
//   const col = isSmallScreen ? 1 : isMd ? 2 : 3;
//   const services = props.data.services;
//   // TODO remove after backend implementation is done
//   const [updatedServices, setUpdatedServices] = useState(services);

//   function getTitle(idx: number): React.ReactElement {
//     const ET = withEditing<TitleProps>(Title, _ => { return { id: services[idx].id, label: services[idx].title } });
//     return <ET
//       title={services[idx].title}
//       subtitle={services[idx].subtitle || undefined}
//       size="medium"
//       onSave={async props => {
//         // TODO save to backend
//         updatedServices[idx] = { ...updatedServices[idx], ...props }
//         setUpdatedServices(updatedServices);
//       }}
//     />
//   }

//   function getSummary(idx: number): React.ReactElement {
//     const ES = withEditing<SummaryProps>(Summary, _ => { return { id: services[idx].id, label: services[idx].title } });
//     return <ES
//       summary={services[idx].description}
//       size="medium"
//       onSave={async props => {
//         // TODO save to backend
//         updatedServices[idx] = { ...updatedServices[idx], ...props }
//         setUpdatedServices(updatedServices);
//       }}
//     />
//   }

//   function getButtons(idx: number): React.ReactElement {
//     return <Buttons
//       buttons={services[idx].buttons}
//     />
//   }

//   function generateRow(
//     startIndex: number,
//     endIndex: number,
//     key: string,
//     getContent: (idx: number) => React.ReactNode,
//     sx?: SxProps,
//   ): React.ReactElement {
//     const rowItems: React.ReactNode[] = [];
//     for (let i = startIndex; i < endIndex; i++) {
//       rowItems.push(
//         <Grid item xs={12 / col} key={`services-${key}-${i}`} sx={{ ...sx, pl: 6, pr: 6 }}>
//           {getContent(i)}
//         </Grid>
//       );
//     }
//     // fill empty items when the row is not full
//     for (let i = 0; i < col + startIndex - endIndex; i++) {
//       rowItems.push(
//         <Grid item xs={12 / col} key={`space-filler-${key}-${i}`} sx={{ ...sx, pl: 6, pr: 6 }} />
//       );
//     }
//     return <Grid
//       container
//       key={`service-grid-row-${startIndex}-${key}`}
//       sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
//     >
//       {rowItems}
//     </Grid>;
//   };

//   const renderGridContent = () => {
//     const gridContent: React.ReactElement[] = [];
//     const totalRows = Math.ceil(services.length / col);

//     for (let rowIndex = 0; rowIndex < totalRows; rowIndex++) {
//       const startIndex = rowIndex * col;
//       const endIndex = Math.min(startIndex + col, services.length);
//       gridContent.push(generateRow(startIndex, endIndex, 'title', getTitle, { textAlign: 'center' }));
//       gridContent.push(generateRow(startIndex, endIndex, 'summary', getSummary, { textAlign: 'center' }));
//       gridContent.push(generateRow(startIndex, endIndex, 'buttons', getButtons, { display: 'flex', justifyContent: 'center' }));
//     }

//     return gridContent;
//   };

//   return <ContentContainer title={props.title}>
//     <Stack direction="column" spacing={4} sx={{ display: 'flex', justifyContent: 'center' }}>
//       {renderGridContent()}
//     </Stack>
//   </ContentContainer>;
// }

export default function ServiceListing(props: {
  title?: string,
  data: GetServicesQuery,
}) {
  if (props.data.services.length == 0) return <></>;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const services = props.data.services;

  const divider = <Grid item xs={12}><Divider variant="middle" /></Grid>

  return <>
    {services.map((s, idx) => <Grid
      key={idx}
      container
      display='flex'
      justifyContent='space-around'
    >
      {idx == 0 ? undefined : divider}
      {idx % 2 == 0 || isSmallScreen ? <Grid item xs={12} md={6} p={{ xs: 4, md: 6, lg: 8 }}><Hero images={s.images} /></Grid> : undefined}
      <Grid item xs={12} md={6} p={{ xs: 4, md: 6, lg: 8 }}>
        <Stack spacing={4} height='100%' display='flex' justifyContent='center'>
          <Title title={s.title} size="small" />
          <Summary summary={s.description} size="large" />
          {s.buttons.length == 0 ? undefined : <Buttons buttons={s.buttons} size="large" />}
        </Stack>
      </Grid>
      {idx % 2 != 0 && !isSmallScreen ? <Grid item xs={12} md={6} p={{ xs: 4, md: 6, lg: 8 }}><Hero images={s.images} /></Grid> : undefined}
    </Grid>)}
  </>
}
