import { Alert, Card, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import React from 'react'
import { canUploadWebContent } from '../../utils/chrome-extension';

export default function WebContentUploadBanner(): React.ReactElement {
  const theme = useTheme();

  return canUploadWebContent() ?
    <Card sx={{ p: 2, width: '100%' }}>
      <Stack direction='row' spacing={1} justifyContent='center' display='flex'>
        <CircularProgress />
        <Typography>Waiting for web content...</Typography>
      </Stack>
    </Card>
    : <Alert severity="warning">Auto-upload web content is only available with chrome extension. Please manually submit html content.</Alert>;
}
