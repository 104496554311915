const displayOptions = {
  menu: {
    height: '70px',
    spacingHeight: '100px',
    spacing: 2,
    p: {
      xs: '5px',
      md: '10px',
      lg: '20px',
    },
  },
  page: {
    p: {
      xs: 2,
      md: 4,
      lg: 8,
    },
    grid: {
      columnSize: {
        xs: 11,
        md: 11,
        lg: 11,
      },
      columnSize1: {
        xs: 11,
        md: 10,
        lg: 8,
      },
      spacing: 6,
    }
  }
};

export default displayOptions;
