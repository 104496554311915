import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import Markdown from '../material-markdown';


const privacyPolicy = `## Introduction

Welcome to pixie.ai ("Service"), operated by Elf.ai LLC ("we," "us," or "our"), a company based in California. Our Service, accessible via gopixie.ai, is dedicated to enabling users to build AI-powered applications with ease. This Privacy Policy details our commitment to protecting your personal information and outlines our practices concerning the collection, use, protection, and sharing of your information.

## 1. Information We Collect

### 1.1 Information You Provide to Us Directly
- **Account Information:** Includes your username, email address, password, and other details you provide during the signup process.
- **User Content:** Comprises the AI configurations, integrations, and other user-generated content within the Service.
- **Communications:** Encompasses messages, feedback, and other forms of communication you send us.

### 1.2 Information We Obtain from Third Parties
- **OAuth Tokens:** We store encrypted OAuth tokens received when you link your business accounts (e.g., Facebook, Instagram) to our Service.

## 2. How We Use Your Data
We utilize your information to provide, maintain, and enhance the Service, communicate with you, ensure security, and conduct analytics for service improvement.

## 3. How We Store and Protect Your Data

### 3.1 Data Storage
Your information, including OAuth tokens and any configurations, is securely stored and encrypted within our systems.

### 3.2 Data Protection
We employ industry-standard encryption and restrict data access to essential personnel only, safeguarding your confidentiality.

## 4. Sharing of Your Data
We do not sell or rent your personal information. However, specific data is shared with third-party AI tools to facilitate core functionalities, based solely on your explicit input and with secured protections.

## 5. Your Choices and Rights
You possess the right to access, amend, or delete your personal data with us. Californian residents have additional rights under the CCPA, which we fully honor.

## 6. Third-Party Services
Interactions with third-party services are governed by their privacy policies. We advise reviewing these policies to understand their data handling practices.

## 7. Children’s Privacy
Our Service does not knowingly collect information from individuals under the age of 13.

## 8. User Uploaded Files
Access to user-uploaded files is strictly limited and occurs only with your explicit consent, ensuring privacy.

## 9. Session Recordings
We use tools like FullStory for session recordings to improve the Service, with sensitive information masked for privacy.

## 10. Changes to This Privacy Policy
Elfai.LLC reserves the right to update this policy. We will notify you of significant changes via email or a notification on our Service.

## 11. Contact Us
If you have questions about this Privacy Policy, please contact us at [yol@gopixie.ai](mailto:yol@gopixie.ai).`;

export default function PrivacyPolicyView(): React.ReactElement {
  return <Container>
    <Stack spacing={2} p={2}>
      <Typography variant='h3'>Privacy Policy</Typography>
      <Typography variant='subtitle2'>Effective Date: Jan 1st, 2024</Typography>
      <Markdown>
        {privacyPolicy}
      </Markdown>
    </Stack>
  </Container>
}
