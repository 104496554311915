import { Alert, Card, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react'
import { canUploadWebContent } from '../../utils/chrome-extension';

export default function WebInteractionOutcomeUploadBanner(): React.ReactElement {

  // Removed auto-submission logic

  return canUploadWebContent() ?
    <Card sx={{ p: 2, width: '100%' }}>
      <Stack direction='row' spacing={1} justifyContent='center' display='flex'>
        <CircularProgress />
        <Typography>Uploading changes in web page since last action...</Typography>
      </Stack>
    </Card>
    : <Alert severity="warning">Auto-upload web content is only available with chrome extension. Please manually submit html content.</Alert>;
}
