import { Box, GlobalStyles, IconButton, Stack, alpha, keyframes, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { TAppDebugInfo } from '../../../../../generated/gql/graphql';
import { Edge, ReactFlowProvider } from 'reactflow';
import DebugGraphView from './Debug';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { createEdges, createFlowFromZustand, createNodes } from '../../../../utils/graph-conversion';
import { SignInDialog } from '../../../../components/Auth';
import CustomizationProvider from '../../../../components/customization/customization-provider';
import { AppRunOptions, ChatAppDialog, useAppRun } from './ChatApp';
import { ChatAppPopover } from './ChatAppPopover';
import { GraphNode } from '../../../../types/GraphNode';


export function DebugChatApp(props: {
  onClose: () => void
}): React.ReactElement {
  const [
    appId,
    nodes,
    edges,
    startNodeId,
    aiConfig,
  ] = useEditorStore(
    useShallow(state => [
      state.app.id || undefined,
      state.app.graph.nodes,
      state.app.graph.edges,
      state.app.startNodeId,
      state.app.aiConfig,
    ])
  );

  const flow = useMemo(() => createFlowFromZustand(nodes.map(n => n.data), edges), [nodes, edges]);

  const appRunOptions = useMemo<AppRunOptions>(() => ({
    debug: true,
    flowConfig: flow,
    flowId: appId,
    startId: startNodeId,
    aiConfig,
  }), [flow, appId]);
  const [showSignin, setShowSignin] = useState(false);
  const appRun = useAppRun(appRunOptions, () => setShowSignin(true));

  const visitedIds = appRun.debugLogs.map(l => l.nodeId);
  const [selectedLog, setSelectedLog] = useState<TAppDebugInfo | undefined>(undefined);
  const theme = useTheme();
  const pulseAnimation = keyframes`
  0% {
    box-shadow: 5px 5px 20px ${alpha(theme.palette.primary.main, 0.5)}
  }
  50% {
    box-shadow: 5px 5px 30px ${alpha(theme.palette.secondary.main, 0.7)}
  }
  100% {
    box-shadow: 5px 5px 20px ${alpha(theme.palette.primary.main, 0.5)}
  }
  `;

  const styledNodes = useMemo(() => {
    return nodes.map(n => {
      const copiedNode = structuredClone(n);
      copiedNode.selected = false;
      if (!visitedIds.includes(n.id)) return copiedNode;
      if (n.id === visitedIds[visitedIds.length - 1]) copiedNode.selected = true;
      else {
        copiedNode.style = {
          ...n.style,
          borderRadius: 16,
          backgroundColor: alpha(theme.palette.success.light, 0.2),
        };
      }
      if (n.id === selectedLog?.nodeId) {
        copiedNode.className = 'glow-box';
      }
      return copiedNode;
    })
  }, [nodes, visitedIds, selectedLog?.nodeId, pulseAnimation]);

  return <Box width='100%' height='100%' overflow='hidden' sx={{
    mr: '10px',
    boxShadow: '5px 0px 5px rgba(0, 0, 0, 0.3)',
  }}>
    <GlobalStyles styles={{
      '.glow-box': {
        animation: `${pulseAnimation} 2s ${theme.transitions.easing.easeInOut} infinite`,
      }
    }} />
    <ReactFlowProvider>
      <DebugGraphView
        nodes={styledNodes}
        edges={edges}
        debugDetails={appRun.debugLogs.filter(l => l.pluginResultData)}
        onSelect={setSelectedLog}
        menu={<Stack spacing={1} p={1}>
          <IconButton color='error' onClick={() => {
            // TODO not all cases we want to unsubscribe on close
            // should make this only happen when running from editor
            appRun.disconnect();
            props.onClose();
          }}><CloseRoundedIcon /></IconButton>
        </Stack>}
        flowId={appId}
        clientId={appRun.clientId}
      />
    </ReactFlowProvider>

    <CustomizationProvider>
      <ChatAppPopover>
        <ChatAppDialog {...appRun} />
      </ChatAppPopover>
    </CustomizationProvider>

    <SignInDialog
      open={showSignin}
      onSignin={() => {
        // NOTE: this could be wrong if signin required is not triggered at the beginning
        appRun.send([]);
        setShowSignin(false);
      }}
      message='Please signin first to use the app.'
    />
  </Box>
}
