import React, { useContext, useState } from 'react';
import { SEARCH_LABELED_RESULTS } from '../../../../graphql/query';
import { useQuery } from '@apollo/client';
import { AppContext } from '../../../../contexts/AppContext';
import { Button, Dialog, IconButton, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';


import AddRoundedIcon from '@mui/icons-material/AddRounded';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { CheckBox } from '@mui/icons-material';
import { ValueDisplay } from '../../../../components/pixie/ValueDisplay';
import { FlowNodeName } from '../FlowNodeName';
import { useEditorStore } from '../../../../hooks/EditorState';

export function LabeledDataPanel(): React.ReactElement {
  const { setError } = useContext(AppContext);
  const appId = useEditorStore(state => state.app.id);
  const selectedNodeId = useEditorStore(state => state.actions.graph.getSelectedNodeId());
  const { data, loading } = useQuery(SEARCH_LABELED_RESULTS, {
    variables: {
      flowId: appId as string,
      pluginId: selectedNodeId!,
    },
    skip: !appId || !selectedNodeId,
    onError: setError,
  });
  const typeInfo = useEditorStore(state => selectedNodeId ? state.actions.graph.getTypeInfo()[selectedNodeId] : undefined);
  const labeledResults = data?.asearchLabeledResults || [];

  const [selectedRow, setSelectedRow] = useState<{
    actualizedParam: any;
    pluginResult: any;
    note?: string | null | undefined;
    created: any,
  } | null>(null);

  if (!appId) return <Typography>Please save the app first.</Typography>
  if (!selectedNodeId || !typeInfo?.pluginInfo?.requiresAi) return <Typography>Please select an AI-powered function.</Typography>

  return loading
    ? <LinearProgress />
    : <Stack spacing={2}>
      <FlowNodeName variant='description' nodeId={selectedNodeId} />
      <Button color='secondary' variant='outlined'><AddRoundedIcon /></Button>
      {labeledResults.length == 0
        ? <Typography>No labeled data.</Typography>
        : <TableContainer component={Paper}>
          <Table>
            {/* <TableHead>
              <TableRow>
                <TableCell>Note</TableCell>
                <TableCell align="right">Input</TableCell>
                <TableCell align="right">Output</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {labeledResults.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'flex' }}
                >
                  <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                    <Stack direction='row' display='flex' alignItems='center'>
                      <Typography>{row.note || (new Date(row.created)).toLocaleString()}</Typography>
                      <IconButton onClick={() => setSelectedRow(row)}><OpenInFullRoundedIcon fontSize='small' /></IconButton>
                    </Stack>
                  </TableCell>
                  {/* <TableCell align="right">{JSON.stringify(row.actualizedParam)}</TableCell>
                  <TableCell align="right">{JSON.stringify(row.pluginResult)}</TableCell> */}
                  <TableCell align="right" sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckBox />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      <Dialog open={Boolean(selectedRow)} onClose={() => setSelectedRow(null)} maxWidth='sm' fullWidth>
        {selectedRow && <Stack spacing={2} p={2}>
          <Typography>{selectedRow.note}</Typography>
          <Typography>{(new Date(selectedRow.created)).toLocaleString()}</Typography>
          <ValueDisplay value={selectedRow.actualizedParam} />
          <ValueDisplay value={selectedRow.pluginResult} />
        </Stack>}
      </Dialog>
    </Stack>
}
