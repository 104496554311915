import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, { Panel, Controls, Edge, useReactFlow, useNodesInitialized } from 'reactflow';
import FlowNode from '../../Editor/FlowNode';
import { flowNodeType, GraphNode } from '../../../../types/GraphNode';
import { Box, Button, Collapse, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { DataLabel, TAppDebugInfo } from '../../../../../generated/gql/graphql';
import { ValueDisplay } from '../../../../components/pixie/ValueDisplay';
import { JSONInputField, TypeString } from '../../../../components/pixie/common';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { useMutation } from '@apollo/client';
import { SAVE_LABELED_RESULT } from '../../../../graphql/mutation';
import { AppContext } from '../../../../contexts/AppContext';

const nodeTypes = { [flowNodeType]: FlowNode };

export default function DebugGraphView(props: {
  nodes: GraphNode[],
  edges: Edge[],
  menu: React.ReactNode,
  debugDetails: TAppDebugInfo[],
  onSelect: (log: TAppDebugInfo | undefined) => void,
  flowId: string | undefined,
  clientId: string | undefined,
}): React.ReactElement {
  // fit view on load
  const { fitView } = useReactFlow();
  const initialized = useNodesInitialized();
  useEffect(() => {
    if (initialized) {
      const timeoutId = setTimeout(
        () => {
          window.requestAnimationFrame(() => fitView());
        },
        100,
      );
      return () => clearTimeout(timeoutId);
    };
  }, [initialized]);


  return <Box width='100%' height='100%'>
    <ReactFlow
      nodeTypes={nodeTypes}
      nodes={props.nodes}
      edges={props.edges}
      multiSelectionKeyCode={null}
      nodesDraggable={false}
      nodesConnectable={false}
      elementsSelectable={false}
      fitView
    >
      <Panel position='top-left'>
        {props.menu}
      </Panel>
      <Panel position="bottom-center" style={{
        paddingBottom: 8,
        width: '80%',
      }}>
        <DebugLogView debugDetails={props.debugDetails} onSelect={props.onSelect} flowId={props.flowId} clientId={props.clientId} />
      </Panel>
      <Controls showInteractive={false} />
      {/* <Background /> */}

    </ReactFlow>
  </Box>
}

function DebugLogView(props: {
  debugDetails: TAppDebugInfo[],
  onSelect: (log: TAppDebugInfo | undefined) => void,
  flowId: string | undefined,
  clientId: string | undefined,
}): React.ReactElement {
  const [activeStep, setActiveStep] = useState<number>(0);
  const activeLog = props.debugDetails[activeStep];
  const maxSteps = props.debugDetails.length;
  const [showLogs, setShowLogs] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!showLogs) props.onSelect(undefined);
    else props.onSelect(activeLog);
  }, [activeLog, showLogs]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setActiveStep(Math.max(props.debugDetails.length - 1, 0));
  }, [props.debugDetails.length]);

  return <Stack sx={{
    background: theme.palette.background.paper,
    borderRadius: 2,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `5px 5px 5px rgba(0, 0, 0, 0.5)`,
    display: 'flex',
    alignItems: 'center',
    maxHeight: '25vh',
    overflow: 'hidden',
    width: '100%',
    '& > *': { width: '100%' },
    p: 1,
  }}>
    {maxSteps == 0
      ? <Typography textAlign='center' color='disabled'>No debug log.</Typography>
      : <>
        <Stack direction='row' spacing={1} display='flex' justifyContent={showLogs ? 'space-between' : 'center'}>
          {showLogs
            ? <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </Button>
            : undefined
          }
          <Button size='small' onClick={() => setShowLogs(s => !s)} sx={{ textTransform: 'none' }}>
            {showLogs ? `hide logs (${activeStep + 1}/${maxSteps})` : `show logs (${activeStep + 1}/${maxSteps})`}
          </Button>
          {showLogs
            ? <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </Button>
            : undefined
          }
        </Stack>

        <Collapse in={showLogs} sx={{ whiteSpace: 'pre-wrap', overflow: 'auto' }}>
          <Stack spacing={2}>
            <Stack direction='row' display='flex' alignItems='center' spacing={2}>
              <Typography variant='h6'>{activeLog.nodeId}</Typography>
              <TypeString>version {activeLog.nodeVersion + 1}</TypeString>
            </Stack>
            {props.flowId
              ? <ResultLabelingView flowId={props.flowId} log={activeLog} clientId={props.clientId} />
              : undefined
            }
          </Stack>
        </Collapse>
      </>
    }
  </Stack>
}


function ResultLabelingView(props: {
  flowId: string | undefined,
  clientId: string | undefined,
  log: TAppDebugInfo,
}): React.ReactElement {
  const [label, setLabel] = useState<DataLabel | null>(null);
  const [editedLog, setEditedLog] = useState<TAppDebugInfo>(props.log);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [save] = useMutation(SAVE_LABELED_RESULT);
  const { setError, setSuccessMessage } = useContext(AppContext);

  useEffect(() => {
    setEditedLog(props.log);
    setLabel(null);
    setInProgress(false);
    setSaved(false);
  }, [props.log]);

  useEffect(() => {
    setEditedLog(props.log);
  }, [label]);

  const canLabel = props.flowId && props.log.pluginId && props.clientId;

  const saveLabeledResult = useCallback(() => {
    if (!canLabel) return;
    let resultOverride = null as { next: string | null | undefined, data: any } | null;
    if (label == DataLabel.Bad) {
      resultOverride = {
        next: editedLog.pluginNext,
        data: editedLog.pluginResultData,
      };
    }
    setInProgress(true);
    save({
      variables: {
        flowId: props.flowId!,
        pluginId: props.log.pluginId!,
        clientId: props.clientId!,
        nodeId: props.log.nodeId,
        version: props.log.nodeVersion,
        label: DataLabel.Good, // always set to good since when labeled bad, we'd be storing the overriden result
        resultOverride,
      }
    }).then(res => {
      if (res.errors) setError(res.errors[0]);
      if (res.data?.asaveLabeledResult) {
        setSaved(true);
        setSuccessMessage(`Labeled result saved with id ${res.data.asaveLabeledResult}`);
      }
      else setError("Something went wrong. Labeled result not saved");
    })
      .catch(setError)
      .finally(
        () => setInProgress(false)
      )
  }, [props, label, editedLog]);

  return <>
    {canLabel
      ? <Stack direction='row' spacing={2}>
        <Box>
          <IconButton
            color={label == DataLabel.Good ? 'success' : 'default'}
            onClick={() => setLabel(DataLabel.Good)}
            disabled={inProgress || saved}
          ><ThumbUpRoundedIcon /></IconButton>
          <IconButton
            color={label == DataLabel.Bad ? 'error' : 'default'}
            onClick={() => setLabel(DataLabel.Bad)}
            disabled={inProgress || saved}
          ><ThumbDownRoundedIcon /></IconButton>
        </Box>
        <Button
          variant='outlined' sx={{ textTransform: 'none' }}
          disabled={!label || inProgress || saved}
          onClick={saveLabeledResult}
          color={saved ? 'success' : 'primary'}
        >
          {saved ? 'Saved' : 'Save'}
        </Button>
      </Stack >
      : undefined
    }
    {canLabel && label == DataLabel.Bad
      ? <>
        <TextField
          name='next' label='Next'
          value={editedLog.pluginNext}
          onChange={e => setEditedLog(l => ({ ...l, pluginNext: e.target.value || null }))}
        />
        <JSONInputField
          label='Result data'
          value={editedLog.pluginResultData}
          onChange={v => setEditedLog(l => ({ ...l, pluginResultData: v }))}
        />
      </>
      : <ValueDisplay value={props.log.pluginResultData} />
    }
  </>
}
