import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_AI_CONFIG_SCHEMA } from '../../../graphql/query';
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import ParamEditor from '../../../components/pixie/param-editor/ParamEditor';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';

export const DEFAULT_AI_MODEL_PROVIDER = "OpenAI";
export const DEFAULT_AI_CONFIG = {
  ai_model_name: DEFAULT_AI_MODEL_PROVIDER
}

export function AIConfigurationView(props: {
  aiConfig: any,
  setAiConfig: (config: any) => void,
  onSchemaLoaded?: (schema: { [modelName: string]: JSONSchema7; }) => void;
}): React.ReactElement {
  const [aiConfigSchema, setAiConfigSchema] = useState<{ [modelName: string]: JSONSchema7; } | null>(null);
  const appId = useEditorStore(state => state.app.id);
  useQuery(GET_AI_CONFIG_SCHEMA, {
    variables: { appId: appId || undefined },
    onCompleted(data) {
      setAiConfigSchema(data.getAiConfigSchema);
      props.onSchemaLoaded?.(data.getAiConfigSchema);
    },
    // https://github.com/apollographql/apollo-client/issues/11151
    notifyOnNetworkStatusChange: true,
  });
  // TODO hack, should get defaults from server.
  const aiModelProvider = props.aiConfig?.ai_model_name || DEFAULT_AI_MODEL_PROVIDER;

  return aiConfigSchema
    ? <Stack spacing={2}>
      <FormControl>
        <InputLabel id="modelProvider">model provider</InputLabel>
        <Select
          label="model provider"
          labelId="modelProvider"
          value={aiModelProvider}
          onChange={e => {
            // set the model provider while clear the configuration when switching provider
            props.setAiConfig({ ai_model_name: e.target.value });
          }}
        >
          {Object.entries(aiConfigSchema).map(([modelName, _]) => {
            return <MenuItem key={modelName} value={modelName}>{modelName}</MenuItem>;
          })}
        </Select>
      </FormControl>
      {aiConfigSchema[aiModelProvider] && <ParamEditor
        dynamicDisabled
        schemaDef={aiConfigSchema[aiModelProvider]}
        value={props.aiConfig?.ai_model_config}
        onChange={(value) => {
          props.setAiConfig({
            ai_model_name: aiModelProvider,
            ai_model_config: structuredClone(value),
          });
        }}
        uniqueId="aiConfig" />}
    </Stack>
    : <LinearProgress />;
}
