import {
  AppBar,
  Box, Button, Container, LinearProgress, Popover, Slide, Stack, SxProps, Toolbar, Tooltip, Typography, useTheme,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  OnConnectEnd,
  OnConnectStart,
  OnConnectStartParams,
  Panel,
  ReactFlowProvider,
} from 'reactflow';
import FlowNode from './FlowNode';
import { FetchResult, useApolloClient, useQuery } from '@apollo/client';
import SiteAssetsProvider from '../../../contexts/AssetsContext';
import { flowNodeType } from '../../../types/GraphNode';
import { getFlowEditorPathV2 } from '../../../components/pixie/common';
import { useHistory, useParams } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import MutationButton from '../../../components/pixie/mutation-button';
import { NameChangeV2 } from './NameChange';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { SettingsMenu } from './SettingsMenu/SettingsMenu';
import { SettingsPanel } from './SettingsMenu/SettingsMenu';
import ConfigurationPanel from './ConfigurationPanel';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow'
import { SignInRequired } from '../../../components/Auth';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { defaultLayout, useClientStore } from '../../../hooks/ClientState';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';
import { GET_APP_TEMPLATE_DETAILS } from '../../../graphql/query';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import { useEditorCommands, useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { AddNodeButton } from './EditorPage.AddNode'; import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import { sendCommandToExtension } from '../Client/Extension/contentScript.comm';
import { AppClientInPopover } from '../Client/Web/AppClient';

import 'reactflow/dist/style.css';

const nodeTypes = { [flowNodeType]: FlowNode };

function FlowGraphView(props: {
  sx?: SxProps,
}) {
  const theme = useTheme();

  const [
    onNodesChange,
    onEdgesChange,
    onConnect,
    setHighlightHandles,
  ] = useEditorStore(
    useShallow(state => [
      state.actions.graph.onNodesChange,
      state.actions.graph.onEdgesChange,
      state.actions.graph.onConnect,
      state.actions.setHighlightedConnectHandles,
    ])
  )
  const nodes = useEditorStore(useShallow(state => state.app.graph.nodes));
  const edges = useEditorStore(useShallow(state => state.app.graph.edges));


  const onConnectStart: OnConnectStart = useCallback((_, param: OnConnectStartParams) => {
    if (param.nodeId && param.handleId) {
      // NOTE we are clearing the existing highlights
      // this is ok for now based on the assumption that connect will trigger deselection
      // and only selection would result in highlight handles being set
      setHighlightHandles([{ nodeId: param.nodeId, handleId: param.handleId }]);
    }
  }, []);

  const onConnectEnd: OnConnectEnd = useCallback(() => {
    // NOTE we are clearing the existing highlights
    // this is ok for now based on the assumption that connect will trigger deselection
    // and only selection would result in highlight handles being set
    setHighlightHandles([]);
  }, []);

  return <Box component="div" sx={props.sx}>
    {/* <TuningContext.Provider value={{ tunings, setTunings, toggleTuning: t => doToggleTuning(tunings, setTunings, t) }}> */}
    {/* <ReactFlowContext.Provider value={contextValue}> */}
    <ReactFlow
      nodeTypes={nodeTypes}
      nodes={nodes}
      onNodesChange={onNodesChange}
      edges={edges}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      // we are not using onSelectionChange due to its reliability
      // it's not triggered when an edge is first selected
      // it's more reliable to handle selection change in onNodesChange and onEdgeChange
      //onSelectionChange={onSelectionChange}
      selectionOnDrag
      multiSelectionKeyCode='Shift'
      // deleteKeyCode="Delete"
      fitView
      elevateEdgesOnSelect
    >
      <GraphActionsPanel />
      <Background />
      <Controls position='bottom-left' />
      <MiniMap pannable position='bottom-right' />
    </ReactFlow>
    {/* </ReactFlowContext.Provider> */}
    {/* </TuningContext.Provider> */}
  </Box>
}

function GraphActionsPanel() {
  useKeyboardShortcuts();
  const selectionCount = useEditorStore(state => state.actions.graph.getSelectionCount)();
  const actionPanelRef = useRef<HTMLDivElement>(null);

  return <Panel position="bottom-center" style={{ paddingBottom: 8 }}>
    <Stack direction='row' ref={actionPanelRef}>
      <AddNodeButton popoverAnchorEl={actionPanelRef.current} />
      <PasteButton />
      <CopyButton />
      {selectionCount > 0 && <RemoveButton />}
    </Stack>
  </Panel>
}

function EditorPage(props: EditorPageProps) {
  return <SignInRequired signInDialog message="Please sign in.">
    <EditorPageInternal {...props} />
  </SignInRequired>
}

function EditorPageInternal(props: EditorPageProps) {

  const height = use100vh();
  const theme = useTheme();
  const routerHistory = useHistory();
  const apolloClient = useApolloClient();
  const loadStaticTypes = useEditorStore(state => state.graphql.loadStaticTypes);

  useEffect(() => {
    loadStaticTypes(apolloClient);
  }, []);

  const { flowId } = useParams<{ flowId: string | undefined }>();

  const [
    debugOpen,
    selectedSettingMenuOption,
    endDebug,
    loadApp,
    validateApp,
    appName,
    templateView,
    validationResults,
  ] = useEditorStore(
    useShallow(state => [
      state.editorView.debugAppOpen,
      state.settingsMenu.selected,
      state.actions.endDebug,
      state.graphql.loadApp,
      state.graphql.validatePluginDependencies,
      state.app.name,
      state.editorView.templateView,
      state.app.graph.validationResults,
    ])
  )

  const selectedNodeId = useEditorStore(state => state.actions.graph.getSelectedNodeId());

  const setFlowId = useClientStore(state => state.setFlowId);

  useEffect(() => {
    document.title = `Editing ${appName} | GoPixie.ai`;
  }, [appName]);

  useEffect(() => {
    loadApp(apolloClient, flowId === undefined ? null : flowId)
      .then(() => validateApp(apolloClient));
    setFlowId(flowId);
  }, [flowId]);

  return <SiteAssetsProvider siteId={props.siteId}>
    <ReactFlowProvider>
      <Stack
        height={`${height}px`}
        width='100vw'
        overflow='hidden'
        display='flex'
      >
        <TopBar onCreate={() => routerHistory.push(getFlowEditorPathV2())} />
        <Stack direction='row' width='100%' overflow='hidden' flexGrow={1} display='flex'>
          <Box sx={{ overflowY: 'auto' }}>
            <SettingsMenu />
          </Box>
          <Stack direction='row' overflow='hidden' flexGrow={1} display='flex' position="relative">
            <Slide in={Boolean(selectedSettingMenuOption)} direction='right' mountOnEnter unmountOnExit>
              <Box sx={{
                width: { xs: '100vw', md: '25vw', xl: '20vw' },
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                borderRight: `1px solid ${theme.palette.divider}`,
                boxShadow: '3px 0 10px -10px #000',
                background: theme.palette.background.paper,
                overflow: 'auto',
                zIndex: 1001,
              }}>
                {selectedSettingMenuOption && <SettingsPanel siteId={props.siteId} />}
              </Box>
            </Slide>
            {templateView
              ? <AppTemplateView templateId={templateView} />
              : <FlowGraphView
                sx={{
                  height: "100%",
                  flexGrow: 1,
                  background: theme.palette.grey[100],
                }}
              />
            }
            <Slide in={Boolean(selectedNodeId) && !templateView} direction='left' mountOnEnter unmountOnExit>
              <Box sx={{
                width: { xs: '100vw', md: '30vw', xl: '25vw' },
                position: "absolute",
                right: 0,
                top: 0,
                height: "100%",
                borderLeft: `1px solid ${theme.palette.divider}`,
                boxShadow: '-3px 0 10px -10px #000',
                background: theme.palette.background.paper,
                overflow: 'auto',
              }}>
                {selectedNodeId && <ConfigurationPanel nodeId={selectedNodeId} />}
              </Box>
            </Slide>
          </Stack>
        </Stack>
      </Stack>
      {debugOpen && <AppClientInPopover
        flowId={flowId}
        onClose={endDebug}
        initialOpen
        initialShowDebug
        disableQuickClose
        disconnectOnClose
        quitSessionOnClose
        backdropFilter='blur(25px)'
        layout={{
          ...defaultLayout,
          dialogWidth: '100vw',
          dialogHeight: '100vh',
        }}
      />}
    </ReactFlowProvider>
  </SiteAssetsProvider>
}


interface EditorPageProps {
  siteId: string,
};


export default EditorPage;

// editor components
// includes: top bar, left settings menu/panel, right configuraion panel, and main working area (graph editor)
// top bar includes nav to pages outside of editor (like back to home) info & action about the flow (name), save, run, and more (new/duplicate/delete) etc
// left settings includes workspace/flow level settings, include linked accounts, ai configuration, run history, labeled results
// right configuration panel is the plugin name, type, param/dynamic param configuration panel
// main working area to draw flowchart, and select node for configuration

function TopBar(props: {
  // flowName: string,
  // flowId: string | undefined,
  // getFlow: () => IFlowConfig,
  // getLayout: () => IFlowNodePositionV2[],
  // isPublic: boolean,
  // aiConfig: any,
  // onChange: (name: string, isPublic: boolean, aiConfig: any) => void,
  onCreate: () => void,
  // onDebug: () => void,
  // startNodeId: string | null,
}): React.ReactElement {
  const theme = useTheme();
  const routerHistory = useHistory();
  const siteId = useUserAndWorkspaceStore(state => state.workspaceId);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownAnchor = useRef<HTMLButtonElement>(null);

  const {
    appId,
    appName,
    requiresChromeExtension,
    setAppName,
    startDebug,
    saveApp,
    deleteApp,
    duplicateApp,
  } = useEditorStore(
    useShallow(state => ({
      appId: state.app.id,
      appName: state.app.name,
      requiresChromeExtension: state.actions.graph.requiresChromeExtension(),
      setAppName: state.actions.setAppName,
      startDebug: state.actions.startDebug,
      saveApp: state.graphql.saveApp,
      deleteApp: state.graphql.deleteApp,
      duplicateApp: state.graphql.duplicateApp,
    }))
  );
  const client = useApolloClient();

  return <AppBar position='static' elevation={0} color='inherit'>
    <Toolbar disableGutters sx={{
      p: 1,
      borderBottom: `1px solid ${theme.palette.divider}`
    }}>
      <Stack direction='row' width='100%' spacing={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={1} display='flex' alignItems='center'>
          <Button
            size='large'
            onClick={() => routerHistory.push('/workspace')}
            sx={{
              borderRadius: 5,
              fontSize: 32,
              p: 2,
              color: 'grey.300',
              '&:hover': {
                backgroundColor: 'secondary.light',
                color: 'secondary.contrastText',
              }
            }}
          >
            <ArrowBackIosRoundedIcon fontSize='inherit' />
          </Button>
          <NameChangeV2 value={appName} variant='h5' onChange={setAppName} />
        </Stack>
        {/* CTA buttons */}
        <Stack direction='row' spacing={1} display='flex' alignItems='center'>
          {requiresChromeExtension && <Tooltip title="This app requires Chrome Extension to run">
            <ExtensionRoundedIcon fontSize='large' color='disabled' />
          </Tooltip>
          }
          {appId
            ? <MutationButton
              mutation={() => saveApp(client, siteId)}
              completionMessage={`App "${appName}" updated.`}
            >
              <SaveRoundedIcon fontSize='inherit' />
            </MutationButton>
            : <MutationButton
              mutation={() => saveApp(client, siteId)}
              completionMessage={`App "${appName}" created.`}
              onCompletion={data => {
                // give sometime for the flow to reload from server, then change path
                setTimeout(() => routerHistory.push(getFlowEditorPathV2(data)), 500);
              }}
            >
              <SaveRoundedIcon fontSize='inherit' />
            </MutationButton>
          }
          <Button
            size='large'
            onClick={requiresChromeExtension
              ? () => sendCommandToExtension({ type: 'runApp', flowId: appId, appState: useEditorStore.getState().app })
              : startDebug
            }
            sx={{
              borderRadius: 5,
              fontSize: 32,
              p: 2,
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              '&:hover': {
                backgroundColor: 'secondary.dark',
              }
            }}
          >
            <PlayArrowRoundedIcon fontSize='inherit' />
          </Button>
          <Button
            ref={dropdownAnchor}
            size='large'
            onClick={() => setDropdownOpen(o => !o)}
            sx={{
              borderRadius: 5,
              fontSize: 32,
              p: 2,
              color: dropdownOpen ? theme.palette.secondary.main : 'grey.300',
              '&:hover': {
                color: 'secondary.main',
              }
            }}
          >
            <MoreHorizRoundedIcon fontSize='inherit' />
          </Button>
        </Stack>
      </Stack>
      <Popover
        open={dropdownOpen}
        anchorEl={dropdownAnchor.current}
        onClose={() => setDropdownOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              mt: 2,
              p: 1,
              borderRadius: 5,
            }
          }
        }}
      >
        <Stack spacing={1} sx={{ p: 1 }} display='flex'>
          <DropdownActionButton
            icon={<ContentCopyRoundedIcon />}
            label='Duplicate'
            mutation={async () => {
              return await duplicateApp(client, siteId).finally(() => setDropdownOpen(false));
            }}
          />
          <DropdownActionButton
            icon={<DeleteOutlineRoundedIcon />}
            label='Delete'
            mutation={async () => {
              return await deleteApp(client)
                .then(res => {
                  // TODO should navigate to workspace once we figure out how to reload here
                  routerHistory.push('/workspace');
                  return res;
                })
                .finally(() => setDropdownOpen(false)
                );
            }}
          />
        </Stack>
      </Popover>
    </Toolbar>
  </AppBar>
}


function DropdownActionButton<TData>(props: {
  icon: React.ReactElement,
  label: string,
  mutation: () => Promise<FetchResult<TData>>,
}): React.ReactElement {
  return <MutationButton
    mutation={props.mutation}
    completionMessage={`${props.label} app successful.`}
    sx={{ color: 'inherit', textTransform: 'none', '&:hover': { color: 'secondary.main' } }}
  >
    <Stack p={1} width='100%' direction='row' spacing={1} display='flex' alignItems='center' justifyContent='start'>
      {props.icon}
      <Typography variant='body1'>{props.label}</Typography>
    </Stack>
  </MutationButton>
}


type AddNodeButtonProps = {
  onMouseEnter?: (event: React.MouseEvent) => void,
  onMouseLeave?: (event: React.MouseEvent) => void,
}

function RemoveButton<T>(props: {
  // selected: T,
  // onRemove: (v: T) => void,
}): React.ReactElement {

  const removeSelected = useEditorStore(state => state.actions.graph.removeSelected);

  return <Button
    variant='contained'
    color='error'
    sx={{
      display: 'flex',
      borderRadius: 20,
      p: 2,
      m: 2,
      boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
      textTransform: 'none',
    }}
    onClick={removeSelected}
  >
    <DeleteOutlineRoundedIcon fontSize='large' />
  </Button>
}

function CopyButton(): React.ReactElement | undefined {
  const commands = useEditorCommands();
  const copyCommand = commands.graphCopy;

  if (!copyCommand) return undefined;

  return <Button
    onClick={() => {
      copyCommand.action();
    }}
    variant='contained'
    color='info'
    sx={{
      display: 'flex',
      borderRadius: 20,
      p: 2,
      m: 2,
      boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
      textTransform: 'none',
    }}
  >
    <ContentCopyRoundedIcon fontSize='large' />
  </Button>
}

function PasteButton(): React.ReactElement | undefined {
  const commands = useEditorCommands();
  const pasteCommand = commands.graphPaste;

  if (!pasteCommand) return undefined;

  return <Button
    variant='contained'
    color='info'
    sx={{
      display: 'flex',
      borderRadius: 20,
      p: 2,
      m: 2,
      boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
      textTransform: 'none',
    }}
    onClick={() => pasteCommand.action()}
  >
    <ContentPasteGoOutlinedIcon fontSize='large' />
  </Button>
}

function AppTemplateView(props: {
  templateId: string,
}): React.ReactElement {
  const { loading, data } = useQuery(GET_APP_TEMPLATE_DETAILS, {
    variables: { templateId: props.templateId },
  });

  return <Container sx={{ overflow: 'auto' }}>
    {loading
      ? <LinearProgress />
      : <Stack spacing={2} p={{ xs: 2, md: 3, lg: 4 }} display='flex'>
        {data.agetAppTemplateDetails.settings.map((s, idx) => <Stack key={idx}>
          <Typography variant='h6'>{s.name}</Typography>
          <ConfigurationPanel nodeId={s.nodeId} dynamicDisabled paramsOnly p={0} />
        </Stack>)}
      </Stack>
    }

  </Container>
}
