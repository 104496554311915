import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SignInDialog,
} from '../../../../components/Auth';
import {
  AppRunOptions,
  useAppRun,
} from '../../../../hooks/useAppRun';
import CustomizationProvider from '../../../../components/customization/customization-provider';
import {
  StylingWithDefaults,
} from '../../../../types/ClientState';
import {
  TPalette,
  TFont,
  TLayout,
} from '../../../../../generated/gql/graphql';
import {
  useClientStore,
} from '../../../../hooks/ClientState';
import {
  Property,
} from 'csstype';
import {
  LinearProgress,
  Stack,
  Theme,
} from '@mui/material';
import {
  useApolloClient,
} from '@apollo/client';
import {
  DebugView,
} from './DebugView';
import {
  useEditorStore,
} from '../../../../hooks/EditorState';
import {
  createFlowFromZustand,
} from '../../../../utils/graph-conversion';
import {
  useShallow,
} from 'zustand/react/shallow';
import { AppPopover } from './AppPopover';
import { ChatView } from './ChatView';

// NOTE this component needs to be inside a GraphQLProvider and AppContainer
// TODO this should be the designated component to manage client state, right now it's all over the place
export function AppClientInPopover(props: {
  flowId: string | undefined,
  authToken?: string;
  palette?: TPalette;
  font?: TFont;
  layout?: TLayout;
  onStylingLoaded?: (styling: StylingWithDefaults) => void;
  replayMessagesOnConnect?: boolean;
  onClose?: () => void;
  initialOpen?: boolean;
  initialShowDebug?: boolean;
  disconnectOnClose?: boolean;
  // only effective if disconnectOnClose is true. if true, the session will be terminated on close
  quitSessionOnClose?: boolean;
  backdropFilter?: Property.BackdropFilter;
  disableQuickClose?: boolean;
  theme?: Partial<Theme>;
}): React.ReactElement {
  const [started, setStarted] = useState(false);
  const setFlowId = useClientStore(state => state.setFlowId);
  const loadStylingCustomizations = useClientStore(state => state.loadStylingCustomizations);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setFlowId(props.flowId);
    loadStylingCustomizations(apolloClient).then(() => {
      const state = useClientStore.getState();
      props.onStylingLoaded?.({
        palette: state.getPalatte(),
        font: state.getFont(),
        layout: state.getLayout(),
        icon: state.icon,
        openAfter: state.openAfter,
      });
    });
  }, [props.flowId, apolloClient]);

  const initializing = useClientStore(state => state.initializing);

  const [showDebug, setShowDebug] = useState(props.initialShowDebug);
  const nodesData = useEditorStore(useShallow(state => state.graph.nodes.map(node => node.data)));
  const edges = useEditorStore(useShallow(state => state.graph.edges));
  const [
    startNodeId,
    aiConfig,
  ] = useEditorStore(useShallow(state => [
    state.app.startNodeId,
    state.app.aiConfig,
  ]));

  const disconnectRef = React.useRef(() => { });
  const appRunOptions = useMemo<AppRunOptions>(() => {
    if (!props.flowId) {
      return {
        debug: false,
        flowId: props.flowId,
        startId: null,
      };
    }
    if (showDebug) {
      const flow = createFlowFromZustand(nodesData, edges);
      return {
        debug: true,
        flowConfig: flow,
        flowId: props.flowId,
        startId: startNodeId,
        aiConfig,
      };
    }
    return {
      debug: false,
      flowId: props.flowId,
      startId: null,
    };
  }, [props.flowId, showDebug, nodesData, edges, startNodeId, aiConfig]);


  const [showSignin, setShowSignin] = useState(false);
  const appRun = useAppRun(
    appRunOptions,
    () => setShowSignin(true),
    props.replayMessagesOnConnect,
  );

  useEffect(() => {
    disconnectRef.current = appRun.disconnect;
  }, [appRun.disconnect]);

  const onPopoverOpen = useCallback(() => {
    if (!started) {
      setStarted(true);
      appRun.start();
    }
  }, [started, appRun.start]);

  const onPopoverClose = useCallback(() => {
    if (props.disconnectOnClose) {
      appRun.disconnect(!props.quitSessionOnClose);
    }
    props.onClose?.();
  }, [appRun.disconnect, props.onClose, props.quitSessionOnClose]);

  const setCurrentBreakpoint = useEditorStore(state => state.actions.setCurrentBreakpoint);
  const currentBreakpoint = useEditorStore(useShallow(state => state.app?.currentBreakpoint));
  const rerunFromNodeAfterBreakpoint = useEditorStore(useShallow(state => state.app?.rerunFromNodeAfterBreakpoint));
  const rerunFromNodeAfterBreakpointRef = React.useRef(rerunFromNodeAfterBreakpoint);
  const isInterruptedByError = useClientStore(state => state.interrupted === 'error');

  useEffect(() => {
    rerunFromNodeAfterBreakpointRef.current = rerunFromNodeAfterBreakpoint;
  }, [rerunFromNodeAfterBreakpoint?.nodeId, rerunFromNodeAfterBreakpoint?.nodeVersion]);

  // automatically rerun the app from the configured node when a breakpoint is hit
  // this is for repeating part of the flow for ai learning
  useEffect(() => {
    const rerunFrom = rerunFromNodeAfterBreakpointRef.current;
    if (rerunFrom && (currentBreakpoint || isInterruptedByError)) {
      setCurrentBreakpoint(null);
      appRun.rerun(rerunFrom.nodeId, rerunFrom.nodeVersion);
    }
  }, [currentBreakpoint, isInterruptedByError]);

  return <CustomizationProvider
    palette={props.palette}
    font={props.font}
    theme={props.theme}
  >
    <AppPopover
      authToken={props.authToken}
      showLoading={!props.flowId || !appRun.isReady}
      initialOpen={props.initialOpen}
      onOpen={onPopoverOpen}
      onClose={onPopoverClose}
      backdropFilter={props.backdropFilter}
      disableQuickClose={props.disableQuickClose}
      layout={props.layout}
    >
      {initializing
        ? <LinearProgress />
        : <Stack direction='row' width='100%' height='100%' spacing={2}>
          {showDebug && <DebugView appRun={appRun} onClose={() => setShowDebug(false)} width='70%' />}
          <ChatView {...appRun} width={showDebug ? '30%' : '100%'} />
          <SignInDialog
            open={showSignin}
            enableSignup={true}
            onSignin={() => {
              // NOTE: this could be wrong if signin required is not triggered at the beginning
              appRun.send([]);
              setShowSignin(false);
            }}
            message='Please signin first to use the app.' />
        </Stack>}
    </AppPopover>
  </CustomizationProvider>
}
